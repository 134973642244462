import React, { Component } from 'react';
import Modal from 'react-modal'; 
import { connect } from 'react-redux'; 
import { closeForgotPasswordStatusModal} from '../../actions/modalActions';

export class ForgotPasswordStatus extends Component {
    handleClose = () => {
        this.props.closeModal(); 
    }

    render() {
        return(
            <Modal 
              isOpen={this.props.isOpen} 
              className="modal-content bootstrap gothic-font"
            >
                <div className="modal-header" style={{justifyContent: "center", borderBottom: "none"}}>
                <h2 className="modal-title" style={{color: "#004D71", marginBottom: "0",
                lineHeight: "1.5", fontFamily: "inherit",
                fontWeight: "300", marginLeft: "auto", width: "100%"}}>Check Your Mail</h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{padding: "0 4.375rem 1.25rem"}}>
                    <div style={{textAlign: "center"}}>
                        Email has been sent with steps to reset password.
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => {
        dispatch(closeForgotPasswordStatusModal())
    }
});

export default connect(null, mapDispatchToProps)(ForgotPasswordStatus); 