import React from 'react';
import InfoDropdown from '../common/InfoDropdown';
import PrivateEquityDropdowns from '../services/dropdowns/PrivateEquityDropdowns';
import EnterpriseDropdowns from '../services/dropdowns/EnterpriseDropdowns';
import ConsultingDropdowns from '../services/dropdowns/ConsultingDropdowns';

export default () => (
  <div>
    <InfoDropdown 
      title="Private Equity - Case Study"
      shortText="A global printing company faced a threat to their existing 
        consumer printing business and decided to explore the possibility of 
        entering the growing office printing market 
        as a way to find new sources of growth."
    >
      <PrivateEquityDropdowns />
    </InfoDropdown>
    <InfoDropdown 
      title="Enterprise - Case Study"
      shortText="A global leading software company was trying to understand the Virtual 
        Reality landscape, but struggled to find the right information on this emerging industry. 
        Pressured by a competitor’s move, the client needed 
        immediate expert input to make a rapid decision."
    >
      <EnterpriseDropdowns />
    </InfoDropdown>
    <InfoDropdown 
      title="Consulting - Case Study"
      shortText="A consulting firm needed to build a benchmark of best 
        practices in a number of core areas of data monetization, including 
        buying first-party data, cleaning and curating high-value data, and aggregating 
        different streams of data before offering them for sale externally."
    >
      <ConsultingDropdowns />
    </InfoDropdown>
  </div>
);