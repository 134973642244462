import api from '../utils/api'

import {
  SEARCH_EXPERTS_START,
  SEARCH_EXPERTS_SUCCESS,
  SEARCH_EXPERTS_FAILURE,
  UPDATE_EXPERT_EMAIL_START,
  UPDATE_EXPERT_EMAIL_SUCCESS,
  UPDATE_EXPERT_EMAIL_FAILURE
} from "../action-types/admin";

export const setSearchExpertsStart = () => ({
  type: SEARCH_EXPERTS_START
});

export const setSearchExpertsSuccess = (searchResults: Object) => ({
  type: SEARCH_EXPERTS_SUCCESS,
  payload: searchResults
});

export const setSearchExpertsFailure = (error: Object) => ({
  type: SEARCH_EXPERTS_FAILURE,
  payload: error
});

export const setUpdateExpertEmailStart = () => ({
  type: UPDATE_EXPERT_EMAIL_START
});

export const setUpdateExpertEmailSuccess = (data: Object) => ({
  type: UPDATE_EXPERT_EMAIL_SUCCESS,
  payload: data
});

export const setUpdateExpertEmailFailure = (error: Object) => ({
  type: UPDATE_EXPERT_EMAIL_FAILURE,
  payload: error
});

export const getExpertsByNameOrEmail = (searchValue) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  await dispatch(setSearchExpertsStart())
  try {
    const searchResult = await api.get(`/admin/profiles?search=${encodeURIComponent(searchValue)}`);

    if (searchResult.status === 200) {
      await dispatch(setSearchExpertsSuccess(searchResult.data));
    }
  } catch ({ response: { data } }) {
    await dispatch(setSearchExpertsFailure(true));
  }
};

export const updateUserEmail = (updatedUserInfo, updatedId) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  await dispatch(setUpdateExpertEmailStart())
  try {
    const body = {
      email: updatedUserInfo.user.email,
      firstName: updatedUserInfo.expert.firstName,
      lastName: updatedUserInfo.expert.lastName,
    };

    const emailUpdateResult = await api.patch(`/users/${updatedId}/email`, body);

    if (emailUpdateResult.status === 200) {
      await dispatch(setUpdateExpertEmailSuccess(emailUpdateResult.data));
    }
  } catch ({ response: { data } }) {
    await dispatch(setUpdateExpertEmailFailure(true));
  }
};