import React from 'react'
import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Compliance is at the core of NewtonX products and services"
      text="The line of business in which we operate is one of the most differentiating 
      and sensitive ones in today’s world. Knowledge is the most valuable asset to NewtonX’s 
      clients: it adds tremendous value to their business, but it can also destroy value 
      if handled incorrectly. Leveraging our long-standing experience in the industry 
      as well as a close collaboration with our clients, we have developed a compliance framework 
      that is not only one of the most conservative and robust in the industry, but is also reinforced 
      by our search algorithm which ensures compliance by not matching experts to customers where potential 
      compliance issues could arise."
    />
    <DropdownElement 
      description="The NewtonX compliance framework"
      text="We have designed our framework with the objective of ensuring compliance 
      from both the expert and client point of view. Our compliance framework relies 
      on 3 principles that guide NewtonX’s professional conduct: "
    />
    <DropdownElement 
      description="Conservative"
      text="We prefer to consistently choose the most conservative and secure line of conduct 
        rather than approach any grey area from a compliance or legal standpoint. 
        When in doubt, we would rather not let an expert share his or her expertise than 
        create any risk for the expert or the client."
    />
    <DropdownElement 
      description="Proactive"
      text="We do not rely on our experts and clients just “knowing the rules” 
        and signing the compliance terms. Our long experience in the industry 
        has taught us that constant vigilance and proactivity are the key to 
        success to avoid any glitch in even the most robust compliance framework. 
        This is reflected in our interactive compliance tutorials we give our experts as well 
        as our employers' preferences database."
    />
    <DropdownElement 
      description="Transparent"
      text="We pride ourselves in having a superior product that 
        makes expertise sharing more efficient and transparent. 
        The same transparency applies to our compliance processes. Feel 
        free to contact us for any question or to request any compliance 
        documentation at compliance@newtonx.com."
    />
  </div>
);
