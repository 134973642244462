import React, { Component } from 'react';
import MainBlackBox from '../../components/common/MainBlackBox';
import FAQContent from '../../components/FAQ/FAQcontent';

export default class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    let correctPadding;
    if (this.state.width > 810 ) {
      correctPadding = '100px 100px'
    }
    if(this.state.width < 810 && this.state.width < 573 ) {
      correctPadding = '50px 50px'
    }
    if(this.state.width < 573 ) {
      correctPadding = '20px 20px 10px 20px'
    }
    return (
      <div>
        <MainBlackBox 
          contentStyle={{ height: this.state.width < 573 ? '80px' : '100px', padding: correctPadding,}}
          mainTitle="FAQ - "
        />
        <FAQContent />
      </div>
    );
  }
}