import React, { Component } from 'react';
import Slider from "react-slick";

import '../../styles/common/Slider.scss'

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img
      src={require('../../images/homepage/arrow_right@3x.png')}
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        height: '56px', 
        width: '23px', 
        top: props.paddingBottom ? props.paddingBottom : '0px',
        right: props.width < 450 && '-45px',
      }}
      onClick={onClick}
      alt=""
    />
  );
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <img
      src={require('../../images/homepage/arrow_left@3x.png')}
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        height: '56px', 
        width: '23px', 
        top: props.paddingBottom ? props.paddingBottom : '0px',
        left: props.width < 450 && '-45px',
      }}
      onClick={onClick}
      alt=""
    />
  );
}


export default class CustomSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    const settings = {
      ...this.props.settings,
      nextArrow: <SampleNextArrow width={this.state.width} paddingBottom={this.props.paddingBottom} />,
      prevArrow: <SamplePrevArrow width={this.state.width} paddingBottom={this.props.paddingBottom} />
    }
    return (
      <Slider {...settings}>
        {this.props.children}
      </Slider>
    );
  }
}
