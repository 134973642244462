// @flow
import axios from 'axios';
import { environment } from '../environments/env'

import {
    SET_USER_INFORMATION,
    CLEAR_USER_INFORMATION,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    FETCH_PROFILE_START,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAILURE,
    RESEND_VERIFICATION_EMAIL_START,
    RESEND_VERIFICATION_EMAIL_SUCCESS,
    RESEND_VERIFICATION_EMAIL_FAILURE,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    UPDATE_EXPERT_SUCCESS,
    UPDATE_EXPERT_START,
    UPDATE_EXPERT_FAILURE,
    UPDATE_EXPERT_PAYMENT_START,
    UPDATE_EXPERT_PAYMENT_SUCCESS,
    UPDATE_EXPERT_PAYMENT_FAILURE,
    UPDATE_USER_PASSWORD_START,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAILURE,
    DOWNLOAD_USER_INFORMATION_START,
    DOWNLOAD_USER_INFORMATION_SUCCESS,
    DOWNLOAD_USER_INFORMATION_FAILURE,
    DELETE_USER_INFORMATION_START,
    DELETE_USER_INFORMATION_SUCCESS,
    DELETE_USER_INFORMATION_FAILURE,
    CREATE_USER_LOG_START,
    CREATE_USER_LOG_SUCCESS,
    CREATE_USER_LOG_FAILURE
} from '../action-types/login';
import api from '../utils/api';

export const setUserInformation = (userInfo) => ({
    type: SET_USER_INFORMATION,
    payload: userInfo
});

export const clearUserInformation = () => ({
    type: CLEAR_USER_INFORMATION,
    payload: {}
});

export const setLoginStart = () => ({
    type: LOGIN_START
});

export const setLoginSuccess = (token: string) => ({
    type: LOGIN_SUCCESS,
    payload: token
});

export const setLoginFailure = (error: Object) => ({
    type: LOGIN_FAILURE,
    payload: error
});

export const setFetchProfileStart = () => ({
    type: FETCH_PROFILE_START
});

export const setFetchProfileSuccess = (userInfo: Object) => ({
    type: FETCH_PROFILE_SUCCESS,
    payload: userInfo
});

export const setFetchProfileFailure = (error: Object) => ({
    type: FETCH_PROFILE_FAILURE,
    payload: error
});

export const setResendVerificationEmailStart = () => ({
    type: RESEND_VERIFICATION_EMAIL_START
});

export const setResendVerificationEmailSuccess = () => ({
    type: RESEND_VERIFICATION_EMAIL_SUCCESS
});

export const setResendVerificationEmailFailure = () => ({
    type: RESEND_VERIFICATION_EMAIL_FAILURE
});

export const setResetPasswordStart = () => ({
    type: RESET_PASSWORD_START
});

export const setResetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS
});

export const setResetPasswordFailure = () => ({
    type: RESET_PASSWORD_FAILURE
});

export const setUpdateExpertStart = () => ({
    type: UPDATE_EXPERT_START
});

export const setUpdateExpertSuccess = (expert: Object) => ({
    type: UPDATE_EXPERT_SUCCESS,
    payload: {
        ...expert
    }
});

export const setUpdateExpertFailure = () => ({
    type: UPDATE_EXPERT_FAILURE
});

export const setUpdateExpertPaymentStart = () => ({
    type: UPDATE_EXPERT_PAYMENT_START
});

export const setUpdateExpertPaymentSuccess = (expertPayment: Object) => ({
    type: UPDATE_EXPERT_PAYMENT_SUCCESS,
    payload: {
        ...expertPayment
    }
});

export const setUpdateExpertPaymentFailure = () => ({
    type: UPDATE_EXPERT_PAYMENT_FAILURE
});

export const setUpdateUserPasswordStart = () => ({
    type: UPDATE_USER_PASSWORD_START
});

export const setUpdateUserPasswordSuccess = () => ({
    type: UPDATE_USER_PASSWORD_SUCCESS
});

export const setUpdateUserPasswordFailure = () => ({
    type: UPDATE_USER_PASSWORD_FAILURE
});

export const setDownloadUserInformationStart = () => ({
    type: DOWNLOAD_USER_INFORMATION_START
});

export const setDownloadUserInformationSuccess = () => ({
    type: DOWNLOAD_USER_INFORMATION_SUCCESS
});

export const setDownloadUserInformationFailure = () => ({
    type: DOWNLOAD_USER_INFORMATION_FAILURE
});

export const setDeleteUserInformationStart = () => ({
    type: DELETE_USER_INFORMATION_START
});

export const setDeleteUserInformationSuccess = () => ({
    type: DELETE_USER_INFORMATION_SUCCESS
});

export const setDeleteUserInformationFailure = () => ({
    type: DELETE_USER_INFORMATION_FAILURE
});

export const setCreateUserLogStart = () => ({
    type: CREATE_USER_LOG_START
});

export const setCreateUserLogSuccess = () => ({
    type: CREATE_USER_LOG_SUCCESS
});

export const setCreateUserLogFailure = () => ({
    type: CREATE_USER_LOG_FAILURE
});

export const login = (username, password) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    dispatch(setLoginStart());
    try {
        const loginResult = await axios.get(environment.CAS_BASE_PATH + '/oauth2.0/accessToken?', {
            params: {
                'grant_type': 'password',
                'client_id': environment.CAS_CLIENT_ID,
                'username': username.trim().toLowerCase(),
                'password': password
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (loginResult.status === 200) {
            const { access_token } = loginResult.data;
            await dispatch(setLoginSuccess(access_token));
            localStorage.setItem(environment.LOGIN_TOKEN_NAME, access_token);
            await dispatch(getProfile());
        }
    } catch (error) {
        dispatch(setLoginFailure(true));
    }
};

export const getProfile = () => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setFetchProfileStart());
    try {
        const getProfileResult = await api.get('/profile');

        if (getProfileResult.status === 200) {
            await dispatch(setFetchProfileSuccess(getProfileResult.data));
        }
    } catch (error) {
        await dispatch(setFetchProfileFailure('Something went wrong! Please try again'));
    }
};

export const resendVerificationEmail = (userId) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setResendVerificationEmailStart());
    try {
        const result = await api.post(`/users/${encodeURIComponent(String(userId))}/verify`);

        if (result.status === 200) {
            await dispatch(setResendVerificationEmailSuccess());
        }
    } catch (error) {
        await dispatch(setResendVerificationEmailFailure());
    }
};

export const resetPassword = (userInfo) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setResetPasswordStart());
    try {
        const result = await api.post('/users/resetpassword', userInfo);

        if (result.status === 200) {
            await dispatch(setResetPasswordSuccess());
        }
    } catch (error) {
        await dispatch(setResetPasswordFailure());
    }
};

export const updateExpert = (expertId: Number, expertInfo: Object) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setUpdateExpertStart());
    try {
        const result = await api.patch(`/experts/${encodeURIComponent(String(expertId))}`, expertInfo);

        if (result.status === 200) {
            //NOTE: response does not return anything                   
            await dispatch(setUpdateExpertSuccess(expertInfo));
        }
    } catch (error) {
        await dispatch(setUpdateExpertFailure());
    }
};

export const updateExpertPayment = (expertId: Number, expertPaymentInfo: Object) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setUpdateExpertPaymentStart());
    try {
        const result = await api.patch(`/experts/${encodeURIComponent(String(expertId))}/payment`, expertPaymentInfo);

        if (result.status === 200) {
            await dispatch(setUpdateExpertPaymentSuccess(expertPaymentInfo));
        }
    } catch (error) {
        await dispatch(setUpdateExpertPaymentFailure());
    }
};

export const updateUserPassword = (userId: Number, userInfo: object) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setUpdateUserPasswordStart());
    try {
        const result = await api.patch(`/users/${encodeURIComponent(String(userId))}/password`, userInfo);

        if (result.status === 200) {
            await dispatch(setUpdateUserPasswordSuccess());
        }
    } catch (error) {
        await dispatch(setUpdateUserPasswordFailure());
    }
};

export const downloadUserInformation = (userId: Number) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setDownloadUserInformationStart());
    try {
        const result = await api.get(`/users/${encodeURIComponent(String(userId))}/download`);

        if (result.status === 200) {
            await dispatch(setDownloadUserInformationSuccess());
        }
    } catch (error) {
        await dispatch(setDownloadUserInformationFailure());
    }
};

export const deleteUserInformation = (userId: Number) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setDeleteUserInformationStart());
    try {
        const result = await api.delete(`/users/${encodeURIComponent(String(userId))}`);

        if (result.status === 200) {
            await dispatch(setDeleteUserInformationSuccess());
        }
    } catch (error) {
        await dispatch(setDeleteUserInformationFailure());
    }
};

export const createUserLog = (userId: Number, userLog: Object) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setCreateUserLogStart());
    try {
        const result = await api.post(`/users/${encodeURIComponent(userId)}/logs`, userLog);

        if (result.status === 200) {
            await dispatch(setCreateUserLogSuccess());
        }
    } catch (error) {
        await dispatch(setCreateUserLogFailure());
    }
};