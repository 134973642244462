import React from 'react';

import '../../styles/components/services/Products.scss';

export default () => (
  <div className="products-container">
    <div className="products-content">
      <div className="header-products">
        <span>NewtonX Products</span>
      </div>
      <div className="main-content">

        <div className="item-cell">
          <span className="item-cell-header">Expert Hotline </span>
          <p>Same-day consultations for the insights that cannot wait until tomorrow</p>
        </div>
        <div className="item-cell">
          <span className="item-cell-header">CEO Calls </span>
          <p>Access over 25,000 former  and current CEOs on the NewtonX platform</p>
        </div>
        <div className="item-cell">
          <span className="item-cell-header">Turn-key Surveys</span>
          <p>
            End-to-end large-scale, global survey capabilities, from 
            coding  to fielding, with results in under  3 days for up to 500 participants
          </p>
        </div>

        <div className="item-cell">
          <span className="item-cell-header">Moderated Interviews</span>
          <p>Ask an expert to interview  other experts to solve your issues (speed, compliance, domain expertise)</p>
        </div>
        <div className="item-cell">
          <span className="item-cell-header">Expert Phone  and Video Calls</span>
          <p>On-demand access to subject-matter experts through the medium that works best for you</p>
        </div>
        <div className="item-cell">
          <span className="item-cell-header">In-Person Engagements </span>
          <p>From group panels to longer  in-person projects, NewtonX experts go beyond the traditional network model</p>
        </div>
      </div>
    </div>
  </div>
);