import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { environment } from '../../environments/env';

class GoogleAnalytics extends Component {
  componentWillUpdate ({ location, history }) {
    const gtag = window.gtag;

    if (location.pathname === this.props.location.pathname) {
      // don't relog if they're on the same page
      return;
    }
    
    if (typeof(gtag) === 'function') {
      gtag('config', environment.GA_TRACKING_ID, {
        'page_path': location.pathname
      });
    }
  }

  render () {
    return null;
  }
}

export default withRouter(GoogleAnalytics);