import axios from 'axios';
import { environment } from '../environments/env';

class ContactService {
    //TODO: probably this method is not being used anymore. Need to double check
    sendContactNotificationEmail = (contactInfo) => {
        return axios.post(`${environment.API_BASE_PATH}/contacts`, contactInfo, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }
}

export default new ContactService(); 