import { combineReducers } from 'redux'; 
import signinReducer from './signinReducer'; 
import modalReducer from './modalReducer'; 
import { connectRouter } from 'connected-react-router'; 
import signupReducer from './signupReducer'; 
import adminReducer from './adminReducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    userInfo: signinReducer,
    modals: modalReducer,
    signup: signupReducer,
    admin: adminReducer
});