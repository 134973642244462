import React, { Component } from 'react';
import {ResetPasswordConfirm} from "../resetPasswordConfirm/ResetPasswordConfirm";
import {ResendVerificationEmailConfirm} from "../resendVerificationEmailConfirm/ResendVerificationEmailConfirm";

class ExpertSearchBar extends Component {
  constructor() {
    super();
    this.state = {
      searchValue: '',
    };
  };

  handleInputChange = (event) => {
    this.setState({
      searchValue: event.target.value
    });
  };

  handleSearchClick = () => {
    this.props.searchForExpert(this.state.searchValue);
  };

  render() {
    return(
      <div className="bootstrap col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mt-5 text-center" style={{width: "80%"}}>
        <h3>Expert Search</h3>
        <p>Search for experts by name or email</p>
        <div className="d-flex">
          <input className="form-control" type="text" value={this.state.searchValue} onChange={this.handleInputChange}/>
          <button type="button" className="btn btn-primary ml-1" onClick={this.handleSearchClick}>Search</button>
          <ResetPasswordConfirm isOpen={this.props.resetPasswordConfirmationModalOpen} />
          <ResendVerificationEmailConfirm isOpen={this.props.resendVerificationEmailConfirmationModalOpen} />
        </div>
      </div>
    );
  }
}

export default ExpertSearchBar;
