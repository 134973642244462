import React from 'react';
import '../../styles/components/guidelines/Guidelines.scss';


export default (props) => (
  <div className="homepage-guidelines">
    <div className="home-guidelines-box">
      <div className="guidelines-header">
        <span>
          <h1>Consultation Guidelines &amp; Tips</h1>
        </span>
      </div>
      <div className="guidelines-main-content">
        <div className="guidelines-text">
          <div className="guidelines-text-box">
        <div className="paragraph-header">
          <p>Tune Out Distractions</p>
        </div>
        <div className="paragraph-body">
          <p>
            Please make sure you are in a quiet space where you can focus without distractions (i.e. traffic, playful pets, etc.). 
          </p>
        </div>
        <div className="paragraph-header">
          <p>
              Be Available and Fully Engaged for Your Whole Consultation
          </p>
        </div>
        <div className="paragraph-body">
          <p>
          Any potential scheduling conflicts should be discussed with your NewtonX point of contact <em>prior</em> to your scheduled consultation. If your call is meant to last for 60 minutes, please block off a full 60 minutes in your day.
          </p>
        </div>
        <div className="paragraph-header">
          <p>
            Stay On Topic
          </p>
        </div>
        <div className="paragraph-body">
          <p>
            We understand that small talk can ease the transition into the conversation. However, please keep this to a minimum, as our clients would prefer that you stick to the point and tailor your answers in a concise way to make the most of the consultation time. 
          </p>
        </div>
        <div className="paragraph-header">
          <p>
              Pause And Ask if You’re Answering Questions Fully 
          </p>
        </div>
        <div className="paragraph-body">
          <p>
            You have been carefully vetted and screened to participate in a consultation with our client. You have all the knowledge, your goal is now to make sure that what you are saying actually answers the client’s question. Don’t be afraid to ask in order to be sure!
          </p>
        </div>
        <div className="paragraph-header">
          <p>
              Good Experts Talk, Great Experts also Listen! 
          </p>
        </div>
        <div className="paragraph-body">
          <p>
            Listening is essential when participating in a phone consultation. Our clients have very specific questions and very limited time on the phone with you, so it is important they have the space to ask all of their questions. A useful trick here is to just pause from time to time to ask if they have questions or would like you to continue with your response. 
          </p>
        </div>
        <div className="paragraph-header">
          <p>
              Speak Slowly and Clearly 
          </p>
        </div>
        <div className="paragraph-body">
          <p>
            Our experts are from all over the world, as are our clients. Because of this, sometimes language and accents can be a barrier. Please be sure to speak slowly and clearly so the valuable knowledge you are providing is fully understood and nothing gets lost in translation. 
          </p>
        </div>
        <div className="paragraph-header">
          <p>
              Use a Professional Tone 
          </p>
        </div>
        <div className="paragraph-body">
          <p>
            Please do not use crude or inappropriate language. Our clients respond best to experts that speak passionately about their expertise and conduct themselves with an overall professional demeanor. 
          </p>
        </div>
        <div className="paragraph-header">
          <p>
              Estimates are Better Than Nothing 
          </p>
        </div>
        <div className="paragraph-body">
          <p>
          YOU are the expert &amp; our clients are looking to learn from you. Please do not refrain from sharing data, estimates and approximations. Data with a 20% margin of error is infinitely better than no data!
          </p>
        </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
