// @flow
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { closeResendLinkModal, openResendLinkStatusModal } from '../../actions/modalActions';
import { withToastManager } from 'react-toast-notifications';
import { clearUserInformation, resendVerificationEmail } from '../../actions/signinActions';
import { RESEND_VERIFICATION_EMAIL_START, RESEND_VERIFICATION_EMAIL_SUCCESS, RESEND_VERIFICATION_EMAIL_FAILURE } from '../../action-types/login'

class ResendLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resendVarificationEmailStatus: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resendVarificationEmailStatus !== this.props.resendVarificationEmailStatus &&
            (
                this.state.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_START &&
                (
                    this.props.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_SUCCESS ||
                    this.props.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_FAILURE
                )
            )) {
            if (this.props.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_SUCCESS) {
                this.props.closeResendLinkModal();
                this.props.clearUserInformation();
                this.props.openResendLinkStatusModal();
                this.props.clearUserInformation();
            } else {
                this.props.toastManager.add('An error occurred. The link could not be resent.', {
                    appearance: 'error'
                });
            }
        }

    }

    handleResendClick = () => {
        this.setState({
            resendVarificationEmailStatus: RESEND_VERIFICATION_EMAIL_START
        });
        this.props.resendVerificationEmail(this.props.userId);
    }

    handleCloseClick = () => {
        this.props.closeResendLinkModal();
    }
    render() {
        return (
            <Modal isOpen={this.props.isOpen} className="modal-content bootstrap gothic-font">
                <div className="modal-header">
                    <h5 className="modal-title">Resend Link</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseClick}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p className="text-info">Your email address hasn't been verified yet. Click "Resend Link" button if you did not get the verification email.</p>
                    <div className="form-row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <button className="btn btn-secondary btn-block" onClick={this.handleCloseClick}>Cancel</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <button className="btn black-button btn-block" onClick={this.handleResendClick}>Resend Link</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = ({ modals, userInfo }) => ({
    isOpen: modals.resendLinkModalOpen,
    userId: userInfo.user.id,
    resendVarificationEmailStatus: userInfo.resendVarificationEmailStatus
});

const mapDispatchToProps = (dispatch: Function) => ({
    closeResendLinkModal: () => {
        dispatch(closeResendLinkModal())
    },

    openResendLinkStatusModal: () => {
        dispatch(openResendLinkStatusModal())
    },

    clearUserInformation: () => {
        dispatch(clearUserInformation())
    },

    resendVerificationEmail: (userId) => {
        dispatch(resendVerificationEmail(userId));
    }
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ResendLink)); 