import React, { Component } from 'react';
import queryString from 'query-string';
import Select from 'react-select';

import SurveyEndedData from '../../data/temp_data/surveyEndedData';
import '../../styles/components/survey/SurveyFeedbackText.scss';

// TODO: merge all the survey components to one component that displays depending on the url
const DEFAULT_LANGUAGE = 'en';

export default class surveyEnded extends Component {
  queries = '';
  state = {
    foundLanguage: [],
    languages: [],
    currentValue: {},
    queries: null,
  };

  constructor(props) {
    super(props);

    this.queries = queryString.parse(props.location.search);

    const languageCode = this.queries.lang ? this.queries.lang : '';

    this.state.foundLanguage = [];
    SurveyEndedData.sort((a, b) => {
      if (a.languageCode === DEFAULT_LANGUAGE) {
        return -1;
      } else if (b.languageCode === DEFAULT_LANGUAGE) {
        return 1;
      }
      return a.language > b.language ? 1 : -1;
    });
    this.state.languages = SurveyEndedData.map(data => {
      if (data.languageCode.toLowerCase() === languageCode.toLowerCase()) {
        this.state.foundLanguage.push(data);
      }
      return { value: data.languageCode, label: data.language };
    });
    if (this.state.foundLanguage.length <= 0) {
      this.state.foundLanguage = SurveyEndedData.filter(
        value => value.languageCode === DEFAULT_LANGUAGE,
      );
      this.updateUrl(DEFAULT_LANGUAGE);
    }
  }

  componentDidMount() {
    this.updateCurrentValue();
  }

  updateUrl(lang) {
    if (this.props.history) {
      this.props.history.push({
        search: `?${new URLSearchParams({ ...this.queries, lang }).toString()}`,
      });
    }
  }

  updateCurrentValue() {
    this.setState(state => ({
      ...state,
      currentValue: {
        value: state.foundLanguage[0].languageCode,
        label: state.foundLanguage[0].language,
      },
    }));
  }

  handleChange(value) {
    this.setState(state => ({
      ...state,
      foundLanguage: SurveyEndedData.filter(
        data => data.languageCode.toLowerCase() === value.value.toLowerCase(),
      ),
    }));

    this.updateCurrentValue();
    this.updateUrl(value.value);
  }

  render() {
    const message = this.state.foundLanguage
      ? this.state.foundLanguage[0].content
          .split('\n')
          .map((element, i) => (
            <div key={i}>{element.length ? element : <br />}</div>
          ))
      : '';

    return (
      <div className='homepage-survey-feedback'>
        <div className='home-survey-feedback-box'>
          <div className='home-survey-feedback-language'>
            <Select
              className='customSelectStyle'
              options={this.state.languages}
              onChange={this.handleChange.bind(this)}
              value={this.state.currentValue}
              isSearchable
            />
          </div>
          <div className='survey-feedback-main-content'>
            <div className='survey-feedback-text'>
              <div className='survey-feedback-text-box'>
                <div className='paragraph-body'>
                  <div className='paragraph-body-margin'>{message}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
