const SurveyEndedData = [
  {
    language: 'English',
    languageCode: 'en',
    content: `Dear Respondent,

        Unfortunately it seems you do not qualify for this specific survey due to the precise requirements of our client.
        We are sure we will have other opportunities to collaborate in the near future, and hope to be working with you again very soon.
        If you have any question or if you believe you have been mistakenly disqualified, please contact us at survey@newtonx.com and mention the topic of the survey in the email title for reference.

        Kind regards,
        Your NewtonX Team`,
  },
  {
    language: '中文',
    languageCode: 'ch',
    content: `尊敬的受访者：

        很抱歉，由于我们客户的需求精确，您未能获得参与本调查的资格。我们相信，在不远的未来还会有其他合作机会，期待很快能与您再次合作。如您有任何疑问或认为该不合格判定存在错误，请发送电子邮件至 survey@newtonx.com 与我们联系，并在邮件标题中注明调查主题以供参考。

        谨致问候
        您的 NewtonX 团队`,
  },
  {
    language: 'Français',
    languageCode: 'fr',
    content: `Cher/Chère participant(e),

        Malheureusement, il semble que vous ne remplissez pas les conditions requises pour participer à cette enquête spécifique en raison des exigences précises de notre client.
        Nous aurons certainement l’occasion de vous proposer de nouvelles possibilités de collaboration dans un avenir proche, et nous espérons qu’elles vous intéresseront. Si vous avez des questions ou si vous estimez avoir été disqualifié(e) par erreur, veuillez nous contacter par mail à survey@newtonx.com en indiquant le sujet de l’enquête dans le titre de l’e-mail pour référence.

        Nous vous prions d’agréer, cher/chère participant(e), l’expression de nos sentiments distingués.
        Votre équipe NewtonX`,
  },
  {
    language: 'Deutsche',
    languageCode: 'de',
    content: `Geschätzter Teilnehmer,

        anscheinend sind Sie aufgrund der speziellen Anforderungen unseres Kunden nicht für diese spezifische Umfrage teilnahmeberechtigt.
        Wir sind sicher, dass wir Ihnen in der nahen Zukunft weitere Möglichkeiten zur Zusammenarbeit bieten können, und hoffen, sehr bald wieder mit Ihnen zusammenzuarbeiten. Wenn Sie Fragen haben oder glauben, dass Sie irrtümlich ausgeschlossen wurden, kontaktieren Sie uns bitte unter survey@newtonx.com und geben Sie das Thema der Umfrage in der Betreffzeile der E-Mail als Referenz an.

        Mit freundlichen Grüßen,
        Ihr NewtonX-Team`,
  },
  {
    language: 'Indonesia',
    languageCode: 'id',
    content: `Responden yang Terhormat,

        Sayangnya, Anda tidak memenuhi syarat untuk survei khusus ini karena persyaratan tertentu dari klien kami.
        Kami meyakini akan terdapat kesempatan kerja sama lain dalam waktu dekat, dan kami berharap dapat segera bekerja sama lagi dengan Anda. Apabila Anda memiliki pertanyaan atau jika Anda meyakini telah didiskualifikasi dengan tidak semestinya, hubungilah kami di survey@newtonx.com dan sebutkan topik survei pada judul email sebagai referensi.

        Salam hormat,
        Tim NewtonX Anda`,
  },
  {
    language: '한국어',
    languageCode: 'ko',
    content: `응답자님께,

        안타깝게도 클라이언트의 구체적인 요구조건으로 인하여 귀하는 본 설문을 작성할 자격이 되지 않는 것으로 보입니다. 근시일내에 협력할 수 있는 또 다른 기회가 있으리라 확신하며 곧 귀하와 함께 일할 수 있기를 바랍니다. 질문이 있으시거나 귀하의 결격이 실수라고 생각되실 경우 survey@newtonx.com으로 연락주시기 바라며 이때 참고용으로 설문 주제를 이메일 제목에 기입해 주시면 감사하겠습니다.

        안녕히 계십시오,
        NewtonX 팀 드림`,
  },
  {
    language: 'Portuguesa',
    languageCode: 'pt',
    content: `Prezado participante,

        Infelizmente, você não foi qualificado para esta pesquisa devido a necessidades específicas de nosso cliente.
        Não se preocupe, pois teremos novas oportunidades de colaboração no futuro próximo, e esperamos trabalhar com você novamente em breve. Em caso de dúvidas ou se achar que foi desqualificado por engano, envie um e-mail para survey@newtonx.com e informe o tema da pesquisa no assunto da mensagem para fins de referência.

        Atenciosamente,
        Sua equipe NewtonX`,
  },
  {
    language: 'Española',
    languageCode: 'es',
    content: `Estimado encuestado:

        Lamentablemente, parece que no califica para esta encuesta específica debido a los requisitos precisos de nuestro cliente.
        Estamos seguros de que tendremos otras oportunidades para que colabore en el futuro cercano y esperamos trabajar con usted pronto otra vez. Si tiene alguna pregunta o cree que lo hemos descalificado por error, contáctenos a survey@newtonx.com y mencione el tema de la encuesta en el título del correo electrónico para referencia.

        Saludos cordiales,
        Su equipo de NewtonX`,
  },
  {
    language: '日本語',
    languageCode: 'ja',
    content: `ご回答者各位

        当社クライアントの厳密な要件により、誠に残念ながら、あなたはこちらの特定のアンケートの回答者として適格ではないものと見られます。
        近日中にご協力いただける別の機会が出てくることと思いますので、その際にご参加いただければ幸いです。ご質問がある場合や、ご自身の回答が誤りによって終了されたと思われる場合には、survey@newtonx.comにお問い合わせください。その際、参照用にアンケートのトピックを電子メールの件名にご記入ください。

        よろしくお願いいたします。
        NewtonXチーム`,
  },
  {
    language: 'Pусский',
    languageCode: 'ru',
    content: `Уважаемый респондент!

        К сожалению, Вы не соответствуете требованиям данного опроса, предъявленные нашим клиентом.
        В ближайшем будущем непременно появятся другие возможности для совместной работы, и мы надеемся, что скоро будем с Вами сотрудничать. Если у Вас появились вопросы или Вы считаете, что Вас не допустили к участию в опросе по ошибке, обращайтесь по адресу survey@newtonx.com и укажите в теме электронного письма название опроса.

        С уважением,
        Команда NewtonX`,
  },
  {
    language: 'TÜRK',
    languageCode: 'tr',
    content: `Sayın Katılımcı,

        Müşterimizin belirlediği özel gereklilikler nedeniyle ne yazık ki bu ankete katılmak için gerekli niteliklere sahip değilsiniz.
        Yakın gelecekte başka işbirliği fırsatlarının doğacağından eminiz ve çok yakında sizinle tekrar çalışmayı umuyoruz. Herhangi bir sorunuz varsa veya gerekli niteliklere sahip olduğunuzu düşünüyorsanız lütfen survey@newtonx.com adresinden bizimle iletişime geçin ve e-postanın konu kısmında referans olarak anketin konusunu belirtin.

        Saygılarımızla,
        NewtonX Ekibiniz`,
  },
];

export default SurveyEndedData;
