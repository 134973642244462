import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Header from '../components/common/Header';
import TopHeader from '../components/common/TopHeader';
import ContactUs from '../components/common/ContactUs';
import Footer from '../components/common/Footer';
import GoogleAnalytics from '../components/analytics/GoogleAnalytics';
import HubspotAnalytics from '../components/analytics/HubspotAnalytics';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <>
        <TopHeader />
        <Header />
        <Route {...rest} render={(props) => {
            if (localStorage.getItem('nxPublicToken')) {
                return <Component {...props} />
            } else {
                return <Redirect to='/' />
            }
        }} />
        <GoogleAnalytics />
        <HubspotAnalytics />
        <ContactUs />
        <Footer />
    </>
)

export default PrivateRoute; 