import React, { Component } from 'react';
import GeneralInfo from '../../components/aboutUs/GeneralInfo';
import CrewInfo from '../../components/aboutUs/CrewInfo';
import PlatformDescription from '../../components/aboutUs/PlatformDescription';
import Advantages from '../../components/aboutUs/Advantages';
import MainBlackBox from '../../components/common/MainBlackBox';

export default class AboutUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }
  
  render() {
    // let boxHeight;
    // this.state.width <
    return (
      <div>
        <GeneralInfo />
        <CrewInfo width={this.state.width} />
        <PlatformDescription />
        <Advantages width={this.state.width}/>
        {/* <Experience /> */}
        <MainBlackBox 
          contentStyle={{height: this.state.width < 450 ? '' : ''}}
          mainTitle="Experience Backed - "
          subTitle=" "
          text="The NewtonX leadership team have experienced the same pain points that our clients do. With backgrounds that include McKinsey, Google, and Boston Consulting Group, we know how to deliver the best experience possible to our clients, because we used to be in their positions."
        />
      </div>
    );
  }
}