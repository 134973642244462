import React, { Component, Fragment } from 'react';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import InternalRoute from './InternalRoute';
import Homepage from '../containers/homepage/Homepage';
import AboutUs from '../containers/aboutUs/AboutUs';
import HowWeWork from '../containers/howWeWork/HowWeWork';
import Services from '../containers/services/Services';
import NewsAndResources from '../containers/newsAndResources/NewsAndResources';
import Careers from '../containers/careers/Careers';
import Compliance from '../containers/compliance/Compliance';
import FAQ from '../containers/faq/FAQ';
import MyProfileContainer from '../containers/myProfile/MyProfileContainer';
import LinkedInOAuthSignup from '../containers/signup/LinkedInOAuthSignup';
import ReferralLinkSignup from '../containers/signup/ReferralLinkSignup';
import ExpertEmailVerifier from '../containers/signup/ExpertEmailVerifier';
import ExpertSetupContainer from '../containers/signup/ExpertSetupContainer';
import ExpertTermsContainer from '../containers/signup/ExpertTermsContainer';
import ExpertPaymentContainer from '../containers/signup/ExpertPaymentContainer';
// import { connect } from "react-redux";
import Policies from '../containers/policies/Policies';
import DialinsContainer from '../containers/dialins/DialinsContainer';
import AdminContainer from '../containers/admin/AdminContainer';
import Guidelines from '../components/guidelines/Guidelines';
import SurveyCompleted from '../components/survey/SurveyCompleted';
import SurveyEnded from '../components/survey/SurveyEnded';
import SurveyPaymentContainer from '../containers/surveyPayment/SurveyPaymentContainer';
import SurveyQuota from '../components/survey/SurveyQuota';
import SurveyFlag from '../components/survey/SurveyFlag';
import ExternalSignIn from '../containers/login/ExternalSignIn';

// import { connect } from "react-redux";

// export const history = createHistory();
// ({ component: Component, ...rest }) =>
// class PrivateRoute extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {

//     }
//   }

//   componentWillReceiveProps(newProps) {
//   }
//   render() {
//     const { path, component: PassedComponent, ...rest} = this.props
//     return(
//       <Route
//         {...rest}
//         render={props => {
//             return <PassedComponent {...props} />
//           }
//         }
//       />
//     );
//   }
// };

// const mapStateToProps = (store) => {
//   return {
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {

//   }
// }

// const ConnectedPrivateRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

const AppRouter = () => {
  return (
    <Fragment>
      <ScrollToTopWithRouter >
        <div style={{ width: '100vw', height: '100vh' }}>
          <Switch>
            <InternalRoute path="/" component={Homepage} name="HomePage" exact={true} />
            <LoginRoute path="/login" component={ExternalSignIn} name="LoginPage" exact={true} />
            <InternalRoute path="/about-us" component={AboutUs} exact={true} />
            <InternalRoute path="/how-we-work" component={HowWeWork} exact={true} />
            <InternalRoute path="/services" component={Services} exact={true} />
            <InternalRoute path="/press" component={NewsAndResources} exact={true} />
            <InternalRoute path="/careers" component={Careers} exact={true} />
            <InternalRoute path="/compliance" component={Compliance} exact={true} />
            <InternalRoute path="/faq" component={FAQ} exact={true} />
            <InternalRoute path="/oauth/linkedin/signup" component={LinkedInOAuthSignup} />
            <InternalRoute path="/signup" component={ReferralLinkSignup} />
            <InternalRoute path="/verify" component={ExpertEmailVerifier} exact={true} />
            <PrivateRoute path="/expert/profile" component={MyProfileContainer} exact={true} />
            <PrivateRoute path="/expert/setup" component={ExpertSetupContainer} exact={true} />
            <PrivateRoute path="/expert/terms" component={ExpertTermsContainer} exact={true} />
            <PrivateRoute path="/expert/payment" component={ExpertPaymentContainer} exact={true} />
            <PrivateRoute path="/expert/admin" component={AdminContainer} exact={true} />
            <InternalRoute path="/privacy" render={() => <Policies isPrivacy={true} />} exact={true} />
            <InternalRoute path="/security" render={() => <Policies isPrivacy={false} />} exact={true} />
            <InternalRoute path="/dialins" component={DialinsContainer} exact={true} />
            <InternalRoute path="/guidelines" component={Guidelines} exact={true} />
            <InternalRoute path="/survey-completed" component={SurveyCompleted} exact={true} />
            <InternalRoute path="/survey-ended" component={SurveyEnded} exact={true} />
            <InternalRoute path="/survey-quota" component={SurveyQuota} exact={true} />
            <InternalRoute path="/survey-flag" component={SurveyFlag} exact={true} />
            <InternalRoute path="/payment" component={SurveyPaymentContainer} exact={true} />
            <Redirect from="*" to="/" />
          </Switch>
        </div>
      </ScrollToTopWithRouter >
    </Fragment>
  );
}



export default AppRouter;
