import React from 'react';
import renderHTML from 'react-render-html';

const sectionHeaderLetters = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];

export default ({
  isSectionOpen,
  onClick,
  sectionIndex,
  sectionInfo: {
    title, html,
  },
}) => (
  <div className='policies-section__wrapper'>
    <div className='policies-section__top-wrapper'>
      <h1 className='policies-section__title'>
        {`(${sectionHeaderLetters[sectionIndex]}) ${title} -`}
      </h1>
      <button className='policies-section__show-button white-button' onClick={onClick}>
        { isSectionOpen ? 'Show Less' : 'Show More' }
      </button>
    </div>
    {
      isSectionOpen && (
        <div className='policies-section__content'>
          {renderHTML(html)}
        </div>
      )
    }
  </div>
);
