import {
    OPEN_RESEND_LINK_MODAL,
    CLOSE_RESEND_LINK_MODAL,
    OPEN_SIGNUP_MODAL,
    CLOSE_SIGNUP_MODAL,
    OPEN_SIGNUP_AFTER_REDIRECT_MODAL,
    CLOSE_SIGNUP_AFTER_REDIRECT_MODAL,
    OPEN_SIGNIN_MODAL,
    CLOSE_SIGNIN_MODAL,
    OPEN_RESEND_LINK_STATUS_MODAL,
    CLOSE_RESEND_LINK_STATUS_MODAL,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    OPEN_FORGOT_PASSWORD_STATUS_MODAL,
    CLOSE_FORGOT_PASSWORD_STATUS_MODAL,
    OPEN_THANK_YOU_SIGNUP_MODAL,
    CLOSE_THANK_YOU_SIGNUP_MODAL,
    RESET_PASSWORD_USER_EMAIL,
    OPEN_RESET_PASSWORD_CONFIRMATION_MODAL,
    CLOSE_RESET_PASSWORD_CONFIRMATION_MODAL,
    RESEND_VERIFICATION_USER_ID,
    OPEN_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL,
    CLOSE_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL
} from '../action-types/modals';

export default (state = {
    signupModalOpen: false,
    signupAfterRedirectModalOpen: false,
    signinModalOpen: false,
    resendLinkModalOpen: false,
    resendLinkStatusModalOpen: false,
    forgotPasswordModalOpen: false,
    forgotPasswordStatusModalOpen: false,
    resetPasswordConfirmationModalOpen: false,
    resendVerificationEmailConfirmationModalOpen: false
}, action) => {
    switch(action.type) {
        case OPEN_SIGNUP_MODAL:
            return {
                ...state,
                signupModalIsOpen: action.payload.signupModalIsOpen
            }
        case CLOSE_SIGNUP_MODAL:
          return {
              ...state,
              signupModalIsOpen: action.payload.signupModalIsOpen
          }
        case OPEN_SIGNUP_AFTER_REDIRECT_MODAL:
            return {
                ...state,
                signupAfterRedirectModalOpen: action.payload.signupAfterRedirectModalOpen

            }
        case CLOSE_SIGNUP_AFTER_REDIRECT_MODAL:
            return {
                ...state,
                signupAfterRedirectModalOpen: action.payload.signupAfterRedirectModalOpen
            }
        case OPEN_SIGNIN_MODAL:
            return {
                ...state,
                signinModalOpen: action.payload.signinModalOpen
            }
        case CLOSE_SIGNIN_MODAL:
            return {
                ...state,
                signinModalOpen: action.payload.signinModalOpen
            }
        case OPEN_RESEND_LINK_MODAL:
            return {
                ...state,
                resendLinkModalOpen: action.payload.resendLinkModalOpen
            }
        case CLOSE_RESEND_LINK_MODAL:
            return {
                ...state,
                resendLinkModalOpen: action.payload.resendLinkModalOpen
            }
        case OPEN_RESEND_LINK_STATUS_MODAL:
            return {
                ...state,
                resendLinkStatusModalOpen: action.payload.resendLinkStatusModalOpen
            }
        case CLOSE_RESEND_LINK_STATUS_MODAL:
            return {
                ...state,
                resendLinkStatusModalOpen: action.payload.resendLinkStatusModalOpen
            }
        case OPEN_FORGOT_PASSWORD_MODAL:
            return {
                ...state,
                forgotPasswordModalOpen: action.payload.forgotPasswordModalOpen
            }
        case CLOSE_FORGOT_PASSWORD_MODAL:
            return {
                ...state,
                forgotPasswordModalOpen: action.payload.forgotPasswordModalOpen
            }
        case OPEN_FORGOT_PASSWORD_STATUS_MODAL:
            return {
                ...state,
                forgotPasswordStatusModalOpen: action.payload.forgotPasswordStatusModalOpen
            }
        case CLOSE_FORGOT_PASSWORD_STATUS_MODAL:
            return {
                ...state,
                forgotPasswordStatusModalOpen: action.payload.forgotPasswordStatusModalOpen
            }
        case OPEN_THANK_YOU_SIGNUP_MODAL:
            return {
                ...state,
                thankYouSignupModalOpen: action.payload.thankYouSignupModalOpen
            }
        case CLOSE_THANK_YOU_SIGNUP_MODAL:
            return {
                ...state,
                thankYouSignupModalOpen: action.payload.thankYouSignupModalOpen
            }
        case RESET_PASSWORD_USER_EMAIL:
            return {
                ...state,
                userEmail: action.payload.userEmail
            }
        case OPEN_RESET_PASSWORD_CONFIRMATION_MODAL:
            return {
                ...state,
                resetPasswordConfirmationModalOpen: action.payload.resetPasswordConfirmationModalOpen
            }
        case CLOSE_RESET_PASSWORD_CONFIRMATION_MODAL:
            return {
                ...state,
                resetPasswordConfirmationModalOpen: action.payload.resetPasswordConfirmationModalOpen
            }
        case RESEND_VERIFICATION_USER_ID:
            return {
                ...state,
                userId: action.payload.userId
            }
        case OPEN_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL:
            return {
                ...state,
                resendVerificationEmailConfirmationModalOpen: action.payload.resendVerificationEmailConfirmationModalOpen
            }
        case CLOSE_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL:
            return {
                ...state,
                resendVerificationEmailConfirmationModalOpen: action.payload.resendVerificationEmailConfirmationModalOpen
            }
        default:
          return state
    }
}
