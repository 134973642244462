import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "pageHeadline": "Payment Information",
      "Select a Language": "Select a language",
      "firstName": "First Name",
      "lastName": "Last Name",
      "contactEmail": "Contact Email",
      "confirmContactEmail": "Confirm Contact Email",
      "paymentEmail": "Payment Email",
      "confirmPaymentEmail": "Confirm Payment Email",
      "paymentEmailCheckbox": "My payment email is the same as my contact email.",
      "france": "France",
      "unitedKingdom": "United Kingdom",
      "germany": "Germany",
      "japan": "Japan",
      "unitedStates": "United States",
      "canada": "Canada",
      "australia": "Australia",
      "brazil": "Brazil",
      "denmark": "Denmark",
      "sweden": "Sweden",
      "mexico": "Mexico",
      "newZealand": "New Zealand",
      "india": "India",
      "italy": "Italy",
      "indonesia": "Indonesia",
      "ukraine": "Ukraine",
      "dubai": "Dubai",
      "southKorea": "South Korea",
      "china": "China",
      "haiti": "Haiti",
      "netherlands": "Netherlands",
      "chile": "Chile",
      "other": "Other Country",
      "Please select the country corresponding to your payment information": "Please select the country corresponding to your payment information",
      "paymentHeaderMessage": "Congratulations on completing the survey successfully! Please select below your preferred payment option as well as your contact info so that we can email you a payment confirmation.",
      "bankTransferMessage": "Thank you for selecting Bank transfer. Please note that you will receive a link to our secure Tipalti bank platform from our finance team in the next 5 business days. Check your spam folder as the email might land there. Should you not receive this email, please contact survey@newtonx.com with the topic of the survey in the email subject line.",
      "submit": "Submit",
      "paymentType": "Payment Type",
      "paypal": "Paypal",
      "bankTransfer": "Bank Transfer",
      "amazon": "Amazon",
      "paypalEmailAddress": "Paypal Email Address",
      "amazonEmailAddress": "Amazon Email Address",
      "confirmPaypalEmailAddress": "Confirm Paypal Email Address",
      "confirmAmazonEmailAddress": "Confirm Amazon Email Address",
    }
  },
  fr: {
    translation: {
      "pageHeadline": "Données de paiement",
      "Select a Language": "Selectionnez une langue",
      "unitedStates": "Etats-Unis",
      "france": "France",
      "canada": "Canada",
      "unitedKingdom": "Royaume-Uni",
      "australia": "Australie",
      "brazil": "Brésil",
      "germany": "Allemagne",
      "denmark": "Danemark",
      "sweden": "Suède",
      "mexico": "Mexique",
      "newZealand": "Nouvelle-Zélande",
      "india": "Inde",
      "italy": "Italie",
      "japan": "Japon",
      "indonesia": "Indonésie",
      "ukraine": "Ukraine",
      "dubai": "Dubai",
      "southKorea": "Corée du sud",
      "china": "Chine",
      "haiti": "Haïti",
      "netherlands": "Pays-Bas",
      "chile": "Chili",
      "other": "Autre Pays",
      "paymentType": "Méthode de paiement:",
      "paypal": "Paypal",
      "bankTransfer": "Virement",
      "amazon": "Chèque Amazon",
      "firstName": "Prénom",
      "lastName": "Nom",
      "contactEmail": "Email de contact",
      "confirmContactEmail": "Confirmez l'email de contact",
      "paymentEmailCheckbox": "Je souhaite recevoir le paiement sur la même adresse email",
      "paymentEmail": "Email de paiement",
      "confirmPaymentEmail": "Confirmez l'email de paiement",
      "paymentHeaderMessage": "Félicitations ! Vous avez rempli cette enquête avec succès ! Veuillez indiquer ci-dessous votre mode de paiement préféré ainsi que vos coordonnées afin que nous puissions vous envoyer par e-mail une confirmation de paiement.",
      "Please select the country corresponding to your payment information": "Merci de choisir le pays dans lequel vous souhaitez reçevoir le paiement.",
      "bankTransferMessage": "Nous vous remercions d'avoir choisie le virement comme methode de paiement. Vous aller reçevoir un email de notre service finance d'ici 5 jours ouvrés avec un lien vers notre partenaire sécurisé Tipalti. Pensez à vérifier vos SPAMs. Si jamais vous ne reçevez toujours pas l'email, vous pouvez contacter nos équipes à survey@newtonx.com avec le sujet du sondage dans l'objet du mail.",
      "submit": "Submit",
      "paypalEmailAddress": "Email du compte Paypal",
      "amazonEmailAddress": "Email du compte Amazon",
      "confirmPaypalEmailAddress": "Confirmez l'email du compte Paypal",
      "confirmAmazonEmailAddress": "Confirmez l'email du compte Amazon",
    }
  },
  id: {
    translation: {
      "pageHeadline": "INFORMASI PEMBAYARAN",
      "Select a Language": "Pilih Bahasa",
      "unitedStates": "Amerika Serikat",
      "france": "Prancis",
      "canada": "Kanada",
      "unitedKingdom": "Inggris",
      "australia": "Australia",
      "brazil": "Brasil",
      "germany": "Jerman",
      "denmark": "Denmark",
      "sweden": "Swadia",
      "mexico": "Meksiko",
      "newZealand": "Selandia Baru",
      "india": "India",
      "italy": "Italia",
      "japan": "Jepang",
      "indonesia": "Indonesia",
      "ukraine": "Ukraine",
      "dubai": "Dubai",
      "southKorea": "Korea Selatan",
      "china": "Tiongkok",
      "haiti": "Haiti",
      "netherlands": "Belanda",
      "chile": "Chile",
      // "other": "Autre Pays",
      "paymentType": "Jenis Pembayaran",
      "paypal": "Paypal",
      "bankTransfer": "Transfer Bank",
      "amazon": "Amazon",
      "firstName": "Nama depan",
      "lastName": "Nama belakang",
      "contactEmail": "Alamat Email Kontak Anda",
      "confirmContactEmail": "Konfirmasi Alamat Email Kontak",
      "paymentEmailCheckbox": "Alamat email untuk pembayaran sama dengan alamat email kontak saya:",
      "paymentEmail": "Alamat Email Pembayaran Anda:",
      "confirmPaymentEmail": "Konfirmasi Alamat Email Pembayaran:",
      "paymentHeaderMessage": "Selamat, Anda telah berhasil menyelesaikan survei ini! Harap pilih opsi pembayaran yang Anda kehendaki di bawah ini beserta info kontak Anda agar kami dapat mengirim email konfirmasi pembayaran kepada Anda.",
      "Please select the country corresponding to your payment information": "Pilihlah negara yang sesuai dengan informasi pembayaran Anda:",
      "bankTransferMessage": "Terima kasih telah memilih Transfer Bank. Harap diperhatikan bahwa Anda akan menerima tautan dari tim keuangan kami ke platform bank Tipalti kami yang aman dalam 5 hari kerja ke depan. Periksa folder spam Anda karena email tersebut mungkin masuk ke sana. Apabila Anda tidak menerima email ini, hubungilah survey@newtonx.com dengan menuliskan topik survei pada baris subjek email.",
      "submit": "Kirim",
      "paypalEmailAddress": "Alamat Email Pembayaran Anda:",
      "amazonEmailAddress": "Alamat Email Pembayaran Anda:",
      "confirmPaypalEmailAddress": "Konfirmasi Alamat Email Pembayaran:",
      "confirmAmazonEmailAddress": "Konfirmasi Alamat Email Pembayaran:",
    }
  },
  de: {
    translation: {
      "pageHeadline": "ZAHLUNGSINFORMATIONEN",
      "Select a Language": "Wählen Sie eine Sprache aus",
      "unitedStates": "USA",
      "france": "Frankreich",
      "canada": "Kanada",
      "unitedKingdom": "Vereinigtes Königreich",
      "australia": "Australien",
      "brazil": "Brasilien",
      "germany": "Deutschland",
      "denmark": "Dänemark",
      "sweden": "Schweden",
      "mexico": "Mexiko",
      "newZealand": "Neuseeland",
      "india": "Indien",
      "italy": "Italien",
      "japan": "Japan",
      "indonesia": "Indonesien",
      "ukraine": "Ukraine",
      "dubai": "Dubai",
      "southKorea": "Südkorea",
      "china": "China",
      "haiti": "Haiti",
      "netherlands": "Niederlande",
      "chile": "Chile",
      // "other": "Autre Pays",
      "paymentType": "Zahlungsart",
      "paypal": "Paypal",
      "bankTransfer": "Banküberweisung",
      "amazon": "Amazon",
      "firstName": "Vorname",
      "lastName": "Nachname",
      "contactEmail": "Ihre Kontakt-E-Mail-Adresse",
      "confirmContactEmail": "Kontakt-E-Mail-Adresse bestätigen",
      "paymentEmailCheckbox": "Meine E-Mail-Adresse für die Zahlung und meine Kontakt-E-Mail-Adresse sind identisch:",
      "paymentEmail": "Ihre Zahlungs-E-Mail-Adresse:",
      "confirmPaymentEmail": "Zahlungs-E-Mail-Adresse bestätigen:",
      "Please select the country corresponding to your payment information": "Bitte wählen Sie das Ihren Zahlungsinformationen entsprechende Land aus:",
      "paymentHeaderMessage": "Vielen Dank, dass Sie sich für eine Banküberweisung entschieden haben. Bitte beachten Sie, dass Sie innerhalb der nächsten 5 Werktage einen Link zu unserer sicheren Tipalti-Bankplattform von unserem Finanzteam erhalten werden. Bitte überprüfen Sie Ihren Spam-Ordner, da die E-Mail möglicherweise dort eingehen könnte. Sollten Sie diese E-Mail nicht erhalten, kontaktieren Sie bitte survey@newtonx.com und geben Sie das Thema der Umfrage in der Betreffzeile der E-Mail an.",
      "bankTransferMessage": "Terima kasih telah memilih Transfer Bank. Harap diperhatikan bahwa Anda akan menerima tautan dari tim keuangan kami ke platform bank Tipalti kami yang aman dalam 5 hari kerja ke depan. Periksa folder spam Anda karena email tersebut mungkin masuk ke sana. Apabila Anda tidak menerima email ini, hubungilah survey@newtonx.com dengan menuliskan topik survei pada baris subjek email.",
      "submit": "Absenden",
      "paypalEmailAddress": "Ihre Zahlungs-E-Mail-Adresse:",
      "amazonEmailAddress": "Ihre Zahlungs-E-Mail-Adresse:",
      "confirmPaypalEmailAddress": "Zahlungs-E-Mail-Adresse bestätigen:",
      "confirmAmazonEmailAddress": "Zahlungs-E-Mail-Adresse bestätigen:",
    },
  },
  zh: {
    translation: {
      "pageHeadline": "付款信息",
      "Select a Language": "选择一种语言",
      "unitedStates": "美国",
      "france": "法国",
      "canada": "加拿大",
      "unitedKingdom": "英国",
      "australia": "澳大利亚",
      "brazil": "巴西",
      "germany": "德国",
      "denmark": "丹麦",
      "sweden": "瑞典",
      "mexico": "墨西哥",
      "newZealand": "新西兰",
      "india": "印度",
      "italy": "意大利",
      "japan": "日本",
      "indonesia": "印度尼西亚",
      "ukraine": "乌克兰",
      "dubai": "迪拜",
      "southKorea": "韩国",
      "china": "中国",
      "haiti": "海地",
      "netherlands": "荷兰",
      "chile": "智利",
      // "other": "Autre Pays",
      "paymentType": "付款方式",
      "paypal": "Paypal",
      "bankTransfer": "银行转账",
      "amazon": "Amazon",
      "firstName": "名字",
      "lastName": "姓氏",
      "contactEmail": "您的联系电子邮箱地址",
      "confirmContactEmail": "确认联系电子邮箱地址",
      "paymentEmailCheckbox": "我的付款电子邮箱地址与我的联系电子邮箱地址相同：",
      "paymentEmail": "您的付款电子邮箱地址：",
      "confirmPaymentEmail": "确认付款电子邮箱地址：",
      "Please select the country corresponding to your payment information": "请选择您的付款信息对应的国家：",
      "paymentHeaderMessage": "恭喜您成功完成此次调查！请从下方选择您首选的付款方式并填写您的联系信息，以便我们通过电子邮件向您发送付款确认",
      "bankTransferMessage": "感谢您选择“银行转账”。请注意，在接下来的 5 个工作日内，我们的财务团队将向您发送我们的安全 Tipalti 银行平台链接。请查看您的垃圾邮件文件夹，因为邮件可能会被存放在这里。如果您未收到该邮件，请发送电子邮件至 survey@newtonx.com，邮件主题行请注明调查标题。",
      "submit": "提交",
      "paypalEmailAddress": "您的付款电子邮箱地址：",
      "amazonEmailAddress": "您的付款电子邮箱地址：",
      "confirmPaypalEmailAddress": "确认付款电子邮箱地址：",
      "confirmAmazonEmailAddress": "确认付款电子邮箱地址：",
    },
  },
  pt: {
    translation: {
      "pageHeadline": "INFORMAÇÕES DE PAGAMENTO",
      "Select a Language": "Selecione um idioma",
      "unitedStates": "Estados Unidos",
      "france": "França",
      "canada": "Canadá",
      "unitedKingdom": "Reino Unido",
      "australia": "Austrália",
      "brazil": "Brasil",
      "germany": "Alemanha",
      "denmark": "Dinamarca",
      "sweden": "Suécia",
      "mexico": "México",
      "newZealand": "Nova Zelândia",
      "india": "Índia",
      "italy": "Itália",
      "japan": "Japäo",
      "indonesia": "Indonésia",
      "ukraine": "Ucrânia",
      "dubai": "Dubai",
      "southKorea": "Coreia do Sul",
      "china": "China",
      "haiti": "Haiti",
      "netherlands": "Paises Baixos",
      "chile": "Chile",
      // "other": "Autre Pays",
      "paymentType": "Tipo de pagamento",
      "paypal": "Paypal",
      "bankTransfer": "Transferência bancária",
      "amazon": "Amazon",
      "firstName": "Nome",
      "lastName": "Sobrenome",
      "contactEmail": "Seu endereço de e-mail de contato",
      "confirmContactEmail": "Confirme seu endereço de e-mail de contato",
      "paymentEmailCheckbox": "Meu endereço de e-mail para pagamento é o mesmo que meu endereço de e-mail de contato:",
      "paymentEmail": "Seu endereço de e-mail de pagamento",
      "confirmPaymentEmail": "Confirme o endereço de e-mail de pagamento",
      "Please select the country corresponding to your payment information": "Selecione o país correspondente às suas informações de pagamento:",
      "paymentHeaderMessage": "Parabéns por ter concluído com sucesso a pesquisa! Selecione abaixo a sua opção de pagamento preferida, bem como suas informações de contato, para que possamos lhe enviar um e-mail com a confirmação de pagamento.",
      "bankTransferMessage": "Obrigado por selecionar transferência bancária. Você receberá um link para nossa plataforma bancária segura, Tipalti, de nossa equipe financeira nos próximos 5 dias úteis. Verifique sua caixa de spam, pois o e-mail pode ser direcionado para ela. Caso você não receba esse e-mail, entre em contato com survey@newtonx.com, escrevendo o tópico da pesquisa no campo de assunto do e-mail.",
      "submit": "Enviar",
      "paypalEmailAddress": "Seu endereço de e-mail de pagamento",
      "amazonEmailAddress": "Seu endereço de e-mail de pagamento",
      "confirmPaypalEmailAddress": "Confirme o endereço de e-mail de pagamento",
      "confirmAmazonEmailAddress": "Confirme o endereço de e-mail de pagamento",
      // "notListed": "Non listé"
    },
  },
  ja: {
    translation: {
      "pageHeadline": "支払情報",
      "Select a Language": "言語を選択",
      "unitedStates": "米国",
      "france": "フランス",
      "canada": "カナダ",
      "unitedKingdom": "英国",
      "australia": "オーストラリア",
      "brazil": "ブラジル",
      "germany": "ドイツ",
      "denmark": "デンマーク",
      "sweden": "スウェーデン",
      "mexico": "メキシコ",
      "newZealand": "ニュージーランド",
      "india": "インド",
      "italy": "イタリア",
      "japan": "日本",
      "indonesia": "インドネシア",
      "ukraine": "ウクライナ",
      "dubai": "ドバイ",
      "southKorea": "韓国",
      "china": "中国",
      "haiti": "ハイチ",
      "netherlands": "オランダ",
      "chile": "チリ",
      // "other": "Autre Pays",
      "paymentType": "支払タイプ",
      "paypal": "Paypal",
      "bankTransfer": "銀行振込",
      "amazon": "Amazon",
      "firstName": "氏名（名）：",
      "lastName": "氏名（姓）：",
      "contactEmail": "連絡先メールアドレス",
      "confirmContactEmail": "連絡先メールアドレス確認",
      "paymentEmailCheckbox": "私の支払用メールアドレスは、私の連絡先メールアドレスと同一です。",
      "paymentEmail": "支払用メールアドレス：",
      "confirmPaymentEmail": "支払用メールアドレスを確認：",
      "Please select the country corresponding to your payment information": "ご自身の支払情報について、対応する国を選択してください。",
      "paymentHeaderMessage": "以上でアンケートは終了です。お疲れさまでした。ご希望の支払方法を以下から選択し、連絡先情報を入力してください。当社から支払確認メールを送信いたします。",
      "bankTransferMessage": "銀行振込をお選び頂きありがとうございます。財務チームより、5営業日以内に当社の安全なTipaltiバンク・プラットフォームへのリンクを送信いたします。リンクが記載されたメールがスパムフォルダに分類されていないか、ご確認ください。リンクが記載されたメールが届かない場合には、当該メールの件名となるアンケートのトピックを記載の上、survey@newtonx.comまでご連絡ください。",
      "submit": "送信",
      "paypalEmailAddress": "支払用メールアドレス：",
      "amazonEmailAddress": "支払用メールアドレス：",
      "confirmPaypalEmailAddress": "支払用メールアドレスを確認：",
      "confirmAmazonEmailAddress": "支払用メールアドレスを確認：",
      // "notListed": "Non listé"
    },
  },
  es: {
    translation: {
      "pageHeadline": "INFORMACIÓN DE PAGO",
      "Select a Language": "Elija un idioma",
      "unitedStates": "Estados Unidos",
      "france": "Francia",
      "canada": "Canadá",
      "unitedKingdom": "Reino Unido",
      "australia": "Australia",
      "brazil": "Brasil",
      "germany": "Alemania",
      "denmark": "Dinamarca",
      "sweden": "Suecia",
      "mexico": "México",
      "newZealand": "Nueva Zelanda",
      "india": "India",
      "italy": "Italia",
      "japan": "Japón",
      "indonesia": "Indonesia",
      "ukraine": "Ucrania",
      "dubai": "Dubái",
      "southKorea": "Corea del Sur",
      "china": "China",
      "haiti": "Haití",
      "netherlands": "Países Bajos",
      "chile": "Chile",
      // "other": "Autre Pays",
      "paymentType": "Tipo de pago",
      "paypal": "Paypal",
      "bankTransfer": "Transferencia bancaria",
      "amazon": "Amazon",
      "firstName": "Nombre:",
      "lastName": "Apellido:",
      "contactEmail": "Su dirección de correo electrónico de contacto",
      "confirmContactEmail": "Confirmar dirección de correo electrónico de contacto",
      "paymentEmailCheckbox": "Mi dirección de correo electrónico de pago es la misma que mi dirección de correo electrónico de contacto:",
      "paymentEmail": "Su dirección de correo electrónico de pago:",
      "confirmPaymentEmail": "Confirmar dirección de correo electrónico de pago:",
      "Please select the country corresponding to your payment information": "Seleccione el país que corresponde a su información de pago:",
      "paymentHeaderMessage": "¡Felicitaciones por haber completado exitosamente la encuesta! Seleccione a continuación su opción de pago preferida y su información de contacto para que podamos enviarle la confirmación de pago por correo electrónico.",
      "bankTransferMessage": "Gracias por elegir transferencia bancaria. Tenga en cuenta que recibirá un enlace a nuestra plataforma bancaria segura Tipalti de parte de nuestro equipo de finanzas dentro de los próximos 5 días laborables. Revise su carpeta de spam; el correo electrónico puede llegar ahí. Si no desea recibir este correo electrónico, contacte a survey@newtonx.com con el tema de la encuesta en el asunto del correo electrónico.",
      "submit": "Enviar",
      "paypalEmailAddress": "Su dirección de correo electrónico de pago:",
      "amazonEmailAddress": "Su dirección de correo electrónico de pago:",
      "confirmPaypalEmailAddress": "Confirmar dirección de correo electrónico de pago:",
      "confirmAmazonEmailAddress": "Confirmar dirección de correo electrónico de pago:",
      // "notListed": "Non listé"
    }
  },
  ko: {
    translation: {
      "pageHeadline": "결제 정보",
      "Select a Language": "언어 선택",
      "unitedStates": "미국",
      "france": "프랑스",
      "canada": "캐나다",
      "unitedKingdom": "영국",
      "australia": "호주",
      "brazil": "브라질",
      "germany": "독일",
      "denmark": "덴마크",
      "sweden": "스웨덴",
      "mexico": "멕시코",
      "newZealand": "뉴질랜드",
      "india": "인도",
      "italy": "이탈리아",
      "japan": "일본",
      "indonesia": "인도네시아",
      "ukraine": "우크라이나",
      "dubai": "두바이",
      "southKorea": "한국",
      "china": "중국",
      "haiti": "아이티",
      "netherlands": "네덜란드",
      "chile": "칠레",
      // "other": "Autre Pays",
      "paymentType": "결제 유형",
      "paypal": "페이팔(PayPal)",
      "bankTransfer": "계좌 이체",
      "amazon": "아마존 페이(Amazon Pay)",
      "firstName": "이름:",
      "lastName": "성:",
      "contactEmail": "연락 가능한 이메일 주소",
      "confirmContactEmail": "연락 가능한 이메일 주소 재입력",
      "paymentEmailCheckbox": "결제용 이메일 주소는 연락 가능한 이메일 주소와 같습니다.",
      "paymentEmail": "결제용 이메일 주소:",
      "confirmPaymentEmail": "결제용 이메일 주소 재입력:",
      "Please select the country corresponding to your payment information": "귀하의 결제 정보에 해당하는 국가를 선택하여 주시기 바랍니다.",
      "paymentHeaderMessage": "설문을 성공적으로 완료하신 것을 축하드립니다! 아래에서 원하시는 결제방법과 연락처를 선택하시면 결제 확인서를 이메일로 발송하여 드리겠습니다. ",
      "bankTransferMessage": "계좌 이체를 선택해 주셔서 감사합니다. 당사의 재무팀에서 보안이 적용된 Tipalti 은행 플랫폼에 접속할 수 있는 링크를 영업일 5일 이내에 발송하여 드릴 것입니다. 발송 드리는 이메일이 스팸으로 분류될 수 있사오니 스팸 폴더도 확인하여 주시기 바랍니다. 이메일을 수신하지 못하시는 경우, 설문의 주제를 이메일 제목란에 기입하시어 survey@newtonx.com으로 연락 주시기 바랍니다. ",
      "submit": "Enviar",
      "paypalEmailAddress": "결제용 이메일 주소:",
      "amazonEmailAddress": "결제용 이메일 주소:",
      "confirmPaypalEmailAddress": "결제용 이메일 주소 재입력:",
      "confirmAmazonEmailAddress": "결제용 이메일 주소 재입력:",
    }
  },
  tr: {
    translation: {
      "pageHeadline": "ÖDEME BİLGİSİ",
      "Select a Language": "Bir Dil seçin",
      "unitedStates": "Amerika Birleşik Devletleri",
      "france": "Fransa",
      "canada": "Kanada",
      "unitedKingdom": "Birleşik Krallık",
      "australia": "Avustralya",
      "brazil": "Brezilya",
      "germany": "Almanya",
      "denmark": "Danimarka",
      "sweden": "İsveç",
      "mexico": "Meksika",
      "newZealand": "Yeni Zelanda",
      "india": "Hindistan",
      "italy": "İtalya",
      "japan": "Japonya",
      "indonesia": "Endonezya",
      "ukraine": "Ukrayna",
      "dubai": "Dubai",
      "southKorea": "Güney Kore",
      "china": "Çin",
      "haiti": "Haiti",
      "netherlands": "Hollanda",
      "chile": "Şili",
      // "other": "Autre Pays",
      "paymentType": "Ödeme Türü",
      "paypal": "Paypal",
      "bankTransfer": "Banka Transferi",
      "amazon": "Amazon",
      "firstName": "Adı:",
      "lastName": "Soyadı:",
      "contactEmail": "İletişim E-posta Adresiniz",
      "confirmContactEmail": "İletişim E-posta Adresini Onayla",
      "paymentEmailCheckbox": "Ödeme e-posta adresim iletişim e-posta adresimle aynı:",
      "paymentEmail": "Ödeme E-posta Adresiniz:",
      "confirmPaymentEmail": "Ödeme E-posta Adresini Onayla:",
      "Please select the country corresponding to your payment information": "Lütfen ödeme bilgilerinize karşılık gelen ülkeyi seçin:",
      "paymentHeaderMessage": "Anketi başarılı bir şekilde tamamladığınız için tebrikler! Size ödeme teyidinizi e-postayla iletebilmemiz için lütfen aşağıda tercih ettiğiniz ödeme seçeneğini ve iletişim bilgilerinizi seçin.",
      "bankTransferMessage": "Banka transferini seçtiğiniz için teşekkür ederiz. Önümüzdeki 5 iş günü içinde finans ekibimiz size, güvenli Tipalti banka platformumuza erişim için bir bağlantı gönderecek. İstenmeyen posta klasörünüzü kontrol edin, çünkü e-postanız bu klasöre girmiş olabilir. Bu e-postayı almazsanız, lütfen e-posta konu satırına anketin adını yazarak survey@newtonx.com adresi ile iletişime geçin.",
      "submit": "Gönder",
      "paypalEmailAddress": "Ödeme E-posta Adresiniz:",
      "amazonEmailAddress": "Ödeme E-posta Adresiniz:",
      "confirmPaypalEmailAddress": "Ödeme E-posta Adresini Onayla:",
      "confirmAmazonEmailAddress": "Ödeme E-posta Adresini Onayla:",
    }
  },

};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
});

export default i18n;