import React from 'react';
import CareerInfoDropdown from '../../components/common/CareerInfoDropdown'; 
import '../../styles/components/careers/OpenPositions.scss';

export default () => (
  <div className="open-position-container">
    <div className="open-position-content">
      <div className="open-position-header">
        <img src={require('../../images/careers/newtonxcareers@2x.png')} alt=""/>
        <span>Open Positions</span>
      </div>
      <div className="main-content">
        <CareerInfoDropdown 
          title="Marketplace Associate - "
          shortText="We are growing fast, and our Marketplace Associates are essential  
            in supporting this growth. We are looking for motivated people who 
            are excited by the idea to make a difference in the knowledge market by 
            helping us grow our expertise access engine.  This role is a great entry point 
            into future opportunities at our quickly growing company."
          buttonText="Learn More"
          href="https://angel.co/newtonx/jobs/197280-marketplace-associate"
        />

        <CareerInfoDropdown 
          title="Ninja Full Stack Developer - "
          shortText="As our Lead Platform Developer, you will help us build the next-generation 
            NewtonX platform, alongside a dedicated team. Our team is smart, visionary, 
            supportive and, above all, we are excited by what we do!"
          buttonText="Learn More"
          href="https://angel.co/newtonx/jobs/266516-ninja-full-stack-developer-of-newtonx-platform"
        />
        
        <CareerInfoDropdown 
          title="DevOps & Full-Stack Ninja - "
          shortText="To boost our platform to the next generation, we are looking 
            for that rare person who transcends development and technology operations. 
            In this role, you’ll work collaboratively work to deploy and operate our systems. 
            Help automate and streamline our operations and processes. Build and maintain tools 
            for deployment, monitoring and operations. And troubleshoot and resolve issues in our 
            dev, test and production environments."
          buttonText="Learn More"
          href="https://angel.co/newtonx/jobs/307329-devops-full-stack-ninja"
        />
      </div>
    </div>
  </div>
);