import React from 'react'
import DropdownElement from '../../common/DropdownElement';
import '../../../styles/components/homepage/ExpertCompliance.scss';
import termsAndConditionsPdf from '../../../assets/termsAndConditions.pdf';
export default () => (
  <div>
    <DropdownElement 
      description="Screening and Selection"
      text="We select our experts with extreme care and attention. 
        Each expert goes through a very selective evaluation and vetting process which:"
    >
      <ul>
        <li>Validates their identity based on public sources as well as their personal identification</li>
        <li>
          Granularly assesses their areas of expertise in order to guarantee 
          accurate matches with the expertise requests from our clients
        </li>
        <li>Ensures a granular mapping of their previous employers to accurately avoid any conflict of interest</li>
      </ul>
    </DropdownElement>
    <DropdownElement 
      description="Expert Terms and Conditions"
      text="In order to be accepted on our platform and share expertise with our clients, 
        each of our experts is required to sign the NewtonX terms and conditions annually. 
        These terms and conditions are binding, and through them the experts certify: "
    >
      <ul>
        <li> 	Having reviewed any agreements, policies or laws to which they are subject to</li>
        <li>Having expressly verified that they are permitted to join NewtonX</li>
        <li>
          Proactively verifying whether sharing their 
          expertise could violate any agreement, policy or law they may be subject to
        </li>
        <li>Declining or terminating any expertise sharing that moves into subject matters they cannot discuss</li>
        <li>Not providing confidential (including material non-public) information to clients</li>
        <li>Keeping all client information confidential (name, interests or any type of information)</li>
      </ul>
      <div className="bottom-links">
        <span><a style={{color: "inhert", textDecoration: "inherit"}} href={termsAndConditionsPdf} rel="noopener noreferrer" target="_blank">View the Experts Terms and Conditions</a></span>
        <span><a style={{color: "inhert", textDecoration: "inherit"}} href="https://www.research.net/r/newtonxexperttutorial" rel="noopener noreferrer" target="_blank">Review the NewtonX Expert Tutorial</a></span>
      </div>
    </DropdownElement>
    <DropdownElement 
      description="Expert Compliance Training"

      text="We have designed a tutorial illustrating unequivocally the rules of expert 
        knowledge sharing based on the latest legislation as well as NewtonX’s principles of 
        confidentiality and discretion. This tutorial includes a series of exercises as well as 
        questions and answers to ensure that our experts adopt the right framework when 
        sharing their knowledge. During these exercises they receive feedback along the way and 
        thereby can easily adopt the most professional way of knowledge sharing."

      textTwo="The tutorial explains what constitutes “confidential information”, 
        sensitizes experts to any confidentiality obligations they may have towards our client 
        as well as well as their current and previous employers, and emphasizes that our clients do 
        not want – and will actually be harmed – if they receive confidential information."

      textThree="We evolve in a dynamic industry and as such update our tutorial frequently. 
        Experts are required to take our tutorial at a minimum yearly, but may also be asked to 
        take the tutorial upon our request if we deem necessary."
    />
    <DropdownElement 
      description="Employer Confidentiality"

      text="Employees (including officers and directors) may not consult about their current employer – 
        regardless of whether the employer is publicly held or privately owned. This prohibition extends 
        to the parent and subsidiaries of an Advisor’s employer."

      textTwo="Furthermore, experts are also required to follow strict guidelines regarding their 
        previous employers. Even though they may share expertise related to the time of 
        their previous employment, they are strictly forbidden to share any confidential 
        information gained while working at any previous employer."

    />
    <DropdownElement 
      description="Transparent"

      text="Employees (including officers and directors) may not consult about their current 
        employer – regardless of whether the employer is publicly held or privately owned. This 
        prohibition extends to the parent and subsidiaries of an Advisor’s employer."


      textTwo="Furthermore, experts are also required to follow strict guidelines regarding 
        their previous employers. Even though they may share expertise related to the time of 
        their previous employment, they are strictly forbidden to share any confidential 
        information gained while working at any previous employer."
    />
  </div>
);