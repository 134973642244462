import React from 'react'

import '../../styles/components/homepage/WorkFlow.scss';

export default (props) => (
  <div className="work-flow-container">
    <div className="work-flow-header">
      <div className="work-flow-top-header">
        <img src={require('../../images/homepage/grey_lines@3x.png')} alt=""/>
      </div>
      <div className="work-flow-header-text">
        <span>How we work with our Experts -  </span>
      </div>
    </div>
    <div className="main-content" style={{ marginTop: '10px'}}>
      <div className="building-picture">
        <img src={require('../../images/homepage/building_header.png')} alt=""/>
      </div>
      <div className="main-content-text">
        <div className="text" style={{ width: props.width > 1300 && '80%'}}>
          <p>
            NewtonX ensures that we understand the specific expertise of each NewtonX 
            expert at a very granular level in order to propose only the consulting 
            opportunities that best fit their profiles.
          </p>
          <p>
            We leverage the NewtonX proprietary knowledge graph to build the specific expert profile, and our proprietary search engine to connect the right expert to the right client.
          </p>
        </div>
        <div className="bottom-lines-container">
          <img src={require('../../images/homepage/grey_lines_full.png')} alt=""/>
        </div>
      </div>
    </div>
  </div>
);