import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withToastManager } from 'react-toast-notifications';
import SignupAfterRedirectModalForm from './SignupAfterRedirectModalForm';
import SignupService from '../../utils/signupService';
import { closeSignupAfterRedirectModal, openThankYouSignupModal } from '../../actions/modalActions';
import { postUser } from '../../actions/signupActions';
import { POST_USER_SUCCESS, POST_USER_FAILURE, POST_USER_START } from '../../action-types/signup';

/**
 * After the expert's information has been retrieved
 * from LinkedIn, they arrive here to choose an email
 * and password.
 */
class SignupAfterRedirectModal extends Component {
    constructor() {
        super();
        this.state = {
            submitDisabled: false,
            alerts: null
        }

        this.signupService = new SignupService();
    }

    componentDidMount() {
        if (this.props.isOpen) {
            // navigate away from the CAS return link which contained the CAS auth token to keep the URL clean
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.postUserStatus !== this.props.postUserStatus &&
            this.props.postUserStatus !== POST_USER_START) {

            if (this.props.postUserStatus === POST_USER_FAILURE) {
                this.setState({
                    alerts: 'Unable to create account. If you already have a NewtonX account, please click "Login In" instead of "Sign Up". '
                });
            }
            
            this.setState({
                submitDisabled: false
            });
        }
    }

    handleFormSubmit = (email: string, password: string) => {
        this.setState({
            submitDisabled: true
        });
        let userInfo = {
            email: email,
            password: password,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            linkedInId: this.props.linkedInId,
        };

        this.props.postUser(userInfo);
    }

    handleClose = () => {
        this.props.closeSignupAfterRedirectModal();
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} className="bootstrap gothic-font">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Welcome to NewtonX</h5>
                        <button type="button" className="close" aria-label="Close" onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <br />
                    </div>
                    <div className="modal-body">
                        <SignupAfterRedirectModalForm alerts={this.state.alerts} email={this.props.email} onFormSubmit={this.handleFormSubmit} submitDisabled={this.state.submitDisabled} />
                    </div>
                </div>
            </Modal>


        )
    }
}

const mapStateToProps = ({ signup }) => ({
    email: signup.signupUser.email,
    firstName: signup.signupUser.firstName,
    lastName: signup.signupUser.lastName,
    linkedInId: signup.signupUser.linkedInId,
    postUserStatus: signup.postUserStatus
});

const mapDispatchToProps = (dispatch) => ({
    closeSignupAfterRedirectModal: () => {
        dispatch(closeSignupAfterRedirectModal())
    },

    openThankYouSignupModal: () => {
        dispatch(openThankYouSignupModal())
    },

    postUser: (userInfo) => {
        dispatch(postUser(userInfo));
    }
})

export default compose(
    withRouter,
    withToastManager,
    connect(mapStateToProps, mapDispatchToProps)
)(SignupAfterRedirectModal);