import React, { Component } from 'react';
import MainBlackBox from '../../components/common/MainBlackBox';
import InfoDropdowns from '../../components/howWeWork/InfoDropdowns';

export default class Compliance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    return (
      <div>
        <MainBlackBox 
          contentStyle={{ height: this.state.width < 415 ? '320px' :'250px'}}
          mainTitle="Compliance -  "
          text="Compliance is at the core of NewtonX values and guides the 
            daily activities of our teams. We believe in a world of knowledge access a
            nd sharing. Such a vision can only be achieved in strict accordance with compliance policies"
        />
        <InfoDropdowns width={this.state.width} />
      </div>
    );
  }
}
