import React from 'react'
import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Solution"
      text="In a matter of hours NewtonX provided the PE fund with experts 
        to start exploring market dynamics. Over the course of 10 days, the fund 
        spoke to more than 15 experts in the office printing space across North America, 
        Europe and Asia to understand local differences in market entry strategies. 
        The experts consulted included OEM sales executives, printing distributor
         mangers, printing resellers, and managed printing services providers."
    />
    <DropdownElement 
      description="Impact"
      text="Local Knowledge: The client learned the nuances of each market 
        (NA, Europe, Asia) and the different approaches needed to enter."
      textTwo="Partnerships: The client understood key partnerships across distributors, 
        OEMs, and resellers to effectively penetrate the corporate office market."
    />
  </div>
);