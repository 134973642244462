import React from 'react'

import '../../styles/common/DropdownElement.scss'

export default (props) => {
  return (
    <div className="dropdown-element-container">
      <div className="dropdown-element-content">
        <span>{props.description}</span>
        <p>
          {props.text}
          {props.textTwo && <p className="dropdown-element-paragraph" >{props.textTwo}</p> }
          {props.textThree && <p className="dropdown-element-paragraph" >{props.textThree}</p> }
          {props.children && props.children}
        </p>
      </div>
    </div>
  )
};