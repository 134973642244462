import React from 'react';
import { Route } from 'react-router-dom';

import Header from '../components/common/Header';
import TopHeader from '../components/common/TopHeader';
import ContactUs from '../components/common/ContactUs';
import Footer from '../components/common/Footer';
import GoogleAnalytics from '../components/analytics/GoogleAnalytics';
import HubspotAnalytics from '../components/analytics/HubspotAnalytics';

const InternalRoute = ({ component: Component, render, ...rest }) => {

  let route;

  if (render) {
    route = <Route {...rest} render={render} />;
  } else {
    route = (<Route {...rest} render={(props) => {
      return <Component {...props} />
    }} />);
  }

  return (
    <>
      <TopHeader />
      <Header />
      {route}
      <GoogleAnalytics />
      <HubspotAnalytics />
      <ContactUs />
      <Footer />
    </>
  )
}

export default InternalRoute; 