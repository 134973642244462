import React, { Component } from 'react'; 
import axios from 'axios';
import { environment } from '../../environments/env';

class DialinsContainer extends Component {

    state = {
        dialins: [],
    }

    async componentDidMount() {
        let response = await axios.get(environment.API_BASE_PATH + '/conference/dialins', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
        this.setState({
            dialins: response.data
        });

    }

    renderDialinsExceptUS = () => {
        return this.state.dialins
          .sort( (a, b) => {
            if (a.country.toUpperCase() < b.country.toUpperCase()) {
                return -1
            }
            if (a.country.toUpperCase() > b.country.toUpperCase()) {
                return 1
            }
            return 0
          })
          .filter( dialin => dialin.country !== 'United States')
          .filter( dialin => this.filterDuplicateDialins(dialin))
          .map( dialin => <li className="list-group-item">{dialin.country}: {dialin.dialin}</li>)
    }

    filterDuplicateDialins = (dialin) => {
        // several countries have more than one dialin, but only the correct one should be displayed
        if (dialin.country === 'Germany' && dialin.dialin !== '+4915735983880') {
            return false;
        } else if (dialin.country === 'Canada' && dialin.dialin !== '+16046708568') {
            return false;
        } else if (dialin.country === 'Poland' && dialin.dialin !== '+48523047386') {
            return false;
        } else {
            return true;
        }
    }

    renderUSDialin() {
        return(
            this.state.dialins
              .filter(dialin => dialin.country === 'United States' && dialin.dialin === '+19177468422')
              .map(dialin => <li className="list-group-item">{dialin.country}: {dialin.dialin}</li>)
        )
    }
    render() {
        return(
            <div className="bootstrap gothic-font">
                <ul className="list-group">
                    <h2 style={{paddingLeft: '.2em', }}>Conferencing Dialins</h2>
                    {this.renderUSDialin()}
                    {this.renderDialinsExceptUS()}
                </ul>
            </div>
        )
    }
}

export default DialinsContainer