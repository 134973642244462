const SurveyQuotaData = [
  {
    language: 'English',
    languageCode: 'en',
    content: `Dear Respondent,

        Unfortunately we have filled the quota of respondents corresponding to your specific profile for this survey and we are not accepting any more responses in this quota.
        We are sure we will have other opportunities to collaborate in the near future, and hope to be working with you again very soon.
        If you have any question or if you believe you have been mistakenly terminated, please contact us at survey@newtonx.com and mention the topic of the survey in the email title for reference.

        Kind regards,
        Your NewtonX Team`,
  },
  {
    language: '中文',
    languageCode: 'ch',
    content: `尊敬的受访者：

        很抱歉，本调查中与您具有同类资料的受访者已满额，我们将不再接收此类受访者。
        我们相信，在不远的未来还会有其他合作机会，期待很快能与您再次合作。
        如您有任何疑问或认为该终止存在错误，请发送电子邮件至 survey@newtonx.com 与我们联系，并在邮件标题中注明调查主题以供参考。

        谨致问候
        您的 NewtonX 团队`,
  },
  {
    language: 'Français',
    languageCode: 'fr',
    content: `
        Cher/Chère participant(e),

        Nous avons malheureusement atteint le quota des participants correspondant à votre profil spécifique pour cette enquête. Nous n’acceptons plus d’autres réponses pour ce quota.
        Nous aurons certainement l’occasion de vous proposer de nouvelles possibilités de collaboration dans un avenir proche, et nous espérons qu’elles vous intéresseront.
        Si vous avez des questions ou si vous estimez ne pas avoir été retenu(e) par erreur, veuillez nous contacter par mail à survey@newtonx.com en indiquant le sujet de l’enquête dans le titre de l’e-mail pour référence.

        Nous vous prions d’agréer, cher/chère participant(e), l’expression de nos sentiments distingués.
        Votre équipe NewtonX`,
  },
  {
    language: 'Deutsche',
    languageCode: 'de',
    content: `Geschätzter Teilnehmer,

        leider haben wir die Quote für Teilnehmer mit Ihrem spezifischen Profil für diese Umfrage erreicht und nehmen keine weiteren Antworten in diesem Segment mehr an.
        Wir sind sicher, dass wir Ihnen in der nahen Zukunft weitere Möglichkeiten zur Zusammenarbeit bieten können, und hoffen, sehr bald wieder mit Ihnen zusammenzuarbeiten.
        Wenn Sie Fragen haben oder glauben, dass Sie irrtümlich entfernt wurden, kontaktieren Sie uns bitte unter survey@newtonx.com und geben Sie in der Betreffzeile der E-Mail das Thema der Umfrage als Referenz an.

        Mit freundlichen Grüßen,
        Ihr NewtonX-Team`,
  },
  {
    language: 'Indonesia',
    languageCode: 'id',
    content: `Responden yang Terhormat,

        Sayangnya, kami telah memenuhi kuota responden yang sesuai dengan profil khusus Anda untuk survei ini serta kami tidak lagi menerima tanggapan dalam kuota ini.
        Kami meyakini akan terdapat kesempatan kerja sama lain dalam waktu dekat, dan kami berharap dapat segera bekerja sama lagi dengan Anda.
        Apabila Anda memiliki pertanyaan atau jika Anda meyakini telah diberhentikan dengan tidak semestinya, hubungilah kami di survey@newtonx.com dan sebutkan topik survei pada judul email sebagai referensi.

        Salam hormat,
        Tim NewtonX Anda`,
  },
  {
    language: '한국어',
    languageCode: 'ko',
    content: `응답자 님께,

        안타깝게도 본 설문에서 귀하의 특정 프로필에 해당되는 응답자 정원이 모두 마감되어 더 이상 응답자를 받지 않고 있습니다.
        근시일내에 협력할 수 있는 또 다른 기회가 있으리라 확신하며 곧 귀하와 함께 일할 수 있기를 바랍니다.
        질문이 있으시거나 귀하가 실수로 제외되었다고 생각되실 경우 survey@newtonx.com으로 연락주시기 바라며 이때 참고용으로 설문 주제를 이메일 제목에 기입해 주시면 감사하겠습니다.

        안녕히 계십시오,
        NewtonX 팀 드림`,
  },
  {
    language: 'Portuguesa',
    languageCode: 'pt',
    content: `Prezado participante,

        Infelizmente, preenchemos as vagas dos participantes com perfil correspondente ao seu para esta pesquisa. Portanto, encerramos o processo de seleção.
        Não se preocupe, pois teremos novas oportunidades de colaboração no futuro próximo, e esperamos trabalhar com você novamente em breve.
        Em caso de dúvidas ou se achar que foi desligado por engano, envie um e-mail para survey@newtonx.com e informe o tema da pesquisa no assunto da mensagem para fins de referência.

        Atenciosamente,
        Sua equipe NewtonX`,
  },
  {
    language: 'Española',
    languageCode: 'es',
    content: `Estimado encuestado:

            Lamentablemente, hemos completado la cuota de encuestados correspondiente a su perfil específico para esta encuesta y no aceptamos más respuestas en esta cuota.
            Estamos seguros de que tendremos otras oportunidades para que colabore en el futuro cercano y esperamos trabajar con usted pronto otra vez.
            Si tiene alguna pregunta o cree que lo hemos descartado por error, contáctenos a survey@newtonx.com y mencione el tema de la encuesta en el título del correo electrónico para referencia.

            Saludos cordiales,
            Su equipo de NewtonX`,
  },
  {
    language: '日本語',
    languageCode: 'ja',
    content: `ご回答者各位

    誠に残念ながら、本アンケートについて、あなたのプロフィールに相当する回答者が所定の数に達しましたので、当該定数における回答を締め切らせていただきます。
    近日中にご協力いただける別の機会が出てくることと思いますので、その際にご参加いただければ幸いです。
    ご質問がある場合や、ご自身の回答が誤りによって終了されたと思われる場合には、survey@newtonx.comにお問い合わせください。その際、参照用にアンケートのトピックを電子メールの件名にご記入ください。

    よろしくお願いいたします。
    NewtonXチーム`,
  },
  {
    language: 'Pусский',
    languageCode: 'ru',
    content: `Уважаемый респондент!

        К сожалению, мы уже заполнили квоту по респондентам для данного опроса по Вашему профилю и больше не принимаем ответы.
        В ближайшем будущем непременно появятся другие возможности для совместной работы, и мы надеемся, что скоро будем с Вами сотрудничать.
        Если у Вас появились вопросы или Вы считаете, что Вас исключили из опроса по ошибке, обращайтесь по адресу survey@newtonx.com и укажите в теме электронного письма название опроса.

        С уважением,
        Команда NewtonX`,
  },
  {
    language: 'TÜRK',
    languageCode: 'tr',
    content: `Sayın Katılımcı,

        Ne yazık ki bu anket için profilinize uygun katılımcı sayısı kotasına ulaştık ve bu anket için daha fazla katılımcı kabul edemiyoruz.
        Yakın gelecekte başka işbirliği fırsatlarının doğacağından eminiz ve çok yakında sizinle tekrar çalışmayı umuyoruz.
        Herhangi bir sorunuz varsa veya katılımınızın yanlışlıkla sonlandırıldığını düşünüyorsanız lütfen survey@newtonx.com adresinden bizimle iletişime geçin ve e-postanın konu kısmında referans olarak anketin konusunu belirtin.

        Saygılarımızla,
        NewtonX Ekibiniz`,
  },
];

export default SurveyQuotaData;
