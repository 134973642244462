const SurveyFlagData = [
  {
    language: 'English',
    languageCode: 'en',
    content: `Dear respondent,

    Our quality check system seems to indicate that you are not able to take the survey with the desired level of attention at this time.
    As a consequence of multiple failures on our quality checks for consistency, speed and attention we are forced to terminate this survey.
    If you believe you have indeed given the appropriate level of attention to this survey, please email us at survey@newtonx.com

    Best,
    Your NewtonX team`,
  },
  {
    language: '中文',
    languageCode: 'ch',
    content: `尊敬的受访者：

        我们的质量检查系统显示，您未达到期望的关注度，因而无法参与此次调查。
        由于您在我们对一致性、速度和关注度的质量检查中多次被判定为不合格，我们只能终止本调查。
        如果您认为自己确实拥有足够的关注度，可以参与本调查，请发送电子邮件至 survey@newtonx.com 与我们联系。

        祝好
        您的 NewtonX 团队`,
  },
  {
    language: 'Français',
    languageCode: 'fr',
    content: `Cher/Chère participant(e),

        Notre système de contrôle de la qualité semble indiquer que vous n’êtes pas en mesure de répondre à l’enquête en lui accordant le niveau d’attention souhaité pour le moment.
        En raison des multiples échecs liés à nos contrôles de la qualité en matière de cohérence, de rapidité et d’attention, nous sommes contraints de mettre fin à cette enquête.
        Si vous estimez avoir en fait consacré le niveau d’attention approprié à cette enquête, veuillez nous envoyer un e-mail à survey@newtonx.com.

        Nous vous prions d’agréer, cher/chère participant(e), l’expression de nos sentiments distingués.
        Votre équipe NewtonX`,
  },
  {
    language: 'Deutsche',
    languageCode: 'de',
    content: `Geschätzter Teilnehmer,

        unser Qualitätsprüfungssystem gibt an, dass Sie derzeit nicht in der Lage sind, mit der notwendigen Aufmerksamkeit an der Umfrage teilzunehmen.
        Aufgrund mehrerer Fehler bei unserer Qualitätsprüfung auf Konsistenz, Geschwindigkeit und Aufmerksamkeit müssen wir diese Umfrage abbrechen.
        Wenn Sie glauben, dass Sie dieser Umfrage doch die notwendige Aufmerksamkeit gewidmet haben, schicken Sie uns bitte eine E-Mail unter survey@newtonx.com

        Alles Gute
        Ihr NewtonX-Team`,
  },
  {
    language: 'Indonesia',
    languageCode: 'id',
    content: `Responden yang terhormat,

        Sistem pemeriksaan kualitas kami tampaknya menunjukkan bahwa Anda tidak dapat mengikuti survei dengan tingkat perhatian yang diinginkan saat ini.
        Kami terpaksa mengakhiri survei ini akibat sejumlah kegagalan pemeriksaan kualitas terhadap konsistensi, kecepatan, dan tingkat perhatiannya.
        Apabila Anda merasa telah memberikan perhatian yang memadai terhadap survei ini, kirimkanlah email kepada kami di survey@newtonx.com

        Semua yang terbaik,
        Tim NewtonX Anda`,
  },
  {
    language: '한국어',
    languageCode: 'ko',
    content: `응답자님께,

        당사의 품질 체크 시스템에 따르면 이번에는 귀하께서 충분한 주의를 기울여 설문을 작성하지 못한 것으로 판단됩니다.
        일관성, 스피드, 주의를 판단하는 품질 체크에서 다수의 문제가 발생하였기 때문에 본 설문은 폐기할 수밖에 없습니다.
        귀하가 실제로는 본 설문에 적절한 수준의 주의를 기울였다고 생각하신다면 survey@newtonx.com으로 연락주시기 바랍니다

        감사합니다,
        NewtonX 팀 드림`,
  },
  {
    language: 'Portuguesa',
    languageCode: 'pt',
    content: `Prezado participante,

        Nosso sistema de controle de qualidade indicou que, neste momento, você não pode participar da pesquisa com o nível de atenção desejado.
        Como resultado das várias falhas de padronização, agilidade e atenção identificadas por nosso controle de qualidade, somos obrigados a encerrar esta pesquisa.
        Se você acreditar que dedicou realmente o nível adequado de atenção a esta pesquisa, envie-nos um e-mail para survey@newtonx.com

        Atenciosamente,
        Sua equipe NewtonX`,
  },
  {
    language: 'Española',
    languageCode: 'es',
    content: `Estimado encuestado:

        Parece que nuestro sistema de control de calidad indica que no puede realizar esta encuesta con el nivel de atención requerido en este momento.
        Como consecuencia de múltiples fallas en nuestros controles de calidad de consistencia, velocidad y atención, nos vemos obligados a finalizar esta encuesta.
        Si cree que ha prestado el nivel adecuado de atención a esta encuesta, envíenos un correo electrónico a survey@newtonx.com

        Saludos,
        Su equipo de NewtonX`,
  },
  {
    language: '日本語',
    languageCode: 'ja',
    content: `
        ご回答者各位

        当社の品質チェックシステムによると、現段階で、あなたは求められる注意水準をもってアンケートにお答えいただけていないことが示されています。
        一貫性、速度、注意に関する品質チェックで複数の問題が示されているため、本アンケートを終了させていただきます。
        本アンケートに対して確実に適切な水準の注意を払っていると思われる場合は、当社まで電子メール（survey@newtonx.com）でご連絡ください。

        よろしくお願いいたします。
        NewtonXチーム`,
  },
  {
    language: 'Pусский',
    languageCode: 'ru',
    content: `Уважаемый респондент!

        Наша система оценки качества показала, что в этот раз Вы не сможете пройти опрос с желаемым уровнем внимания.
        В результате ряда непройденных проверок качества на согласованность, скорость и внимание мы вынуждены прекратить этот опрос.
        Если Вы считаете, что всё же уделили данному опросу должное внимание, напишите нам по адресу survey@newtonx.com.

        С наилучшими пожеланиями,
        Команда NewtonX`,
  },
  {
    language: 'TÜRK',
    languageCode: 'tr',
    content: `Sayın katılımcı,

        Kalite kontrol sistemimiz anketi gereken özeni göstererek dolduramadığınızı belirtiyor.
        Tutarlılık, hız ve dikkat açısından gerçekleştirdiğimiz kalite kontrollerinde birden fazla kez başarısız olduğunuz için bu anketi sonlandırmamız gerekiyor.
        Bu ankete gereken özeni gösterdiğinizi düşünüyorsanız lütfen survey@newtonx.com adresine bir e-posta gönderin

        Saygılarımızla,
        NewtonX ekibi`,
  },
];

export default SurveyFlagData;
