import React from 'react';

import '../../styles/components/homepage/HowItWorks.scss';

export default () => (
  <div className="how-it-works-container" style={{ background: '#F8F8F8'}}>
    <div className="how-it-works-header">
      <span className="bold-text">How it Works: Expert Calls</span>
      <span className="normal-text">Find Subject Matter Experts Across Any Topic, Geography, or Language</span>
    </div>
    <div className="how-it-works-icons">
      <div className="icon-container">
        <img style={{ width: '126px', height: '48px', paddingTop: '17px'}} src={require('../../images/howWeWork/icon_define@3x.png')} alt=""/>
        <span style={{marginTop: '20px'}}>Define Your Need</span>
      </div>
      <div className="icon-container">
        <img src={require('../../images/howWeWork/icon_choose@3x.png')} alt=""/>
        <span>Choose Your Experts</span>
      </div>
      <div className="icon-container">
        <img src={require('../../images/homepage/schedule@3x.png')} alt=""/>
        <span>Schedule Seamlessly</span>
      </div>
    </div>
    <div className="bottom-text-container">
      <p className="bottom-text">
        The NewtonX proprietary technologies make accessing experts as easy as 1-2-3. 
        We deliver a list of  top-tier experts, you take 
        your pick and we handle all the scheduling and screening for you.
      </p>
    </div>
  </div>
);