import { SEARCH_EXPERTS_SUCCESS, UPDATE_EXPERT_EMAIL_START, UPDATE_EXPERT_EMAIL_SUCCESS, UPDATE_EXPERT_EMAIL_FAILURE } from "../action-types/admin";

export default (state = {
  adminSearchResults: [],
  updateExpertEmailStatus: '',
  error: undefined,
  loading: false
}, action) => {
  switch (action.type) {
    case SEARCH_EXPERTS_SUCCESS:
      return {
        ...state,
        adminSearchResults: action.payload
      }
    case UPDATE_EXPERT_EMAIL_START:
      return {
        ...state,
        updateExpertEmailStatus: action.type,
        loading: true,
        error: undefined
      }
    case UPDATE_EXPERT_EMAIL_SUCCESS:
      return {
        ...state,
        updateExpertEmailStatus: action.type,
        loading: false,
        error: undefined
      }
    case UPDATE_EXPERT_EMAIL_FAILURE:
      return {
        ...state,
        updateExpertEmailStatus: action.type,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}