export const privacyTitle = 'NewtonX Data Privacy Policy';
export const securityTitle = 'NewtonX Security and Data Protection Policy';
export const privacyUpdatedDate = 'April 10, 2020';
export const securityUpdatedDate = 'December 24, 2018';

export const privacyData = [
  {
    title: 'THIS NOTICE',
    html: `
      <p>This Notice explains how we may Process your Personal Data. This Notice may be amended or updated from time to time, so please check it regularly for updates.</p>
      <p>This Notice is issued by NewtonX, Inc.® on behalf of itself and its affiliates (together, “NewtonX”, “we”, “us” and “our”) and is addressed to individuals outside our organisation with whom we interact, including customers, visitors to our Sites, and other users of our services (together, “you”). Defined terms used in this Notice are explained in Section (S) below. </p>
      <p>For the purposes of this Notice, NewtonX is the Controller. Contact details are provided in Section (R) below. </p>
      <p>This Notice may be amended or updated from time to time to reflect changes in our practices with respect to the Processing of Personal Data, or changes in applicable law. We encourage you to read this Notice carefully, and to regularly check this page to review any changes we might make in accordance with the terms of this Notice. </p>
    `
  },
  {
    title: 'Recourse mechanism for Privacy Shield Complaints',
    html: `
      <p>NewtonX complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union, the United Kingdom, and Switzerland to the United States in reliance on Privacy Shield.  NewtonX has certified that it adheres to the Privacy Shield Principles with respect to such data. If there is any conflict between the policies in this privacy policy and data subject rights under the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification page, please visit <a href=""https://www.privacyshield.gov/>https://www.privacyshield.gov/</a>.</p>
      <p>With respect to personal data received or transferred pursuant to the Privacy Shield Frameworks, NewtonX is subject to the regulatory and enforcement powers of the U.S. Federal Trade Commission. </p>
      <p>Pursuant to the Privacy Shield Frameworks, EU, UK, and Swiss individuals have the right to obtain our confirmation of whether we maintain personal information relating to you in the United States. Upon request, we will provide you with access to the personal information that we hold about you. You may also correct, amend, or delete the personal information we hold about you. An individual who seeks access, or who seeks to correct, amend, or delete inaccurate data transferred to the United States under Privacy Shield, should direct their query to <a href="mailto: experts@newtonx.com">experts@newtonx.com</a>. If requested to remove data, we will respond within a reasonable timeframe. </p>
      <p>We will provide an individual opt-out choice, or opt-in for sensitive data, before we share your data with third parties other than our agents, or before we use it for a purpose other than which it was originally collected or subsequently authorized. To request to limit the use and disclosure of your personal information, please submit a written request to <a href="mailto: experts@newtonx.com">experts@newtonx.com</a>.</p>
      <p>In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. </p>
      <p>NewtonX’s accountability for personal data that it receives in the United States under the Privacy Shield and subsequently transfers to a third party is described in the Privacy Shield Principles. In particular, NewtonX remains responsible and liable under the Privacy Shield Principles if third-party agents that it engages to process personal data on its behalf do so in a manner inconsistent with the Principles, unless NewtonX proves that it is not responsible for the event giving rise to the damage.</p>
      <p>In compliance with the Privacy Shield Principles, NewtonX commits to resolve complaints about your privacy and our collection or use of your personal information transferred to the United States pursuant to Privacy Shield. European Union, United Kingdom, and Swiss individuals with Privacy Shield inquiries or complaints should first contact NewtonX by email at <a href="mailto: experts@newtonx.com">experts@newtonx.com</a>.</p>
      <p>NewtonX has further committed to refer unresolved privacy complaints under the Privacy Shield Principles to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit <a href=https://bbbprograms.org/privacy-shield-complaints/">https://bbbprograms.org/privacy-shield-complaints/</a> for more information and to file a complaint. This service is provided free of charge to you.</p>
      <p>If your Privacy Shield complaint cannot be resolved through the above channels, under certain conditions, you may invoke binding arbitration for some residual claims not resolved by other redress mechanisms. See Privacy Shield Annex 1 at <a href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">https://www.privacyshield.gov/article?id=ANNEX-I-introduction</a>.</p>
    `
  },
  {
    title: 'COLLECTION OF PERSONAL DATA',
    html: `
      <p>Summary - Collection of Personal Data</p>
      <p>We may collect or obtain Personal Data about you: directly from you (e.g., where you contact us); in the course of our relationship with you (e.g., if you make a purchase); when you visit our Sites; when you register to use any of our Sites, or services; or when you interact with any third party content or advertising on a Site. We may also receive Personal Data about you from third parties (e.g., law enforcement authorities). </p>
      <p>Collection of Personal Data: We may collect Personal Data about you from the following sources: </p>
      <ul>
          <li>Data you provide: We may obtain your Personal Data when you provide it to us (e.g., where you contact us via email or telephone).</li>
          <li>Relationship data: We may collect or obtain your Personal Data in the ordinary course of our relationship with you (e.g., we provide a service to you, or to your employer). </li>
          <li>Expert data: We may collect or obtain your Personal Data if you apply to become an Expert. </li>
          <li>Registration details: We may collect or obtain your Personal Data when you use, or register to use, any of our Sites or services. </li>
      </ul>
    `
  },
  {
    title: 'CREATION OF PERSONAL DATA',
    html: `
        <p>Summary - Creation of Personal Data</p>
        <ul>
            <li>We may create Personal Data about you (e.g., records of your interactions with us). </li>
            <li>We may also create Personal Data about you, such as records of your interactions with us, our clients, or our Experts.</li>
        </ul>
    `
  },
  {
    title: 'CATEGORIES OF PERSONAL DATA WE MAY PROCESS',
    html: `
        <p>Summary - Categories of Personal Data we may Process</p>
        <ul>
            <li>We may Process: your personal details (e.g., your name, biographical information); demographic data; your contact details (e.g., your address); records of your consents; payment details; information about our Sites (e.g., the type of device you are using); details of your employer (where relevant); information about your interactions with our content or advertising; and any views or opinions you provide to us. </li>
        </ul>
        <p>We may Process the following categories of Personal Data about you: </p>
        <ul>
            <li>Personal details: given name(s); preferred name; and photograph (if provided). </li>
            <li>Demographic information: date of birth; salutation; title; and language preferences. </li>
            <li>Expert data: With respect to Experts (in addition to any other applicable category set out in this Notice), payment information, professional biography, and other profiling information pertaining to your experience and expertise. </li>
            <li>Contact details: address; telephone number; email address; and details of your public business networking profile(s) or online biographies. </li>
            <li>Consent records: records of any consents you may have given, together with the date and time, means of consent and any related information (e.g., the subject matter of the consent). </li>
            <li>Employer details: where you interact with us in your capacity as an employee, the name, address, telephone number and email address of your employer, to the extent relevant. </li>
            <li>Views and opinions: any views and opinions that you choose to send to us.</li>
        </ul>
    `
  },
  {
    title: 'LEGAL BASIS FOR PROCESSING PERSONAL DATA',
    html: `
      <p>Summary - Legal basis for Processing Personal Data</p>
      <p>We may Process your Personal Data where: you have given your prior, express consent; the Processing is necessary for a contract between you and us; the Processing is required by applicable law; the Processing is necessary to protect the vital interests of any individual; or where we have a valid legitimate interest in the Processing. </p>
      <ul>
          <li>In Processing your Personal Data in connection with the purposes set out in this Notice, we may rely on one or more of the following legal bases, depending on the circumstances: </li>
          <li>Consent: We may Process your Personal Data where we have obtained your prior, express consent to the Processing (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way); </li>
          <li>Contractual necessity: We may Process your Personal Data where the Processing is necessary in connection with any contract that you may enter into with us (for instance if you are a NewtonX Expert, have applied for Expert membership, or are a NewtonX client); </li>
          <li>Compliance with applicable law: We may Process your Personal Data where the Processing is required by applicable law; </li>
          <li>Vital interests: We may Process your Personal Data where the Processing is necessary to protect the vital interests of any individual; or </li>
          <li>Legitimate interests: We may Process your Personal Data where we have a legitimate interest in the Processing. The balancing test we have conducted in each case is as follows: o we have ensured that the Processing is lawful, proportionate, and conducted in accordance with the terms of this Notice; o we have ensured that we have a legitimate business need to perform the Processing; and o we have ensured that there is no material likelihood of any adverse impact on your interests, fundamental rights, or freedoms, as a result of the Processing. </li>
      </ul>
    `
  },
  {
    title: 'SENSITIVE PERSONAL DATA',
    html: `
        <p>Summary - Sensitive Personal Data</p>
        <p>We do not seek to collect or otherwise Process your Sensitive Personal Data. Where we need to Process your Sensitive Personal Data for a legitimate purpose, we do so in accordance with applicable law. </p>
        <p>We do not seek to collect or otherwise Process Sensitive Personal Data in the ordinary course of our business. Where it becomes necessary to Process your Sensitive Personal Data for any reason, we rely on one of the following legal bases: </p>
        <ul>
            <li>Compliance with applicable law: We may Process your Sensitive Personal Data where the Processing is required or permitted by applicable law (e.g., to comply with our diversity reporting obligations); </li>
            <li>Detection and prevention of crime: We may Process your Sensitive Personal Data where the Processing is necessary for the detection or prevention of crime (including the prevention of fraud); </li>
            <li>Establishment, exercise or defence of legal rights: We may Process your Sensitive Personal Data where the Processing is necessary for the establishment, exercise or defence of legal rights; or </li>
            <li>Consent: We may Process your Sensitive Personal Data where we have, in accordance with applicable law, obtained your prior, express consent prior to Processing your Sensitive Personal Data (this legal basis is only used in relation to Processing that is entirely voluntary – it is not used for Processing that is necessary or obligatory in any way). </li>
        </ul>
        <p>If you provide Sensitive Personal Data to us, you must ensure that it is lawful for you to disclose such data to us, including ensuring that one of the legal bases set out above is available to us with respect to the Processing of those Sensitive Personal Data. </p>
    `
  },
  {
    title: 'PURPOSES FOR WHICH WE MAY PROCESS YOUR PERSONAL DATA',
    html: `
        <p>Summary - This Purposes for which we may Process your Personal Data</p>
        <p>We may Process your Personal Data for the following purposes: providing services to you; compliance checks; operating our Sites; communicating with you; managing our IT systems; financial management; conducting surveys; ensuring the security of our premises and systems; conducting investigations where necessary; compliance with applicable law; and improving our Sites, and services. </p>
        <p>We may Process your Personal Data for the following purposes, subject to applicable law: </p>
        <ul>
            <li>Provision of services: providing our Sites or services; providing services on request; and communicating with you in relation to those services. </li>
            <li>Our Sites: operating and managing our Sites; providing content to you; displaying advertising and other information to you; communicating and interacting with you via our Sites; and notifying you of changes to any of our Sites, or our services. </li>
            <li>Experts: communicating with you regarding your Expert membership (or prospective Expert membership), where applicable, to provide you with opportunities to participate in projects, and to keep you up to date with new developments at NewtonX.</li>
            <li>Communications: communicating with you via any means (including via email, telephone, text message, social media, post or in person) information in which you may be interested (e.g., upcoming NewtonX events, new product offerings, information relevant to you as a NewtonX client or Expert), subject to ensuring that such communications are provided to you in compliance with applicable law; maintaining and updating your contact information where appropriate; and obtaining your prior, opt-in consent where required. </li>
            <li>Communications and IT operations: management of our communications systems; operation of IT security systems; and IT security audits. </li>
            <li>Financial management: sales; finance; corporate audit; and vendor management. </li>
            <li>Surveys: engaging with you for the purposes of obtaining your views on our services. </li>
            <li>Security: physical security of our premises (including records of visits to our premises; and CCTV recordings); and electronic security (including login records and access details).</li>
            <li>Investigations: detecting, investigating and preventing breaches of policy, fraud, and violations of law, in accordance with applicable law. </li>
            <li>Legal proceedings: establishing, exercising and defending legal rights. </li>
            <li>Legal compliance: compliance with our legal and regulatory obligations under applicable law. </li>
            <li>Improving our Sites, services: identifying issues with our Sites, or our services; planning improvements to our Sites, or our services; and creating new Sites, or services. </li>
        </ul>
    `
  },
  {
    title: 'DISCLOSURE OF PERSONAL DATA TO THIRD PARTIES',
    html: `
        <p>Summary - Disclosure of Personal Data to third parties </p>
        <p>We may disclose your Personal Data to: legal and regulatory authorities; our external advisors; our Processors; any party as necessary in connection with legal proceedings; any party as necessary for investigating, detecting or preventing criminal offences; any purchaser of our business; and any third party providers of advertising, plugins or content used on our Sites. </p>
        <p>We may disclose your Personal Data to other entities within the NewtonX group, for legitimate business purposes (including operating our Sites, and providing services to you), in accordance with applicable law. In addition, we may disclose your Personal Data to: </p>
        <ul>
            <li>you and, where appropriate, your appointed representatives; </li>
            <li>legal and regulatory authorities, upon request, or for the purposes of reporting any actual or suspected breach of applicable law or regulation;</li>
            <li>accountants, auditors, lawyers and other outside professional advisors to NewtonX, subject to binding contractual obligations of confidentiality; </li>
            <li>third party Processors (such as payment services providers; survey partners, marketing outreach providers, cloud service providers, etc.), located anywhere in the world, subject to the requirements noted below in this Section (H); </li>
            <li>any relevant party, law enforcement agency or court, to the extent necessary for the establishment, exercise or defence of legal rights; </li>
            <li>any relevant party for the purposes of prevention, investigation, detection or prosecution of criminal offences or the execution of criminal penalties; </li>
            <li>any relevant party if we believe disclosure is necessary and appropriate to prevent physical, financial, or other harm, injury, or loss; </li>
            <li>any relevant third party acquirer(s), in the event that we sell or transfer all or any relevant portion of our business or assets (including in the event of a reorganization, dissolution or liquidation); and </li>
            <li>any relevant third party provider, where our Sites use third party advertising, plugins or content. If you choose to interact with any such advertising, plugins or content, your Personal Data may be shared with the relevant third party provider. We recommend that you review that third party’s privacy policy before interacting with its advertising, plugins or content. </li>
            <li>If we engage a third-party Processor to Process your Personal Data, the Processor will be subject to binding contractual obligations to: (i) only Process the Personal Data in accordance with our prior written instructions; and (ii) use measures to protect the confidentiality and security of the Personal Data; together with any additional requirements under applicable law. </li>
        </ul>
    `
  },
  {
    title: 'INTERNATIONAL TRANSFER OF PERSONAL DATA',
    html: `
        <p>Summary - International transfer of Personal Data</p>
        <p>We may transfer your Personal Data to recipients in other countries. Where we transfer Personal Data from the EEA to a recipient outside the EEA that is not in an Adequate Jurisdiction, we do so on the basis of Standard Contractual Clauses. </p>
        <p>Because of the international nature of our business, we may need to transfer your Personal Data within the NewtonX group, and to third parties as noted in Section (H) above, in connection with the purposes set out in this Notice. For this reason, we may transfer your Personal Data to other countries that may have different laws and data protection compliance requirements to those that apply in the country in which you are located. </p>
        <p>Where we transfer your Personal Data from the EEA to recipients located outside the EEA who are not in Adequate Jurisdictions, we do so on the basis of Standard Contractual Clauses. You may request a copy of our Standard Contractual Clauses using the contact details provided in Section (R) below. </p>
        <p>If you are located outside of the United States, you should be aware that the Personal Data you provide to us is being transmitted to us and Processed in the United States, and will be protected subject to this privacy policy and United States laws, which may not be as protective as the laws in your country. Please note that when you transfer any Personal Data directly to a NewtonX entity established outside the EEA, we are not responsible for that transfer of your Personal Data. We will nevertheless Process your Personal Data, from the point at which we receive those data, in accordance with the provisions of this Notice. </p>
`
  },
  {
    title: 'DATA SECURITY',
    html: `
        <p>Summary - Data security </p>
        <p>We implement appropriate technical and organisational security measures to protect your Personal Data. Please ensure that any Personal Data that you send to us are sent securely. </p>
        <p>We have implemented appropriate technical and organisational security measures designed to protect your Personal Data against accidental or unlawful destruction, loss, alteration, unauthorised disclosure, unauthorised access, and other unlawful or unauthorised forms of Processing, in accordance with applicable law. </p>
        <p>Because the internet is an open system, the transmission of information via the internet is not completely secure. Although we will implement all reasonable measures to protect your Personal Data, we cannot guarantee the security of your data transmitted to us using the internet – any such transmission is at your own risk and you are responsible for ensuring that any Personal Data that you send to us are sent securely. </p>
`
  },
  {
    title: 'DATA ACCURACY',
    html: `
        <p>Summary - Data accuracy</p>
        <p>We take every reasonable step to ensure that your Personal Data is kept accurate and up-to-date and is erased or rectified if we become aware of inaccuracies. </p>
        <p>We take every reasonable step to ensure that: </p>
        <ul>
            <li>Your Personal Data that we Process are accurate and, where necessary, kept up to date; and </li>
            <li>any of your Personal Data that we Process that are inaccurate (having regard to the purposes for which they are Processed) are erased or rectified without delay. </li>
            <li>From time to time we may ask you to confirm the accuracy of your Personal Data. </li>
        </ul>
`
  },
  {
    title: 'DATA MINIMISATION',
    html: `
        <p>Summary - Data minimisation</p>
        <p>We take every reasonable step to limit the volume of your Personal Data that we Process to what is necessary. </p>
        <p>We take every reasonable step to ensure that your Personal Data that we Process are limited to the Personal Data reasonably necessary in connection with the purposes set out in this Notice.</p>
    `
  },
  {
    title: 'DATA RETENTION',
    html: `
    <p>Summary - Data retention</p>
    <p>We take every reasonable step to ensure that your Personal Data are only retained for as long as they are needed in connection with a lawful purpose. </p>
    <p>We take every reasonable step to ensure that your Personal Data are only Processed for the minimum period necessary for the purposes set out in this Notice. The criteria for determining the duration for which we will retain your Personal Data are as follows: </p>
    <p>(1) we will retain copies of your Personal Data in a form that permits identification only for as long as: (a) we maintain an ongoing relationship with you (e.g., where you are a user of our services, are a Expert(or have applied to become one), or you are lawfully included in our mailing list and have not unsubscribed); (b) should you cease being a Expert, six (6) years from your most recent interaction with any NewtonX client; or (c) your Personal Data are necessary in connection with the lawful purposes set out in this Notice, for which we have a valid legal basis (e.g., where we have a legitimate interest in processing your data for the purposes of operating our business and fulfilling our obligations under a contract), plus: (2) the duration of: (a) any applicable limitation period under applicable law (i.e., any period during which any person could bring a legal claim against us in connection with your Personal Data, or to which your Personal Data may be relevant); and (b) an additional two (2) month period following the end of such applicable limitation period (so that, if a person bring a claim at the end of the limitation period, we are still afforded a reasonable amount of time in which to identify any Personal Data that are relevant to that claim), and: (3) in addition, if any relevant legal claims are brought, we may continue to Process your Personal Data for such additional periods as are necessary in connection with that claim.</p>
    <p>During the periods noted in paragraphs (2)(a) and (2)(b) above, we will restrict our Processing of your Personal Data to storage of, and maintaining the security of, those data, except to the extent that those data need to be reviewed in connection with any legal claim, or any obligation under applicable law. </p>
    <p>Once the periods in paragraphs (1), (2) and (3) above, each to the extent applicable, have concluded, we will either: * permanently delete or destroy the relevant Personal Data; or * anonymize the relevant Personal Data. </p>
`
  },
  {
    title: 'YOUR LEGAL RIGHTS',
    html: `
        <p>Summary - Your legal rights</p>
        <p>Under applicable law, you may have a number of rights, including: the right not to provide your Personal Data to us; the right of access to your Personal Data; the right to request rectification of inaccuracies; the right to request the erasure, or restriction of Processing, of your Personal Data; the right to object to the Processing of your Personal Data; the right to have your Personal Data transferred to another Controller; the right to withdraw consent; and the right to lodge complaints with Data Protection Authorities. We may require proof of your identity before we can give effect to these rights.</p>
        <p>Subject to applicable law, you may have a number of rights regarding the Processing of your Relevant Personal Data, including: </p>
        <ul>
            <li>the right not to provide your Personal Data to us (however, please note that we may be unable to provide you with the full benefit of our Sites, or our services, if you do not provide us with your Personal Data – e.g., we may not be able to process your orders without the necessary details); </li>
            <li>the right to request access to, or copies of, your Relevant Personal Data, together with information regarding the nature, Processing and disclosure of those Relevant Personal Data; </li>
            <li>the right to request rectification of any inaccuracies in your Relevant Personal Data;</li>
        </ul>
        <p>the right to request, on legitimate grounds: </p>
        <ul>
            <li>erasure of your Relevant Personal Data; or restriction of Processing of your Relevant Personal Data; the right to object, on legitimate grounds, to the Processing of your Relevant Personal Data by us or on our behalf; </li>
            <li>the right to have certain Relevant Personal Data transferred to another Controller, in a structured, commonly used and machine-readable format, to the extent applicable; </li>
            <li>where we Process your Relevant Personal Data on the basis of your consent, the right to withdraw that consent (noting that such withdrawal does not affect the lawfulness of any Processing performed prior to the date on which we receive notice of such withdrawal, and does not prevent the Processing of your Personal Data in reliance upon any other available legal bases); and </li>
            <li>the right to lodge complaints regarding the Processing of your Relevant Personal Data with a Data Protection Authority (in particular, the Data Protection Authority of the EU Member State in which you live, or in which you work, or in which the alleged infringement occurred, each if applicable).  A list of Supervisory Authorities is available here:  <a href="https://edpb.europa.eu/about-edpb/board/members_en">https://edpb.europa.eu/about-edpb/board/members_en</a>.</li>
        </ul>
        <p>This does not affect your statutory rights. </p>
        <p>To exercise one or more of these rights, or to ask a question about these rights or any other provision of this Notice, or about our Processing of your Personal Data, please use the contact details provided in Section (R) below. Please note that: </p>
        <p>We may require proof of your identity before we can give effect to these rights; and where your request requires the establishment of additional facts (e.g., a determination of whether any Processing is non-compliant with applicable law) we will investigate your request reasonably promptly, before deciding what action to take. </p>
`
  },
  {
    title: 'COOKIES AND SIMILAR TECHNOLOGIES',
    html: `
        <p>Summary - Cookies and similar technologies</p>
        <p>We may Process your Personal Data by placing or reading Cookies and similar technologies. For more information, please see our Cookie Policy. </p>
        <p>When you visit a Site we may place Cookies onto your device, or read Cookies already on your device, subject always to obtaining your consent, where required, in accordance with applicable law. We use Cookies to record information about your device, your browser and, in some cases, your preferences and browsing habits. We may Process your Personal Data through Cookies and similar technologies, in accordance with our Cookie Policy. </p>
`
  },
  {
    title: 'DIRECT MARKETING',
    html: `
        <p>Summary - Direct marketing</p>
        <p>We may Process your Personal Data to contact you with information regarding services that may be of interest to you. You may unsubscribe for free at any time. </p>
        <p>We may Process your Personal Data to contact you via email, telephone, direct mail or other communication formats to provide you with information regarding services that may be of interest to you. If we provide services to you, we may send information to you regarding our services, upcoming promotions and other information that may be of interest to you, using the contact details that you have provided to us and always in compliance with applicable law. </p>    
        <p>You may unsubscribe from our promotional email list at any time by simply clicking on the unsubscribe link included in every promotional email we send. After you unsubscribe, we will not send you further promotional emails, but we may continue to contact you to the extent necessary for the purposes of any services you have requested. </p>
`
  },
  {
    title: 'CONTACT DETAILS',
    html: `
        <p>Summary - Contact details</p>
        <p>You may contact us at via post, telephone, fax, email or via our online Contact Us form. </p>
        <p>If you have any comments, questions or concerns about any of the information in this Notice, or any other issues relating to the Processing of Personal Data carried out by us, or on our behalf, please contact: </p>
        <ul>
            <li>Legal Department NewtonX, Inc. 60 East 42nd Street 3rd Floor New York NY 10165 </li>    
            <li>Phone: (212) 984-8500 </li>
            <li>Fax: (212) 984-2485 </li>
            <li>Email: <a href="mailto: privacy@newtonx.com">privacy@newtonx.com</a> </li>
        </ul>
`
  },
  {
    title: 'DEFINITIONS',
    html: `
        <p>“Adequate Jurisdiction” means a jurisdiction that has been formally designated by the European Commission as providing an adequate level of protection for Personal Data. </p>
        <p>“Cookie” means a small file that is placed on your device when you visit a website (including our Sites). In this Notice, a reference to a “Cookie” includes analogous technologies such as web beacons and clear GIFs.</p>
        <p>“Controller” means the entity that decides how and why Personal Data are Processed. In many jurisdictions, the Controller has primary responsibility for complying with applicable data protection laws. </p>
        <p>“Expert” means any person who has executed a version of the NewtonX Terms & Conditions of Expert membership and not had their membership discontinued (either by NewtonX or at their request). . </p>
        <p>“Data Protection Authority” means an independent public authority that is legally tasked with overseeing compliance with applicable data protection laws. </p>
        <p>“EEA” means the European Economic Area. </p>
        <p>“Personal Data” means information that is about any individual, or from which any individual is directly or indirectly identifiable, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that individual. </p>
        <p>“Process”, “Processing” or “Processed” means anything that is done with any Personal Data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction. </p>
        <p>“Processor” means any person or entity that Processes Personal Data on behalf of the Controller (other than employees of the Controller). </p>
        <p>“Profiling” means any form of automated Processing of Personal Data consisting of the use of Personal Data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person’s performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements. </p>
        <p>“Relevant Personal Data” means Personal Data in respect of which we are the Controller. </p>
        <p>“Sensitive Personal Data” means Personal Data about race or ethnicity, political opinions, religious or philosophical beliefs, trade union membership, physical or mental health, sexual life, any actual or alleged criminal offences or penalties, national identification number, or any other information that may be deemed to be sensitive under applicable law.</p>
        <p>“Standard Contractual Clauses” means template transfer clauses adopted by the European Commission or adopted by a Data Protection Authority and approved by the European Commission. </p>
        <p>“Site” means any website operated, or maintained, by us or on our behalf. </p>
`
  }

];

export const securityData = [
  {
    title: 'Personnel Security & Conduct',
    html: `
      <p> NewtonX’s internal policies define the guidelines for employees to operate the NewtonX platform and associated technologies, and serve to protect information assets. These policies include, but are not limited to, the following: </p>
      <ul>
          <li>Identification, Authentication, and Access Control</li>
          <li>Security Awareness</li>
          <li>Acceptable Use</li>
          <li>Risk Assessment</li>
          <li>Accountability</li>
          <li>Vulnerability Management</li>
          <li>Incident Response</li>
      </ul>
      <p> The NewtonX security and senior management team reviews and approves policies intermittently —or when there is a significant change that impacts how controls or policies operate. </p>
      <p> As part of the NewtonX employee onboarding process, all new hires are trained to observe and uphold all of the company’s privacy policies and procedures as implemented or varied from time to time. </p>
    `,
  },
  {
    title: 'Data Storage',
    html: `
      <p> NewtonX stores two categories of data: structured and unstructured. Structured data—including our expert profiles, client profiles, and project information entered into the NewtonX platform—are stored within a Google Cloud SQL database. Unstructured data—including files uploaded by our clients or experts and recordings —are stored in Google Cloud Storage. We tag all client data, and backend logical access controls prevent one client from seeing the data of another client. </p>
      <p> NewtonX controls and restricts employee, client, and expert interaction with data via role-based access permissions that prevent users from accessing data without given permissions. </p>
    `,
  },
  {
    title: 'Data and Platform Access',
    html: `
      <p> NewtonX tightly controls access to systems, proprietary platforms, and data by limiting access levels based on job responsibilities. Only NewtonX senior employees have access to the NewtonX platform and the data it holds. Our technology and security team reviews permissions on a quarterly basis to help ensure that access remains appropriate to active and authorized personnel. When an employee departs from NewtonX, our process dictates that we immediately revoke access to all systems. </p>
      <p> The NewtonX Platform can integrate with clients’ various Single Sign-On (SSO) solutions via Security Assertion Markup Language (SAML), enabling streamlined management of client personnel who access the platform. </p>
    `,
  },
  {
    title: 'Data Encryption',
    html: `
      <p> NewtonX configures the load balancers to support secure Transport Layer Security (TLS) connections between end-point devices and the NewtonX Platform to help ensure the secure transmission of information over public networks. We also encrypt all data stored on the Platform at rest, using advanced encryption standard (AES) 256-bit encryption. </p>
    `,
  },
  {
    title: 'Vulnerability Management',
    html: `
      <p> Security vulnerabilities that threaten the confidentiality, integrity, and availability of data arise as a product of technological advances and changes to the NewtonX platform code. We continuously monitor for security vulnerabilities through methods including, but not limited to, subscriptions with our vendors and reputable researchers, as well as monthly vulnerability scanning of the Platform and underlying systems. We triage all vulnerabilities to determine impact and patch our systems according to criticality. The architecture of the Platform enables our engineering team to deploy security patches seamlessly without disrupting our clients’ experience. </p>
      <p> Additionally, NewtonX regularly conducts internal penetration testing of our web applications and the underlying infrastructure to identify security vulnerabilities. Clients who wish to perform their own independent testing should contact their NewtonX Account Manager. </p>
    `,
  },
  {
    title: 'Platform Updates and Changes',
    html: `
      <p> The NewtonX product and engineering teams participate in the agile Systems Development Life Cycle (SDLC). The NewtonX SLDC includes a number of controls to help ensure development efforts are well-designed and secure. Controls in place include, but are not limited to, robust inter-team testing, code reviews, and management approvals prior to implementing a change into the production environment. NewtonX also restricts the ability to implement changes in the production environment to a small number of authorized engineers and technologists. </p>
    `,
  },
  {
    title: 'Distributed Architecture',
    html: `
      <p> NewtonX leverages Google Cloud’s distributed architecture, spreading its data footprint over multiple regions, and multiple zones within those regions. NewtonX uses a container-based architecture, which allows us to easily launch the NewtonX Platform infrastructure within another cloud provider, or from within our own hosted environment, if needed. </p>
    `,
  },

  {
    title: 'SOC 2 Evaluation',
    html: `
      <p> To help ensure that our controls are designed appropriately and operating effectively, NewtonX undergoes an annual SOC 2 examination from an independent third-party audit firm. Please contact your NewtonX Account Manager to request a copy of our most recent SOC 2 audit report. </p>
    `,
  },
  {
    title: 'Privacy Policy',
    html: `
      <p> At NewtonX, our clients’ and experts’ privacy is extremely important to us. NewtonX has designed privacy program to meet General Data Protection Regulation (GDPR) requirements, that you can find <a routerlink="/privacy" href="/privacy">here</a>. We are happy to sign data processing addendums and model clauses with our clients. </p>
    `,
  },
  {
    title: 'Google Cloud',
    html: `
      <p> The NewtonX Platform is built on the Google Cloud Platform. As part of Google’s service offering, Google takes responsibility for physical and environmental security, availability, routing, switching and networking controls. Google data centers are equipped with state-of-the-art physical security controls including, but not limited to, multi-factor authentication (badge access card and biometric), strict role-based access, security guard monitoring, video surveillance systems, and access logging and monitoring. Google deploys environmental security controls within their data centers to ensure systems remain fully operational that include redundant generators, uninterruptible power supply (UPS) systems, cooling systems, and fire detection and suppression systems. </p>
      <p> Google provides NewtonX with additional built-in protections that include, but are not limited to: </p>
      <ul>
          <li> Vulnerability management: Any Google software consumed by NewtonX automatically updates to most current version. </li>
          <li> Key management: NewtonX’s keys are stored in Google’s secure key management system (KMS). </li>
          <li> Traffic load increase protection: Google’s Autoscaler adds the appropriate resources to handle traffic spikes when required. </li>
      </ul>
    `,
  },
  {
    title: 'Third-Party Providers',
    html: `
      <p> NewtonX may use third parties for the NewtonX Platform and Services. We employ a third-party supplier evaluation process that requires all service providers follow the appropriate administrative, technical, and physical safeguards to ensure our security policy is maintained. This includes the review of any third-party audit reporting, penetration testing, vulnerability scanning results, and more. </p>
    `,
  },
];