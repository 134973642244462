import React from 'react'
import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Solution"
      text="NewtonX used its proprietary identification engine to identify the 
      highest-level blockchain experts in the world. This automated engine 
      identified 10 people in the world with a deep understanding of 
      cryptocurrency and the technology that enables it, and we promptly 
      reached out to all of them. The client chose to meet with the founding 
      member of one of the biggest cryptocurrencies in use today."
    />
    <DropdownElement 
      description="Impact"
      text="The V.C. Firm utilized the expert’s knowledge to pivot their approach 
        to the investment opportunity. The expert worked as an advisor to the VC 
        firm after being connected with them, and then eventually became a C-level executive."
    />
  </div>
);