import React, { Component } from 'react';
import MainBlackBox from '../../components/common/MainBlackBox';
import OpenPositions from '../../components/careers/OpenPositions';

export default class Careers extends Component {
  render() {
    return (
      <div>
        <MainBlackBox 
          contentStyle={{ height: '250px'}}
          mainTitle="At NewtonX, we work collaboratively to make knowledge sharing easy, fast and rewarding."
          subTitle="Careers"
        /> 
        <OpenPositions />
      </div>
    );
  }
}