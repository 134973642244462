import React, { Component } from 'react'; 
import { Formik } from 'formik'; 
import { connect } from 'react-redux'; 

export class DomesticPaymentEditForm extends Component{
    constructor(props) {
        super(props);

        this.state = {
            accountNumberFieldDisabled: this.props.expertPayment.domesticAccountNumber,
            routingNumberFieldDisabled: this.props.expertPayment.domesticRoutingNumber,
            currentAccountNumber: this.props.expertPayment.domesticAccountNumber || '',
            currentRoutingNumber: this.props.expertPayment.domesticRoutingNumber || '',

        }
    }

    handleAccountEditOrResetClick = (formikValues, setFormikValues) => {
        if (this.state.accountNumberFieldDisabled === false) {
            setFormikValues({
                ...formikValues,
                domesticAccountNumber: this.props.expertPayment.domesticAccountNumber
            })
            this.setState({
                accountNumberFieldDisabled: true,
            });
        } else {
            setFormikValues({
                ...formikValues,
                domesticAccountNumber: ''
            })
            this.setState({
                accountNumberFieldDisabled: false, 
            });
        }
    }

    handleRoutingEditOrResetClick = (formikValues, setFormikValues) => {
        // setFormikValues({
        //     ...formikValues,
        //     domesticRoutingNumber: this.state.accountNumberFieldDisabled ? '' : this.props.expertPayment.domesticRoutingNumber
        // })

        setFormikValues({})
        if (this.state.routingNumberFieldDisabled === false) {
            this.setState({
                routingNumberFieldDisabled: true,
            });
            setFormikValues({
                ...formikValues,
                domesticRoutingNumber: this.props.expertPayment.domesticRoutingNumber
            })
        } else {
            setFormikValues({
                ...formikValues,
                domesticRoutingNumber: ''
            })
            this.setState({
                routingNumberFieldDisabled: false, 
            });
        }
    }

    validateErrors = (values) => {
        console.log('Validating errors!', values);
        let errors = {};
        if (!values.domesticAccountNumber) {
            errors.domesticAccountNumber = 'Required';
        } 
        if (values.domesticAccountNumber.length < 3 || values.domesticAccountNumber.length > 18) {
            errors.domesticAccountNumber = 'Account number must be between 3 and 18 digits';
        }
        if (!values.domesticRoutingNumber) {
            errors.domesticRoutingNumber = 'Required';
        }
        if (values.domesticRoutingNumber.length !== 9) {
            errors.domesticRoutingNumber = 'Routing number must be 9 digits'; 
        }
        return errors; 
    }

    render(){
        return(
            <Formik
            initialValues={{ domesticAccountNumber: this.state.currentAccountNumber, domesticRoutingNumber: this.state.currentRoutingNumber, domesticNotes: this.props.expertPayment.domesticNotes}}
            enableReinitialize={false}
            validate={this.validateErrors}
            onSubmit={(values, { setSubmitting }) => {
                this.props.handleSubmit(values);
                setSubmitting(false);  
            }}
        >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setValues
        }) => (
            
            <form onSubmit={handleSubmit} className="bootstrap gothic-font">
                <div className="form-group">
                    <label className="required">Account Number</label>
                    <p style={{color: "red"}}>{errors.domesticAccountNumber}</p>
                    <div className="input-group">
                        <input
                            type="text"
                            name="domesticAccountNumber"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.domesticAccountNumber}
                            disabled={this.state.accountNumberFieldDisabled}
                        />
                        <span className="input-group-btn">
                            <button className="btn black-button" name="accountNumber" type="button" onClick={() => this.handleAccountEditOrResetClick(values, setValues)}>
                                {this.state.accountNumberFieldDisabled ? "Edit" : "Reset" }
                            </button>
                        </span>
                    </div>
                </div>

                <div className="form-group">
                <label className="required">Routing Number</label>
                <p style={{color: "red"}}>{errors.domesticRoutingNumber}</p>
                <div className="input-group">
                    <input
                        type="text"
                        name="domesticRoutingNumber"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.domesticRoutingNumber}
                        disabled={this.state.routingNumberFieldDisabled}
                    />
                    <span className="input-group-btn">
                        <button className="btn black-button" name="accountNumber" type="button" onClick={() => this.handleRoutingEditOrResetClick(values, setValues)}>
                            {this.state.routingNumberFieldDisabled ? "Edit" : "Reset" }
                        </button>
                    </span>
                </div>
                </div>

                <div className="form-group">
                <label>Additional Information</label>
                <p style={{color: "red"}}>{errors.domesticNotes}</p>
                <input
                    type="text"
                    name="domesticNotes"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.domesticNotes}
                />
                </div>

                <button type="submit" disabled={isSubmitting || errors.domesticAccountNumber || errors.domesticRoutingNumber || errors.domesticNotes} className="btn black-button">
                    Submit
                </button>
            </form>
        )}
        </Formik>
        )
    }
}

const mapStateToProps = ({userInfo: { expertPayment } }) => ({
    expertPayment
})

export default connect(mapStateToProps)(DomesticPaymentEditForm); 