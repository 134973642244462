export const languages = [
  {
    languageName: 'English',
    formAbbreviation: 'en'
  },
  {
    languageName: 'Bahasa Indonesia',
    formAbbreviation: 'id'
  },
  {
    languageName: 'Français',
    formAbbreviation: 'fr'
  },
  {
    languageName: 'Deutsch',
    formAbbreviation: 'de'
  },
  {
    languageName: '中文',
    formAbbreviation: 'zh'
  },
  {
    languageName: 'Português',
    formAbbreviation: 'pt'
  },
  {
    languageName: '日本語',
    formAbbreviation: 'ja'
  },
  {
    languageName: 'Español',
    formAbbreviation: 'es'
  },
  {
    languageName: '한국어',
    formAbbreviation: 'ko'
  },
  {
    languageName: 'Türkçe',
    formAbbreviation: 'tr'
  },
];