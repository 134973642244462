import React, { Component } from 'react';
import { Formik } from 'formik';

class ExpertEmailUpdateForm extends Component {
  render() {
    return(
      <Formik
        initialValues={{email: ''}}
        validate={ values => {
          let errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.props.onUpdateClick(values.email);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form className="form bootstrap gothic-font" onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="required">User Email</label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className="form-control"
              />
            </div>
            <p style={{color: 'red'}}>{errors.email && touched.email && errors.email}</p>
            <button type="submit" disabled={isSubmitting || errors.email} className="btn black-button">
              Submit
            </button>
          </form>
        )}
      </Formik>
    )
  }
}

export default ExpertEmailUpdateForm;