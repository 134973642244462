import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExpertPaymentForm from '../../components/signup/ExpertPaymentForm';
import ExpertPaymentEditForm from '../../components/myProfile/ExpertPaymentEditForm';

/**
 * PaymentEditContainer renders the parent payment edit form,
 * which itself will render the forms corresponding to the
 * various payment options. 
 */
class PaymentEditContainer extends Component {
    render() {
        return(
            <ExpertPaymentEditForm/>
        )
    }
}

const mapStateToProps = ({ userInfo: { expertPayment } }) => ({
    expertPayment
});

export default connect(mapStateToProps)(PaymentEditContainer);