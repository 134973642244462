import React from 'react';
import InfoDropdown from '../common/InfoDropdown';

import ExpertHotline from './dropdowns/ExpertHotline';
import CEOCalls from './dropdowns/CEOCalls';
import KeySurvaysDropdown from './dropdowns/KeySurveysDropdown';

export default () => (
  <div style={{marginBottom: '100px'}}>
    <InfoDropdown 
      title="Expert Hotline - Same-day Consultations"
      shortText="A P.E. Fund had a three hour deadline for an acquisition decision, 
        and needed insight into the market, competition, 
        and company itself to submit a price by EOD."
    >
      <ExpertHotline />
    </InfoDropdown>
    <InfoDropdown 
      title="CEO Calls - Access to Hundreds of Current and Former CEOs"
      shortText="A V.C. Firm needed technical insights into the blockchain 
        infrastructure underlying a coin exchange — a technology that only 
        cryptocurrency creators and founding members are highly familiar with."
    >
      <CEOCalls />
    </InfoDropdown>
    <InfoDropdown 
      title="Turn-Key Surveys - Results in Under 3 Days for up to 100 Participants"
      shortText="A large Technology company wanted to gain a granular understanding 
        of the Internet of Things (IoT) market on another continent. They wanted to 
        understand the different use cases for IoT across several industries and the 
        decision making process for the purchase and implementation of IoT solutions."
    >
      <KeySurvaysDropdown />
    </InfoDropdown>
    
  </div>
);