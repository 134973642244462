import React from 'react';

import '../../styles/components/homepage/AboutUs.scss';

export default (props) => (
  <div className="homepage-about-us">
    <div className="home-about-us-box">
      <div className="about-us-header">
        <div className="mobile-subheader">
          <img src={require('../../images/homepage/dots_mobile_header.png')} alt=""/>
          <span>About us</span>
        </div>
        <p>
          We are a technology driven expert search {props.width > 1248 && <br />} engine, bringing speed, quality, 
          and precision to {props.width > 1438 && <br />} the knowledge sourcing process. -  
        </p>
        <span style={{ display: props.width < 500 ? 'none': 'flex'}}>About Us</span>
      </div>
      <div className="about-us-main-content">
        <div className="about-us-picture">
          <img src={require('../../images/homepage/about_us_picture.png')} alt=""/>
        </div>
        <div className="about-us-text">
          <div className="about-us-text-box">
            <div className="paragraph-header">Technology Driven </div>
            <div className="paragraph-body">
              NewtonX leverages the power of automation to rapidly 
              and precisely match clients with experts. Using our proprietary algorithm, we precisely identify the top experts 
              in the world for any client request, no matter how niche. From request, to matching, to scheduling, 
              using NewtonX is always seamless.
            </div>
            <div className="paragraph-header">Experience Backed </div>
            <div className="paragraph-body">
              The NewtonX leadership team have experienced the same pain points that our clients do. With backgrounds that include McKinsey, Google, and Boston Consulting Group, we know how to deliver the best experience possible to our clients, because we used to be in their positions.
            </div>
            <div className="mobile-dots-box"> 
              <img src={require('../../images/homepage/mobile_bottom_dots.png')} alt=""/>
            </div>
            <div className="mobile-picture">
              <img src={require('../../images/homepage/mobile_picture_wave.png')} alt=""/>
            </div>
            <div className="bottom-dots-box">
              <img src={require('../../images/homepage/dots-about-us.svg')} alt=""/>
            </div>
          </div>
 
        </div>
      </div>
    </div>
  </div>
);