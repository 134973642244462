// @flow  
import React, { Component } from 'react'
import '../../styles/containers/ExternalSignIn.scss';
import '../../styles/common/Button.scss';
import ExternalSignInForm from '../../components/login/ExternalSignInForm';
import { connect } from 'react-redux';
import { clearUserInformation, login, createUserLog } from '../../actions/signinActions';
import { openResendLinkModal, openForgotPasswordModal } from '../../actions/modalActions';
import { withToastManager } from 'react-toast-notifications';
import userService from '../../utils/userService';
import { setRedirectingFromEmailVerification } from '../../actions/signupActions';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE
} from '../../action-types/login';
import ForgotPassword from '../../components/forgotPassword/ForgotPassword';
import ForgotPasswordStatus from '../../components/forgotPassword/ForgotPasswordStatus';


class ExternalSignIn extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.userInfo.fetchProfileStatus !== this.props.userInfo.fetchProfileStatus &&
      (this.props.userInfo.loginStatus === LOGIN_SUCCESS ||
        this.props.userInfo.loginStatus === LOGIN_FAILURE) &&
      (this.props.userInfo.fetchProfileStatus === FETCH_PROFILE_SUCCESS ||
        this.props.userInfo.fetchProfileStatus === FETCH_PROFILE_FAILURE)) {

      if (this.props.userInfo.fetchProfileStatus === FETCH_PROFILE_SUCCESS) {
        const { user } = this.props.userInfo
        this.checkIfUserIsVerified(user.isVerified);
        let userLog = userService.getUserLogInfo();
        this.props.createUserLog(user.id, userLog);
      } else {
        this.props.toastManager.add(
          this.props.userInfo.error,
          {
            appearance: 'error'
          }
        );
        this.props.history.push('/');
      }

      if (this.props.userInfo.loginStatus === LOGIN_SUCCESS) {
        // if the user redirected from email verification and received the alert that they are now verified, we need to stop the alert from showing up if they reopen the login page
        if (this.props.redirectingFromEmailVerification) {
          this.props.setRedirectingFromEmailVerification(false)
        }

        // if on mobile, we close the mobile menu after successful login 
        if (this.props.onMobileClose) {
          this.props.onMobileClose();
        }
      }
    }
  }

  handleSignin = (username, password) => {
    this.props.login(username, password);
  };

  checkIfUserIsVerified = (isVerified: boolean) => {
    if (isVerified) {
      // redirect user based on how far they are in signup process
      userService.redirectAfterSignin(this.props.history, this.props.userInfo);
    } else {
      // if user is not verified, we need to abort login & prompt them to verify
      this.props.history.push('/');
      this.props.openResendLinkModal();
    }
  }

  handleForgotPassword = () => {
    this.props.openForgotPasswordModal();
  }

  renderEmailVerifiedAlert = () => {
    return (
      <div className="alert alert-success" role="alert">
        Email address was verified! Please log in to complete signup.
      </div>
    )
  }

  renderLoginFailedAlert = () => {
    return (
      <div className="alert alert-danger" role="alert">
        Username or password was invalid. Please try again.
      </div>
    )
  }

  render() {
    return (
      <div className="external-sign-in-container bootstrap">
        <div className="external-sign-in">
          <div className="card" style={{ width: '38rem' }}>
            <div className="card-header">
              <h5>Log in to NewtonX</h5>
            </div>
            <div className="card-body">
              <ExternalSignInForm
                renderLoginFailedAlert={this.renderLoginFailedAlert}
                renderEmailVerifiedAlert={this.renderEmailVerifiedAlert}
                openSignup={this.props.openSignup}
                handleSignin={this.handleSignin}
                loginFailed={this.props.userInfo.loginFailed}
                redirectingFromEmailVerification={this.props.redirectingFromEmailVerification}
                handleForgotPassword={this.handleForgotPassword}
              />
            </div>
          </div>
        </div>
        <ForgotPassword isOpen={this.props.forgotPasswordModalOpen} />
        <ForgotPasswordStatus isOpen={this.props.forgotPasswordStatusModalOpen} />
      </div>
    );
  }
}

const mapStateToProps = ({ userInfo, signup, modals }) => ({
  userInfo,
  redirectingFromEmailVerification: signup.redirectingFromEmailVerification,
  forgotPasswordModalOpen: modals.forgotPasswordModalOpen,
  forgotPasswordStatusModalOpen: modals.forgotPasswordStatusModalOpen
});

const mapDispatchToProps = (dispatch: Function) => ({
  openResendLinkModal: () => {
    dispatch(openResendLinkModal());
  },

  openForgotPasswordModal: () => {
    dispatch(openForgotPasswordModal())
  },

  clearUserInformation: () => {
    dispatch(clearUserInformation())
  },

  setRedirectingFromEmailVerification: (redirectedFromEmailVerification) => {
    dispatch(setRedirectingFromEmailVerification(redirectedFromEmailVerification))
  },

  login: (username, password) => {
    dispatch(login(username, password));
  },

  createUserLog: (userId, userLogInfo) => {
    dispatch(createUserLog(userId, userLogInfo));
  }
});


export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ExternalSignIn)); 