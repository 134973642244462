import React, { PureComponent } from 'react';

import {
  privacyData,
  securityData,
} from '../../data/policies';

import PolicyHeader from '../../components/policies/PolicyHeader';
import PolicySection from '../../components/policies/PolicySection';

import '../../styles/components/policies/policies.scss';

export default class Policies extends PureComponent {
  state = {
    data: [],
    sectionsOpen: [],
  }

  componentDidMount() {
    const { isPrivacy } = this.props;
    const data = isPrivacy ? privacyData : securityData;
    const sectionsOpen = this.getAllSectionOpenArray(data);
    this.setState({ data, sectionsOpen });
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.isPrivacy !== this.props.isPrivacy) {
      const data = nextProps.isPrivacy ? privacyData : securityData;
      const sectionsOpen = this.getAllSectionOpenArray(data);
      this.setState({ data, sectionsOpen });
    }
  }

  getAllSectionOpenArray = arr => arr.reduce((accu, item, i) => {
    accu.push(i);
    return accu;
  }, []);

  isSectionOpen = (index) => {
    const { sectionsOpen } = this.state;
    return sectionsOpen.indexOf(index) > -1;
  }

  handleShowClick = (index) => {
    const { sectionsOpen } = this.state;
    const newSectionsOpen = [...sectionsOpen];
    if (this.isSectionOpen(index)) {
      const itemIndex = newSectionsOpen.indexOf(index);
      newSectionsOpen.splice(itemIndex, 1);
    } else {
      newSectionsOpen.push(index);
    }

    this.setState({
      sectionsOpen: newSectionsOpen,
    });
  }

  render() {
    const { isPrivacy } = this.props;
    const { data } = this.state;
    return (
      <div className='policies__container'>
        <PolicyHeader isPrivacy={isPrivacy} />
        <div className='policies-section__container'>
          {
            data.map((item, index) => (
              <PolicySection
                key={`item.title-${index}`}
                sectionInfo={item}
                sectionIndex={index}
                onClick={() => this.handleShowClick(index)}
                isSectionOpen={this.isSectionOpen(index)}
              />
            ))
          }
        </div>
      </div>
    );
  }
}
