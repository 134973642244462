import React, { Component, Fragment } from 'react';
import ExpertEmailUpdateContainer from '../../containers/admin/ExpertEmailUpdateContainer';


class ExpertCard extends Component {

  constructor(props) {
    super(props);
     this.state = {
       isPaymentExpanded: false,
       isEmailExpanded: false
     }
  }

  handlePaymentDetailsClick = () => {
    this.setState({
      isPaymentExpanded: !this.state.isPaymentExpanded
    });
  };

  handleUpdateEmailClick = () => {
    this.setState({
      isEmailExpanded: !this.state.isEmailExpanded
    });
  };

  handleResetPasswordClick = () => {
    this.props.setResetPasswordUserEmail(this.props.userInfo.user.email);
    this.props.openResetPasswordConfirmationModal();
  };

  handleResendVerificationEmailClick = () => {
    this.props.setResendVerificationEmailUserId(this.props.userInfo.user.id);
    this.props.openResendVerificationEmailConfirmationModal();
  };

  isUserAnAdmin = () => {
    if (this.props.userInfo.user.isAdmin) {
      return(
        <div className="my-2">
          <i className="fa fa-user-secret"></i>
          <strong><span className="ml-1">Admin Account</span></strong>
        </div>
      )
    }
    return null;
  };

  renderPaymentInformation = () => {
    return (
      <div
        className="py-3 container"
      >
        { this.props.userInfo.expertPayment.selectedType === 'PAYPAL' ? this.renderPaypalInfo() : null}
        { this.props.userInfo.expertPayment.selectedType === 'DOMESTIC' ? this.renderDomesticInfo() : null}
        { this.props.userInfo.expertPayment.selectedType === 'INTERNATIONAL' ? this.renderInternationalInfo() : null}
        { this.props.userInfo.expertPayment.selectedType ? this.renderPaymentDate() : "Payment information not provided!" }
    </div>
    );
  }

  renderPaymentDate = () => {
    return(
      <dl className="row">
          <dt className="col-12 col-sm-4">Payment information provided on:</dt>
          <dd className="col-12 col-sm-8">{new Date(this.props.userInfo.expertPayment.createdDt).toLocaleDateString()}</dd>
      </dl>
    );
  }

  renderPaypalInfo = () => {
    return(
      <Fragment>
        <h5 className="py-1">Paypal</h5>
        <dl className="row" style={{marginBottom: "0px"}}>
          <dt className="col-12 col-sm-4">Email:</dt>
          <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.paypalEmail}</dd>
          <dt className="col-12 col-sm-4">Notes:</dt>
          <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.paypalNotes}</dd>
        </dl>
      </Fragment>
    )
  }

  renderDomesticInfo = () => {
    return(
      <Fragment>
        <h5 className="py-1">Domestic Bank Account</h5>
        <dl className="row" style={{marginBottom: "0px"}}>
          <dt className="col-12 col-sm-4">Account Number:</dt>
          <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.domesticAccountNumber}</dd>
          <dt className="col-12 col-sm-4">Routing Number:</dt>
          <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.domesticRoutingNumber}</dd>
          <dt className="col-12 col-sm-4">Notes:</dt>
          <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.domesticNotes}</dd>
        </dl>
      </Fragment>
    )
  }

  renderInternationalInfo = () => {
    return(
      <Fragment>
        <h5 className="py-1">International Bank Account</h5>
        <dl className="row" style={{marginBottom: "0px"}}>
            <dt className="col-12 col-sm-4">IBAN:</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlIban}</dd>
            <dt className="col-12 col-sm-4">Swift/BIC:</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlSwiftBic}</dd>
            <dt className="col-12 col-sm-4">Bank Address:</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlBankAddress}</dd>
            <dt className="col-12 col-sm-4">Bank City</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlBankCity}</dd>
            <dt className="col-12 col-sm-4">Bank State</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlBankState}</dd>
            <dt className="col-12 col-sm-4">Bank Postal Code</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlBankPostalCode}</dd>
            <dt className="col-12 col-sm-4">Expert Address</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlUserAddress}</dd>
            <dt className="col-12 col-sm-4">Expert City</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlUserCity}</dd>
            <dt className="col-12 col-sm-4">Expert State</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlUserState}</dd>
            <dt className="col-12 col-sm-4">Expert Postal Code</dt>
            <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlUserPostalCode}</dd>
          <dt className="col-12 col-sm-4">Notes</dt>
          <dd className="col-12 col-sm-8">{this.props.userInfo.expertPayment.intlNotes}</dd>
        </dl>
      </Fragment>
    )
  }

  renderVerifiedStatus = () => {
    if (this.props.userInfo.user.isVerified) {
      return(
        <li
         className={'list-group-item py-1 text-success'}
        >
          <i className="fa fa-check"></i>
          <small className="ml-1">Verified Email</small>
      </li>
      )
    } else {
      return(
        <li className={'list-group-item py-1 text-danger'}>
          <i className="fa fa-check"></i>
          <small className="ml-1">Unverified Email</small>
        </li>
      )
    }
  }

  renderTermsAcceptedStatus = () => {
    if (this.props.userInfo.expert.termsAccepted) {
      return(
        <li
         className={'list-group-item py-1 text-success'}
        >
          <i className="fa fa-check"></i>
          <small className="ml-1">T&amp;C Accepted</small>
      </li>
      )
    } else {
      return(
        <li className={'list-group-item py-1 text-danger'}>
          <i className="fa fa-check"></i>
          <small className="ml-1">Missing T&amp;C</small>
        </li>
      )
    }
  }

  renderPaymentTypeStatus = () => {
    if (this.props.userInfo.expertPayment.selectedType) {
      return(
        <li
         className={'list-group-item py-1 text-success'}
        >
          <i className="fa fa-check"></i>
          <small className="ml-1">Payment Selected</small>
      </li>
      )
    } else {
      return(
        <li className={'list-group-item py-1 text-danger'}>
          <i className="fa fa-check"></i>
          <small className="ml-1">Missing Payment</small>
        </li>
      )
    }
  }
  render() {
    return(
      <div className="bootstrap">
        <div className="card my-4 shadow-lg">
          <div className="card-body p-3">
            <div      className="row"
            >
              <div className="col-12 col-sm-8">
                <div><strong>{this.props.userInfo.expert.firstName} {this.props.userInfo.expert.lastName}</strong></div>
                <div>{this.props.userInfo.expert.phone}</div>
                <div>{this.props.userInfo.user.email}</div>
                <div className="row">
                    <button type="button" className="btn btn-secondary" onClick={this.handleUpdateEmailClick}>Update Email</button> &nbsp;
                    <button type="button" className="btn btn-secondary" onClick={this.handleResetPasswordClick}>Reset Password</button> &nbsp;
                    <button disabled={this.props.userInfo.user.isVerified} type="button" className="btn btn-secondary" onClick={this.handleResendVerificationEmailClick}>Resend Verification Email</button>
                </div>
                { this.state.isEmailExpanded ? <ExpertEmailUpdateContainer userInfo={this.props.userInfo} email={this.props.userInfo.email} expertId={this.props.userInfo.user.id} handleUpdateEmailClick={this.handleUpdateEmailClick}/> : null}
                { this.isUserAnAdmin() }
              </div>
              <ul className="col-12 col-sm-4 list-group mb-3">
                { this.renderVerifiedStatus() }
                { this.renderTermsAcceptedStatus() }
                { this.renderPaymentTypeStatus() }
              </ul>
            </div>
            <div
              className="card-footer"
            >
              <div>
                <button
                  type="button"
                  className="text-secondary cursor"
                  onClick={this.handlePaymentDetailsClick}
                >
                  {this.state.isPaymentExpanded ? 'Hide ' : 'Expand '} Payment Details
                </button>
              </div>
              { this.state.isPaymentExpanded ? this.renderPaymentInformation() : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpertCard;
