import React, { Component } from 'react';
import ExpertPaymentForm from '../../components/signup/ExpertPaymentForm';
import { connect } from 'react-redux'; 

 export class ExpertPaymentContainer extends Component {

    // if user refreshes on this page we need to push them to homepage to retrieve info from backend
    componentDidMount() {
        if (!this.props.expertPayment.id) {
            this.props.history.push('/')
        }
    }

    handleBackClick = () => {
        this.props.history.push('/expert/terms'); 
    }
    render() {
        return(
            <ExpertPaymentForm
                onBackClick={this.handleBackClick}
                history={this.props.history}
             />
        )
    }
}

let mapStateToProps = (state) => ({
    expertPayment: state.userInfo.expertPayment
})

export default connect(mapStateToProps)(ExpertPaymentContainer); 