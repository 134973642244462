import React from 'react';


const ConsultationGuide = (props) => {
  return (
    <div>
      <h4 className="py-3" style={{ textAlign: 'center' }}>The NewtonX Guide to a Successful Consultation</h4>
      <div
        className="wistia_responsive_padding"
        style={{ padding: '56.25% 0 0 0', position: 'relative', marginBottom: '1em' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%', }}
        >
          <iframe
            src="https://fast.wistia.net/embed/iframe/tsc3winpbf?videoFoam=true"
            title="NewtonX Expert Consultation Tutorial Video"
            allowtransparency="true"
            frameborder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            allow="fullscreen"
            width="100%"
            height="100%"
          >
          </iframe>
        </div>
      </div>
    </div>
  )
}

export default ConsultationGuide;