import { environment } from '../environments/env'

const authService = {
    loginWithLinkedIn: () => {
        window.open(
            `${environment.CAS_BASE_PATH}/clientredirect?client_name=${encodeURIComponent(environment.CAS_LINKEDIN_CLIENT_NAME)}&service=${encodeURIComponent(environment.CAS_CLIENT_ID)}`, '_self'
        );
    },

    completeOAuthForLinkedInLogin: () => {
        window.open(
            `${environment.CAS_BASE_PATH}/oauth2.0/authorize?client_id=${encodeURIComponent(environment.CAS_CLIENT_ID)}&redirect_uri=${encodeURIComponent(environment.CAS_CLIENT_ID)}&response_type=${encodeURIComponent('token')}`, '_self'
        );
    },

    logoutFromCAS: () => {
        window.open(`${environment.CAS_BASE_PATH}/logout?service=${environment.CAS_CLIENT_ID}`, '_self');
    }
};

export default authService;
