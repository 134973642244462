import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExpertEmailUpdateForm from '../../components/admin/ExpertEmailUpdateForm';
import adminService from '../../utils/adminService';
import { withToastManager } from 'react-toast-notifications';
import { updateUserEmail } from '../../actions/adminActions'
import {  
  UPDATE_EXPERT_EMAIL_SUCCESS,
  UPDATE_EXPERT_EMAIL_FAILURE
} from '../../action-types/admin'

class ExpertEmailUpdateContainer extends Component {

  constructor(props) {
    super(props);
    this.adminService = adminService;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.admin.updateExpertEmailStatus !== this.props.admin.updateExpertEmailStatus) {      
      if (this.props.admin.updateExpertEmailStatus === UPDATE_EXPERT_EMAIL_SUCCESS) {
        this.props.toastManager.add('Email updated successfully!', {
          appearance: 'success',
        });
        this.props.handleUpdateEmailClick();
      }

      if (this.props.admin.updateExpertEmailStatus === UPDATE_EXPERT_EMAIL_FAILURE) {
        this.props.toastManager.add('Email update failed!', {
          appearance: 'error',
        });
      }

    }
  }

  handleUpdateClick = (updatedEmail) => {
    this.props.userInfo.user.email = updatedEmail.toLowerCase();
    this.props.updateUserEmail(this.props.userInfo, this.props.expertId);
  }

  render() {
    return (
      <ExpertEmailUpdateForm onUpdateClick={this.handleUpdateClick} />
    )
  }
}

const mapStateToProps = ({ userInfo, admin }) => ({
  user: userInfo.user,
  admin
});

const mapDispatchToProps = (dispatch: Function) => ({
  updateUserEmail: (userInfo, expertId) => {
    dispatch(updateUserEmail(userInfo, expertId))
  }
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ExpertEmailUpdateContainer));
