export const SET_SIGNUP_ACCESS_TOKEN = 'SET_SIGNUP_ACCESS_TOKEN'; 
export const CLEAR_SIGNUP_ACCESS_TOKEN = 'CLEAR_SIGNUP_ACCESS_TOKEN'; 

export const CLEAR_SIGNUP_USER = 'CLEAR_SIGNUP_USER';
export const SET_SIGNUP_USER = 'SET_SIGNUP_USER';

export const SET_REDIRECTING_FROM_EMAIL_VERIFICATION = 'SET_REDIRECTING_FROM_EMAIL_VERIFICATION';

export const SEND_CLIENT_NOTIFICATION_EMAIL_START = 'SEND_CLIENT_NOTIFICATION_EMAIL_START'
export const SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS = 'SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS'
export const SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE = 'SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE'

export const FETCH_PLATFORM_EXPERT_BY_GUID_START = 'FETCH_PLATFORM_EXPERT_BY_GUID_START';
export const FETCH_PLATFORM_EXPERT_BY_GUID_SUCCESS = 'FETCH_PLATFORM_EXPERT_BY_GUID_SUCCESS';
export const FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE = 'FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE';

export const POST_USER_START = 'POST_USER_START';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';

export const VERIFY_USER_START = 'VERIFY_USER_START';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';
