import React, { Component } from 'react';
import { Formik } from 'formik';
import '../../styles/components/signup/SignupModalForm.scss';
export default class SignupAfterRedirectModalForm extends Component {
    renderAlerts = () => {
            return(
              <div className="alert alert-danger" role="alert">
                 {this.props.alerts}
              </div>
            )
            
    }
    render() {
        return(
            <Formik
              initialValues={{ email: '', password: '', confirmPassword: '' }}
              validate={values => {
                let errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }

                if (!values.password) {
                    errors.password = 'Required';
                } else if (values.password !== values.confirmPassword) {
                    errors.password = 'Password and confirm password must match'
                }

                if (!values.confirmPassword) {
                    errors.confirmPassword = 'Required'; 
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.props.onFormSubmit(values.email, values.password);
                setSubmitting(false); 
              }}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="form bootstrap gothic-font" onSubmit={handleSubmit}>
              <h1 className="text-center primary-color">
                Welcome to NewtonX
              </h1>
                <h5>Please confirm that you want to sign-up to NewtonX and receive paid consulting and online survey
                    opportunities by entering your email below:</h5>
              { this.props.alerts ? this.renderAlerts() : null }
              <div className="form-group">
                <label className="required">Email</label>
                <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={ this.props.submitDisabled }
                    className="form-control"
                />
              </div>
              <p style={{color: 'red'}}>{errors.email && touched.email && errors.email}</p>

              <div className="form-group">
                <label className="required">Password</label>
                <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    disabled={ this.props.submitDisabled }
                    className="form-control"
                />
              </div>
              
              <p style={{color: 'red'}}>{errors.password && touched.password && errors.password}</p>
              <div className="form-group">
                <label className="required">Confirm Password</label>
                <input
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    disabled={ this.props.submitDisabled }
                    className="form-control"
                />
              </div>
              <p style={{color: 'red'}}>{errors.confirmPassword && touched.password && errors.confirmPassword}</p>

              <button type="submit" disabled={isSubmitting || this.props.submitDisabled} className="btn black-button">
                  { this.props.submitDisabled ? <i className="fa fa-refresh fa-spin"></i> : null }
                  Submit
              </button>
            </form>
          )}
        </Formik>

            )
        }
}

