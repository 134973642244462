import React, { Component } from 'react';
import CaseStudies from '../../components/newsAndResources/CaseStudies';
import PressInfo from '../../components/newsAndResources/PressInfo';
import SampleCalls from '../../components/newsAndResources/SampleCalls';
import Blogs from '../../components/newsAndResources/Blogs';

export default class NewsAndResourcesMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }
  
  render() {
    const containerStyle = {
      background: '#F8F8F8', 
      width: '100%',
    }
    return (
      <div className='news-resource__container' style={containerStyle}>
        <PressInfo />
      </div>
    );
  }
}