import React, { Component } from 'react';
import FaqForm from '../../components/myProfile/FaqForm';
import userService from '../../utils/userService';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { downloadUserInformation, deleteUserInformation } from '../../actions/signinActions';
import { DOWNLOAD_USER_INFORMATION_START, DOWNLOAD_USER_INFORMATION_SUCCESS, DOWNLOAD_USER_INFORMATION_FAILURE, DELETE_USER_INFORMATION_START, DELETE_USER_INFORMATION_SUCCESS, DELETE_USER_INFORMATION_FAILURE } from '../../action-types/login';

/**
 * The primary purpose of the FaqContainer is to
 * provide experts a place to request the download or deletion
 * of their information. 
 */
export class FaqContainer extends Component {

    constructor() {
        super();
        this.state = {
            answerOneHidden: true,
            answerTwoHidden: true,
            answerThreeHidden: true,
            answerFourHidden: true,
            answerFiveHidden: true,
            answerSixHidden: true,
            answerSevenHidden: true,
            answerEightHidden: true,
            downloadUserInformationStatus: '',
            deleteUserInformationStatus: ''
        }
    }

    componentDidUpdate(prevProps) {
        this.handleComponentUpdateForDownloadUserInformation(prevProps);
        this.handleComponentUpdateForDeleteUserInformation(prevProps);
    }

    handleComponentUpdateForDownloadUserInformation = (prevProps) => {
        if (prevProps.downloadUserInformationStatus !== this.props.downloadUserInformationStatus &&
            (
                this.state.downloadUserInformationStatus === DOWNLOAD_USER_INFORMATION_START &&
                (
                    this.props.downloadUserInformationStatus === DOWNLOAD_USER_INFORMATION_SUCCESS ||
                    this.props.downloadUserInformationStatus === DOWNLOAD_USER_INFORMATION_FAILURE
                )
            )) {
            if (this.props.downloadUserInformationStatus === DOWNLOAD_USER_INFORMATION_SUCCESS) {
                this.props.toastManager.add('Your request for the download of your information has been sent, and will be processed by our team.', {
                    appearance: 'success'
                });
            } else {
                this.props.toastManager.add('Something went wrong. The request could not be processed.', {
                    appearance: 'error'
                });
            }
        }
    }

    handleComponentUpdateForDeleteUserInformation = (prevProps) => {
        if (prevProps.deleteUserInformationStatus !== this.props.deleteUserInformationStatus &&
            (
                this.state.deleteUserInformationStatus === DELETE_USER_INFORMATION_START &&
                (
                    this.props.deleteUserInformationStatus === DELETE_USER_INFORMATION_SUCCESS ||
                    this.props.deleteUserInformationStatus === DELETE_USER_INFORMATION_FAILURE
                )
            )) {
            if (this.props.deleteUserInformationStatus === DELETE_USER_INFORMATION_SUCCESS) {
                this.props.toastManager.add('Your request for user deletion has been sent, and will be processed by our team.', {
                    appearance: 'success'
                });
            } else {
                this.props.toastManager.add('Something went wrong. The request could not be processed.', {
                    appearance: 'error'
                });
            }
        }
    }

    handleDownloadFormSubmit = () => {
        this.setState({
            downloadUserInformationStatus: DOWNLOAD_USER_INFORMATION_START
        });
        this.props.downloadUserInformation(this.props.userId);
    }

    handleDeleteClick = () => {
        this.setState({
            deleteUserInformationStatus: DELETE_USER_INFORMATION_START
        });
        this.props.deleteUserInformation(this.props.userId);
    }

    // hide/unhide each FAQ answer when clicked based on current state
    handleFaqClick = (event) => {
        if (event.target.id === 'questionOne') {
            this.setState({
                answerOneHidden: !this.state.answerOneHidden
            })
        } else if (event.target.id === 'questionTwo') {
            this.setState({
                answerTwoHidden: !this.state.answerTwoHidden
            })
        } else if (event.target.id === 'questionThree') {
            this.setState({
                answerThreeHidden: !this.state.answerThreeHidden
            })
        } else if (event.target.id === 'questionFour') {
            this.setState({
                answerFourHidden: !this.state.answerFourHidden
            })
        } else if (event.target.id === 'questionFive') {
            this.setState({
                answerFiveHidden: !this.state.answerFiveHidden
            })
        } else if (event.target.id === 'questionSix') {
            this.setState({
                answerSixHidden: !this.state.answerSixHidden
            })
        } else if (event.target.id === 'questionSeven') {
            this.setState({
                answerSevenHidden: !this.state.answerSevenHidden
            })
        } else if (event.target.id === 'questionEight') {
            this.setState({
                answerEightHidden: !this.state.answerEightHidden
            })
        }
    }

    render() {
        return (
            <FaqForm onClick={this.handleFaqClick} {...this.state} onSubmit={this.handleDownloadFormSubmit} onDeleteClick={this.handleDeleteClick} />
        )
    }
}

const mapStateToProps = ({ userInfo }) => ({
    userId: userInfo.user.id,
    downloadUserInformationStatus: userInfo.downloadUserInformationStatus,
    deleteUserInformationStatus: userInfo.deleteUserInformationStatus
});

const mapDispatchToProps = (dispatch) => ({
    downloadUserInformation: (userId) => {
        dispatch(downloadUserInformation(userId));
    },

    deleteUserInformation: (userId) => {
        dispatch(deleteUserInformation(userId));
    }
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(FaqContainer)); 