import React, { Component } from 'react';
import MainBlackBox from '../../components/common/MainBlackBox';
import NewsAndResourcesMainContent from './NewsAndResourcesMainContent';

export default class NewsAndResources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    return (
      <div>
        <NewsAndResourcesMainContent />
      </div>
    );
  }
}