import axios from 'axios';
import { environment } from '../environments/env';

const defaultOptions = {
    baseURL: environment.API_BASE_PATH,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};

const api = axios.create(defaultOptions);

api.interceptors.request.use(
    (config) => {
        // if token found set the authorization header
        const token = localStorage.getItem(environment.LOGIN_TOKEN_NAME);
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default api;