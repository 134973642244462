import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserAccountForm from '../../components/myProfile/UserAccountForm';
import { withToastManager } from 'react-toast-notifications';
import { UPDATE_USER_PASSWORD_START, UPDATE_USER_PASSWORD_SUCCESS, UPDATE_USER_PASSWORD_FAILURE } from '../../action-types/login';
import { updateUserPassword } from '../../actions/signinActions';
import ConsultationGuide from '../../components/myProfile/ConsultationGuide';

/**
 * UserAccountContainer handles the rendering and updating
 * of the expert's first name, last name, and phone number. 
 */
class UserAccountContainer extends Component {

    constructor() {
        super();
        this.state = {
            passwordFormOpen: false,
            updateUserPasswordStatus: '',
            consultationGuideOpen: true,
        }
    }

    componentDidMount() {
        const wistiaAssetsScript = document.createElement('script');
        wistiaAssetsScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        wistiaAssetsScript.async = true;
        document.body.appendChild(wistiaAssetsScript);
    }

    closePasswordForm = () => {
        this.setState({
            passwordFormOpen: false
        })
    }

    handleChangePasswordClick = () => {
        this.setState({
            passwordFormOpen: !this.state.passwordFormOpen
        })
    }

    handleViewGuideClick = () => {
        this.setState({
            consultationGuideOpen: !this.state.consultationGuideOpen
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateUserPasswordStatus !== this.props.updateUserPasswordStatus &&
            (
                this.state.updateUserPasswordStatus === UPDATE_USER_PASSWORD_START &&
                (
                    this.props.updateUserPasswordStatus === UPDATE_USER_PASSWORD_SUCCESS ||
                    this.props.updateUserPasswordStatus === UPDATE_USER_PASSWORD_FAILURE
                )
            )) {
            if (this.props.updateUserPasswordStatus === UPDATE_USER_PASSWORD_SUCCESS) {
                this.props.toastManager.add('Information updated successfully!', {
                    appearance: 'success'
                });
            } else {
                this.props.toastManager.add('Password could not be updated. Please confirm that you have the correct current password and try again.', {
                    appearance: 'error'
                });
            }
        }
    }

    handleSubmit = (values) => {
        let userInfoToSend = {
            password: values.currentPassword,
            newPassword: values.newPassword
        };

        this.setState({
            updateUserPasswordStatus: UPDATE_USER_PASSWORD_START
        });

        this.props.updateUserPassword(this.props.userId, userInfoToSend);
    }

    render() {
        return (
            <div style={{ paddingBottom: ".5rem" }}>
                <p>EMAIL: {this.props.email}</p>
                <button 
                    className="btn black-button" 
                    onClick={this.handleChangePasswordClick}
                    style={{ marginBottom: '.3rem', minWidth: '14.6rem' }}
                >
                    CHANGE PASSWORD
                </button>
                {this.state.passwordFormOpen ? <UserAccountForm closePasswordForm={this.closePasswordForm} onSubmit={this.handleSubmit}  /> : null}


                <button 
                    className="btn black-button" 
                    onClick={this.handleViewGuideClick}
                    style={{ marginTop: '.3rem', display: 'block'}}
                    id="guideButton"
                >
                    { this.state.consultationGuideOpen ? 'HIDE' : 'VIEW'} CONSULTATION GUIDE
                </button>
                {this.state.consultationGuideOpen ? <ConsultationGuide /> : null}
            </div>
        )
    }
}

const mapStateToProps = ({ userInfo }) => ({
    email: userInfo.user.email,
    userId: userInfo.user.id,
    updateUserPasswordStatus: userInfo.updateUserPasswordStatus
});

const mapDispatchToProps = (dispatch) => ({
    updateUserPassword: (userId, userInfo) => {
        dispatch(updateUserPassword(userId, userInfo));
    }
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(UserAccountContainer)); 