import React, { Component } from 'react';
import Modal from 'react-modal'; 
import { connect } from 'react-redux'; 
import { closeResendLinkStatusModal} from '../../actions/modalActions';

class ResendLinkStatus extends Component {

    handleClose = () => {
        this.props.closeResendLinkStatusModal(); 
    }

    render() {
        return(
            <Modal isOpen={this.props.isOpen} className="modal-content thankYou-modal bootstrap gothic-font" >
                <div className="modal-header">
                    <h5 className="modal-title">Resend Link</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{textAlign: "center"}}>
                    <div>
                        <i className="check-circle fa fa-check-circle fa-5x" style={{paddingBottom: "1.25rem", color: "#7ED321"}}></i>
                    </div>
                    <h4>Verification Email Sent!</h4>
                    <p>Please check your email to confirm your account</p>
                    <div className="form-group mb-2">
                        <button className="btn black-button btn-block" onClick={this.handleClose}>Close</button>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeResendLinkStatusModal: () => {
        dispatch(closeResendLinkStatusModal())
    }
});

export default connect (null, mapDispatchToProps)(ResendLinkStatus); 