import React, { PureComponent } from 'react';

import Signup from '../../containers/login/Signup';

import '../../styles/components/homepage/ExpertScreen.scss';

export default class ExpertScreen extends PureComponent {
  state = {
    signUpModalOpen: false,
    signInModalOpen: false,
    showClientSignup: false,
  }

  handleSignupClick = (showClientSignup) => {
    this.setState({
      showClientSignup,
      signUpModalOpen: true,
    });
  }

  handlClose = () => {
    this.setState({ signUpModalOpen: false });
  }

  render() {
    const { showClientSignup, signUpModalOpen } = this.state;
    return (
      <div className="expert-screen-item">
        <div className="expert-screen">
          <div className="expert-text-box">
            <p className="expert-text">
              The World’s <br/> First AI-Powered<br/> Knowledge <br/>Access Platform - 
            </p>
            <span className="expert-text-bottom">The Power of Expertise, Now at Your Fingertips.</span>
          </div>
          <div className="bottom-decoration-box">
            <img className="bottom-decoration" src={require('../../images/homepage/illu_1@3x.png')} alt=""/>
          </div>
        </div>
        <div className="expert-options">
          <div className="expert-options-box">
            <div className="expert-option" onClick={() => this.handleSignupClick(false)}>
              <span className="expert-option-text">I am an Expert</span>
              <img className="expert-arrow" src={require('../../images/homepage/icon_arrow@3x.png')} alt=""/>
            </div>
            <div className="expert-option" onClick={() => this.handleSignupClick(true)}>
              <span className="expert-option-text">I need an Expert</span>
              <img className="expert-arrow" src={require('../../images/homepage/icon_arrow@3x.png')} alt=""/>
            </div>
          </div>
          <div className="certified-by-box">
            <p className="certified-by-text">Certified By -</p>
            <div className="break"/>
            <div className="certified-by-images">
                <a href="https://www.privacyshield.gov/" target="_blank" rel="noopener noreferrer"><img className="privacy-shield-image" src={require("../../images/homepage/privacyshield.png")} alt="Privacy Shield logo"/></a>
                <a href="https://www.esomar.org/what-we-do/standards" target="_blank" rel="noopener noreferrer"><img src={require("../../images/homepage/esomar.png")} alt="Esomar Logo"/></a>
            </div>
          </div>
        </div>
        <Signup
          showClientSignup={showClientSignup}
          isOpen={signUpModalOpen}
          onClose={this.handlClose}
        />
      </div>
    );
  }
}
