import React from 'react';
import WhiteFeatureBox from '../../components/common/WhiteFeatureBox';

import '../../styles/components/newsAndResources/generalStyles.scss';

export default () => (
  <div className="resources-container" style={{"padding": "3% 10px" }}>
    <div className="resources-content">
      <div className="main-content">
        <WhiteFeatureBox 
          text="NewtonX raises $12M from Two Sigma Ventures"
          buttonText="Read More"
          buttonLink="https://techcrunch.com/2019/06/18/two-sigma-leads-12m-series-a-for-expert-knowledge-network-newtonx/"
        >
          <img src={require('../../images/newAndResources/techCrunchHorizontal.png')} alt=""/>
        </WhiteFeatureBox>
        <WhiteFeatureBox 
          text="NewtonX leverages AI to locate Experts in real-time"
          buttonText="Read More"
          buttonLink="https://www.builtinnyc.com/2019/06/18/newtonx-series-funding"
        >
          <img src={require('../../images/newAndResources/builtInNyc.svg')} alt=""/>
        </WhiteFeatureBox>
        <WhiteFeatureBox 
          text="NewtonX COO on how to make AI and automation part of the company’s DNA"
          buttonText="Read More"
          buttonLink="https://ideamensch.com/sascha-eder"
        >
          <img src={require('../../images/newAndResources/idea_mensch@2x.png')} alt=""/>
        </WhiteFeatureBox>
        <WhiteFeatureBox 
          text="Why Your Startup's First Hires Need to Be Women"
          buttonText="Read More"
          buttonLink="https://www.entrepreneur.com/article/313397"
        >
          <img src={require('../../images/newAndResources/entrepreneur@2x.png')} alt=""/>
        </WhiteFeatureBox>
        <WhiteFeatureBox 
          text="This Startup’s AI-Powered Search Engine Connects You to Domain Experts"
          buttonText="Read More"
          buttonLink="https://www.alleywatch.com/2018/12/this-nyc-startups-ai-powered-search-engine-connects-you-to-domain-experts/"
        >
          <img src={require('../../images/newAndResources/alleywatch@2x.png')} alt=""/>
        </WhiteFeatureBox>
        <WhiteFeatureBox 
          text="Meet NewtonX B2B Expert and Influencer Surveys"
          buttonText="Read More"
          buttonLink="https://www.b2bnn.com/2018/12/newtonx-b2b-influencers-expert-networks/"
        >
          <img src={require('../../images/newAndResources/B2B-news-network-logo-1@2x.png')} alt=""/>
        </WhiteFeatureBox>

      </div>
    </div>
  </div>
);