import axios from 'axios';
import { environment } from '../environments/env'

const linkedInSignupOAuthBaseUrl = 'https://www.linkedin.com/oauth/v2/authorization';

const scopes = [
  'r_basicprofile',
  'r_emailaddress',
];

const nonceName = 'linkedin-oauth-nonce';

export class SignupService {

  signupWithLinkedIn = () => {
    window.open(this.generateUrl('signup'), '_self');
  };

  generateUrl = (path) => {
    const nonce = nonceUtil.generateNonce();
    nonceUtil.setNonceInLocalStorage(nonce);

    const queryParams = new URLSearchParams();
    queryParams.append('response_type', 'code');
    queryParams.append('client_id', environment.LINKEDIN_CLIENT_ID);
    queryParams.append('redirect_uri', `${environment.CLIENT_BASE_PATH}/oauth/linkedin/${path}`);
    queryParams.append('state', nonce);
    queryParams.append('scope', scopes.join(' '));

    return `${linkedInSignupOAuthBaseUrl}?${queryParams.toString()}`;
  }

  setSignupAccessToken = (signupToken) => {
    sessionStorage.setItem('nxSignupToken', signupToken);
  }

  // take OAuth code returned by LinkedIn & go to our backend with it to get profile information from LI
  fetchLinkedInProfile = (accessCodeFromLinkedIn: string, nonce: string) => {

    if (accessCodeFromLinkedIn != null && nonceUtil.nonceIsValid(nonce)) {

      // TODO: figure out why axios params obj was not working here
      return axios.post(`${environment.API_BASE_PATH}/oauth/linkedin/signup?authorizationCode=${accessCodeFromLinkedIn}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
    } else {
      return Promise.reject(new Error('nonce or code was not valid!'));
    }
  } 
}


const nonceUtil = {
  generateNonce: () => {
    return Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  },

  nonceIsValid: (nonce) => {
    if (nonce === localStorage.getItem(nonceName)) {
      return true;

    }
    return false;

  },

  setNonceInLocalStorage: (nonce) => {
    localStorage.setItem(nonceName, nonce);
  },
}

export { nonceUtil };

export default SignupService; 
