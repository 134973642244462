import React from 'react';
import Slider from '../common/Slider';

import '../../styles/components/homepage/sliderContentStyles.scss';

const SLIDES = [
  {
    quote: '“ I was amazed at the volume and quality of respondents that NewtonX was able to access for a survey under a tight deadline. They gave us 200 respondents in five days. ”',
    title: 'Project Manager',
    firm: 'Market Research Firm',
  },
  {
    quote: '“ We turned to NewtonX after no other service could meet our deadline. They gave us three expert profiles in under two hours, all three of which were a perfect match for our needs. ”',
    title: 'Engagement Manager',
    firm: 'Management Consulting Firm',
  },
  {
    quote: '“ The best part about using NewtonX is simply having the confidence that you will get the best expert profiles out there. ”',
    title: 'Vice President',
    firm: 'Private Equity Firm',
  },
  {
    quote: '“ We came to NewtonX after we were still 200 respondents short with two other firms. NewtonX not only located the remaining respondents, but also gave them back to us at about twice the speed that the other companies had. ”',
    title: 'Senior Manager',
    firm: 'Market Research Firm',
  },
  {
    quote: '“ After using NewtonX for a few months, we asked to white label their technology for our own internal use. The setup process was seamless and effective. ”',
    title: 'Executive',
    firm: 'Large Global Corporation',
  },
];

export default () => {
  const settings = {
    adaptiveHeight: true
  }
  return (
    <div className="client-opinions">
      <span className="client-header">What Our Clients Say -</span>
      <Slider settings={settings} paddingBottom="25%">
        {
          SLIDES.map(({
            quote, title, firm,
          }) => (
            <div className="slider-text" key={quote}>
              <p>{quote}</p>
              <span>{title} <br /> {firm}</span>
            </div>
          ))
        }
      </Slider>
    </div>
  );
}