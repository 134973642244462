import React from 'react';

import '../../styles/components/homepage/Expertise.scss';
import '../../styles/components/homepage/AboutUs.scss';

export default (props) => (
  <div className="expertise-container">

    <div className="main-slogan-and-pictures">
      <div className="designer-pictures">
        <img className="designer-figure" src={require('../../images/homepage/figure_blue_dots@3x.png')} alt=""/>
        <div>
        <img className="green-dots" src={require('../../images/homepage/green_dots@3x.png')} alt=""/>

        </div>  
      </div>
      <div className="main-slogan-text">
        <span>The Power of Expertise, { (props.width > 920 || props.width < 564) && <br/> } Now at Your Fingertips</span>
      </div>
    </div>
    <div className="expertise-features">
      <div className="expertise-feature-container">
        <div className="paragraph-header">Quality</div>
        <div className="paragraph-body">
          8 years average work experience in their fields.
        </div>
        <div className="paragraph-header">Speed</div>
        <div className="paragraph-body">
          Global leaders, from Keynote Speakers to C-suite Executives
        </div>
        <div className="paragraph-header">Relevance</div>
        <div className="paragraph-body">
          Transparent feedback and 96%+ satisfaction rate
        </div>
      </div>
      <div className="mobile-dots-container">
        <img src={require('../../images/homepage/mobile_green_dots_container.png')} alt=""/>
      </div>
    </div>
  </div>
);