import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { closeResetPasswordConfirmationModal } from '../../actions/modalActions';
import { withToastManager } from "react-toast-notifications";
import { resetPassword } from '../../actions/signinActions';
import { RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from '../../action-types/login';

export class ResetPasswordConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetPasswordStatus: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resetPasswordStatus !== this.props.resetPasswordStatus &&
            (
                this.state.resetPasswordStatus === RESET_PASSWORD_START &&
                (
                    this.props.resetPasswordStatus === RESET_PASSWORD_SUCCESS ||
                    this.props.resetPasswordStatus === RESET_PASSWORD_FAILURE
                )
            )) {

            this.handleClose();
            if (this.props.resetPasswordStatus === RESET_PASSWORD_SUCCESS) {
                this.props.toastManager.add('Email has been sent with steps to reset password.', {
                    appearance: 'success',
                });
            } else {
                this.props.toastManager.add('Error in sending reset password email', {
                    appearance: 'error',
                });
            }
        }
    }

    handleSubmit = () => {
        let userInfo = {
            email: this.props.modals.userEmail.toLowerCase()
        };

        this.setState({
            resetPasswordStatus:RESET_PASSWORD_START
        });

        this.props.resetPassword(userInfo);
    };

    handleClose = () => {
        this.props.closeResetPasswordConfirmationModal();
    };

    render() {
        return (
            <Modal
                className="modal-content bootstrap gothic-font"
                isOpen={this.props.isOpen}
            >
                <div className="modal-header" style={{ justifyContent: "center", borderBottom: "none" }}>
                    <h1
                        className="modal-title"
                        style={{ color: "#004D71", marginBottom: "0", "lineHeight": "1.5", fontFamily: "inherit", fontWeight: "300", marginLeft: "auto", width: "100%", textAlign: "center" }}
                    >
                        Reset password
                    </h1>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: "80%" }}>
                    <div className="col-12" style={{ height: "100%" }}>
                        <label style={{ margin: "0px 6em 0px 5em" }}>
                            Please confirm that you would like
                            to reset this user's password:
                        </label>
                        <div className="form-row" style={{ position: "absolute", bottom: "0", width: "100%" }}>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-secondary" style={{ width: "100%", height: "38px" }} onClick={this.handleClose}>Cancel</button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <button type="submit" className="btn black-button" style={{ width: "100%", height: "38px" }} onClick={this.handleSubmit}>
                                        Confirm
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = ({ modals, userInfo }) => {
    return {
        modals,
        resetPasswordStatus: userInfo.resetPasswordStatus
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeResetPasswordConfirmationModal: () => dispatch(closeResetPasswordConfirmationModal()),
    resetPassword: (userInfo) => {
        dispatch(resetPassword(userInfo));
    }
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm));