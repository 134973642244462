import React from 'react'

import '../../styles/components/aboutUs/PlatformDescription.scss';

export default () => (
  <div className="platform-description-container">
    <div className="road-image-box">
      <img src={require('../../images/aboutUs/road_about_us.png')} alt=""/>
    </div>
    <div className="platform-description-content">
      <div className="main-content">
        <div className="mobile-img-container">
          <img src={require('../../images/aboutUs/white_dots_mobile.png')} alt=""/>
        </div>
        <div className="header-box">
          <span>The NewtonX Platform -</span>
          <div className="dots-and-figure"> 
            <img src={require('../../images/aboutUs/platform_dots_figure.png')} alt=""/>
          </div>
        </div>
        <div className="main-content-text">
          <div className="intro">
            <div className="text-box">
              <span>NewtonX leverages three core technologies to rapidly and precisely match clients with experts</span>
            </div>
          </div>
          <div className="main-info">
            <p>The NewtonX Knowledge Graph™ is a proprietary expertise mapping framework that leverages a node-vector model.</p>
            <p>The NewtonX matching algorithm leverages supervised machine learning to pinpoint the best match experts.</p>
            <p>Robotic Process Automation handles requests, matching  and scheduling more precisely and rapidly than any human could.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);