import React, { Component } from 'react';
import HowWeWorkHeader from '../../components/howWeWork/HowWeWorkHeader';
import HowItWorks from '../../components/howWeWork/HowItWorks';
import MainBlackBox from '../../components/common/MainBlackBox';
import ExpertCalls from '../../components/howWeWork/ExpertCalls';
import ExpertSurveys from '../../components/howWeWork/ExpertSurveys';
import InfoDropdowns from '../../components/howWeWork/InfoDropdowns';

export default class HowWeWork extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }
  render() {
    const imgStyle = {
      position: 'absolute',
      marginTop: '1px',
      left: 0,
      height: '320px',
      width: '280px',
      zIndex: 1,
    }

    if(this.state.width < 1098) {
      imgStyle.height = '275px';
    }
    if(this.state.width < 811) {
      imgStyle.visibility = 'hidden'
    }
    return (
      <div>
        <HowWeWorkHeader width={this.state.width} />
        <HowItWorks width={this.state.width} />
        <MainBlackBox 
          contentStyle={{ height: '320px'}}
          mainTitle="Gain Access to Unprecendented Insights - "
          subTitle="Client Overview"
          text="Access the data and insights you need to move your business forward. 
            NewtonX is your secret weapon: fast, seamless, and robust."
        > 
          <img style={imgStyle} src={require('../../images/howWeWork/Bitmap.png')} alt="" />
        </MainBlackBox>
        <ExpertCalls />
        <ExpertSurveys width={this.state.width} />
        <MainBlackBox 
          contentStyle={{ height: '250px'}}
          mainTitle="Compliance - "
          subTitle=" "
          text="Compliance guides the daily activities of all NewtonX teams. 
            We believe in a world of knowledge access and sharing - but such a vision 
            can only be achieved in strict accordance with compliance policies."
        />
        <InfoDropdowns width={this.state.width}/>
      </div>
    );
  }
}