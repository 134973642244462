import React from 'react';
import { Formik } from 'formik'; 
import '../../styles/components/FAQ/FAQEntryItem.scss';

const FaqForm = (props) => {
    return(
        <section className="section section-faq bootstrap gothic-font flex-column">
        <div className="faq w-100 p-3">
          <h2 className="faq__title">Frequently Asked Questions</h2>
          <div className="faq__row">
            <div className="column col-experts">
              <div className="entry-container">
                <div className="header-content">
                  <div className="faq-question" id="questionOne" onClick={props.onClick}>What distinguishes NewtonX from other expert networks?</div>
                </div>
                <div id="experts_faq_02" hidden={props.answerOneHidden}>
                    <div className="main-content">
                        <p>We are a technology-driven knowledge platform trying to make the exchange of knowledge between experts and companies as fruitful and easy as possible. We do not rely on lengthy imprecise text-based resumés: we use our proprietary profile builder and algorithm to quickly extract your expertise and thereby enable companies to better search and target you.</p>
                    </div>
                </div>
              </div>
              <div className="entry-container">
                <div className="header-content">
                  <div className="faq-question" id="questionTwo" onClick={props.onClick}>Which industries are we looking for?</div>
                </div>
                <div id="experts_faq_02" className="main-content" hidden={props.answerTwoHidden}>
                  <p>We are focusing on tech domains, i.e., all topics from app development to cloud, data centers, e-commerce, IoT, SaaS, Virtual Reality and many more. Furthermore, we cover a broad range of industries for these topics from Aerospace & Defense to Consumer Goods, Healthcare, Media, Public Sector and Telco.</p>
                </div>
              </div>
              <div className="entry-container">
              <div className="header-content">
                  <div className="faq-question" id="questionThree" onClick={props.onClick}>What is the process to update my password?</div>
              </div>
              <div id="experts_faq_03 main-content" hidden={props.answerThreeHidden}>
                  <p>As an expert, you may update your password at any time.</p>
                  <p>To update your password, click on the Account tab, then click "Change my Password."</p>
              </div>
              </div>
              <div className="entry-container">
                <div className="header-content">
                    <div className="faq-question" id="questionFour" onClick={props.onClick}>How can I update my payment information?</div>
                </div>
                    <div id="experts_faq_04" className="main-content" hidden={props.answerFourHidden}>
                      <p>To update your payment information, please click on the "Payments" tab. Select the desired payment type, input the relevant account information, and click "Save".</p>
                    </div>
                </div>
                <div className="entry-container">
                  <div className="header-content">
                        <div className="faq-question" id="questionFive" onClick={props.onClick}>How can I update my name and phone number on file?</div>
                  </div>
                      <div className="main-content" id="experts_faq_05" hidden={props.answerFiveHidden}>
                      <p>To update your name and phone number, please click "Profile", perform the desired changes, and click "Save". </p>
                      </div>
                  </div>
              <div className="entry-container">
                <div className="header-content" style={{display: "block"}}>
                    <div className="faq-question"  id="questionSix" onClick={props.onClick}>How can I check or remove my NewtonX information?</div>
                    <div id="experts_faq_06" hidden={props.answerSixHidden}>
                            <div className="faq-question" id="questionSeven" onClick={props.onClick}>How can I confirm the information NewtonX has on file?</div>
                          <div className="collapse-wrap">
                              <div id="experts_faq_07" hidden={props.answerSevenHidden}>
                                <p>To confirm the payment and contact information NewtonX has on file, please refer to the Payment and Profile tabs.</p>
                                <p>If you wish to obtain a PDF summary of your NewtonX information, a PDF copy can be sent at your request.</p>
                                <p>Please fill out this form to request a PDF copy of your information. We will then reach out to you within 3-5 business days with the information.</p>
                                  <h4 className="py-3">Expert Information</h4>
                                  <Formik
                                    initialValues={{ email: '', firstName: '', lastName: '', phone: '', linkedInUrl: '' }}
                                    validate={values => {
                                      let errors = {};
                                      if (!values.email) {
                                        errors.email = 'Required';
                                      } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                      ) {
                                        errors.email = 'Invalid email address';
                                      }

                                      if (!values.firstName) {
                                        errors.firstName = 'Required';
                                      }

                                      if (!values.lastName) {
                                        errors.lastName = 'Required';
                                      }

                                      if (!values.phone) {
                                        errors.phone = 'Required';
                                      }

                                      if (!values.linkedInUrl) {
                                        errors.linkedInUrl = 'Required'; 
                                      }

                                      return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                      props.onSubmit(); 
                                      setSubmitting(false); 
                                    }}
                                  >
                                    {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      /* and other goodies */
                                    }) => (
                                      <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                          <label className="required">Email:</label>
                                          <p style={{color: 'red'}}>{errors.email && touched.email && errors.email}</p>
                                          <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label className="required">First Name:</label>
                                          <p style={{color: 'red'}}>{errors.firstName && touched.firstName && errors.firstName}</p>
                                          <input
                                            type="firstName"
                                            name="firstName"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.firstName}
                                          />
                                        </div>
                                        
                                        <div className="form-group">
                                          <label className="required">Last Name:</label>
                                          <p style={{color: 'red'}}>{errors.lastName && touched.lastName && errors.lastName}</p>
                                          <input
                                            type="lastName"
                                            name="lastName"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                          />
                                        </div>
                                        
                                        <div className="form-group">
                                          <label className="required">Phone:</label>
                                          <p style={{color: 'red'}}>{errors.phone && touched.phone && errors.phone}</p>
                                          <input
                                            type="phone"
                                            name="phone"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label className="required">LinkedIn Profile URL:</label>
                                          <p style={{color: 'red'}}>{errors.linkedInUrl && touched.linkedInUrl && errors.linkedInUrl}</p>
                                          <input
                                            type="linkedInUrl"
                                            name="linkedInUrl"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.linkedInUrl}
                                          />
                                        </div>
                                        <button type="submit" className="btn black-button" disabled={isSubmitting}>
                                          Submit
                                        </button>
                                      </form>
                                    )}
                                </Formik>
                              </div>
                          </div>
                      <div id="experts_faq_06" >
                            <div className="faq-question" id="questionEight" onClick={props.onClick}>How can I get my information deleted from NewtonX?</div>
                              <div id="experts_faq_08" className="main-content" hidden={props.answerEightHidden}>
                              <p>Your account may be removed at your request. Please be advised that this deletion is permanent. If you currently have a payment from NewtonX pending, please do not delete your account until payment has been received.</p>
                              <p>If you wish to proceed with deletion, please click the below link to request that your account be deleted:</p>
                              <a href="javascript:void(0)" onClick={props.onDeleteClick}>Delete My Account</a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>

    )
}

export default FaqForm; 