import React, { Component } from 'react'
import '../../styles/components/homepage/ContactUs.scss';
import ContactUsModal from '../../containers/contactUs/contactUs';

export default class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    }
  }
  render () {
    return (
      <div className="contact-us-container">
        <div className="contact-us-title">
          <img src={(require('../../images/homepage/green_dots_figure@3x.png'))} alt=""/>
          <span className="title">Contact Us -</span>
        </div>
        <div className="main-content">
          <div className="main-content-text">
            Interested in learning 
            <br />
            more about NewtonX? 
          </div>
          <button onClick={this.handleContactUsClick}>Contact Us</button>
        </div>
        <ContactUsModal isOpen={this.state.isModalOpen} onClose={this.onModalClose} />
      </div>
    );
  }
  handleContactUsClick = (e) => {
    e.preventDefault();
    this.setState({ isModalOpen: true});
  }
  onModalClose = () => {
    this.setState({ isModalOpen: false });
  }
}