import React, { Component } from 'react';
import { Formik } from 'formik';

class ExpertSetupForm extends Component {
    render() {
        return(
            <div style={{paddingBottom: "2em"}} className="bootstrap gothic-font">
                <h2 className="headline">Become an Expert</h2>
                <section className="container">
                  <div className="row">
                    <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <h4 className="py-3">Basic Information</h4>
                        <Formik
                            initialValues={{ firstName: '', lastName: '', phone: ''}}
                            validate={ values => {
                                let errors = {};
                                if (!values.firstName) {
                                    errors.firstName = 'Required';
                                } 
                                if (!values.lastName) {
                                    errors.lastName = 'Required';
                                }
                                if (!values.phone) {
                                    errors.phone = 'Required'; 
                                }
                                return errors; 
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                this.props.onSubmit(values);
                                setSubmitting(false);  
                            }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                  <label className="required">First Name</label>
                                  <input
                                    type="text"
                                    name="firstName"
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="required">Last Name</label>
                                  <input
                                    type="text"
                                    name="lastName"
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="required">Phone Number</label>
                                  <input
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                  />
                                </div>

                                <button type="submit" disabled={isSubmitting} className="btn black-button">
                                    Submit
                                </button>
                            </form>
                        )}
                        </Formik>
                    </div>
                  </div>
                </section>
            </div> 

        )
    }
}

export default ExpertSetupForm; 