import React, { Component } from 'react';

import '../../styles/components/FAQ/FAQEntryItem.scss';

export default class FAQEntryItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    }
  }
  render() {
    let answers;
    if(this.props.answers) {
      answers = this.props.answers.map((answer, i) => {
        return <p key={i}>{answer}</p>
      })
    }
    return (
      <div className="entry-container">
        <div className="header-content">
          <div className="faq-question" onClick={this.onExpandClick}>{this.props.question}</div>
          <i 
            onClick={this.onExpandClick} 
            className={this.state.expanded ? "expand-arrow-open" : 'expand-arrow-close' }
          />
        </div>
        <div className="main-content">
          {this.state.expanded && answers}
        </div>
      </div>
    )
  }
  onExpandClick = () => {
    this.setState(prevState => {
      return {
        expanded: !prevState.expanded,
      }
    })
  }
}