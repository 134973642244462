import React from 'react'

import '../../styles/components/aboutUs/Advantages.scss';
import '../../styles/components/homepage/HowItWorks.scss';

export default (props) => (
  <div className="how-it-works-container">
    <div className="how-it-works-header">
      <span className="bold-text">Results-Oriented</span>
      <span 
        className="normal-text" 
        style={{ margin: props.width < 450 ? '0px 5%' : '0px 20%'}}
      >
        NewtonX was created with a vision: to make expertise access better, faster and cheaper.
        {props.width > 1357 && <br />} This vision is realized every day in the manner in which we connect clients with experts:
      </span>
    </div>
    <div className="how-it-works-icons">
      <div className="icon-container">
        <img style={{ width: '106px', height: '106px'}} src={require('../../images/aboutUs/icon_better@3x.png')} alt=""/>
        <span>Better</span>
        <p>98% satisfaction across expert interactions</p>
      </div>
      <div className="icon-container">
        <img style={{ width: '106px', height: '106px'}} src={require('../../images/aboutUs/icon_cheaper@3x.png')} alt=""/>
        <span>Cheaper</span>
        <p>25% cheaper than traditional  expert networks</p>
      </div>
      <div className="icon-container">
        <img style={{ width: '136px', height: '106px'}} src={require('../../images/aboutUs/faster_icon@3x.png')} alt=""/>
        <span>Faster</span>
        <p>Same-day turnaround from  request to expert delivery</p>
      </div>
    </div>
  </div>
);