import React from 'react'
import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Visibility"
      text="We provide our clients with visibility into their activities at various levels:"
    >
      <ul>
        <li>
          NewtonX users can track their activity, the different expert profiles already 
          contacted as well as the potential profiles to be contacted
        </li>
        <li>
          Client oversight personnel have an aggregate view of the activity at the company 
          level, and can trace which employees talked to which experts at any point in time
        </li>
        <li>
          Client oversight personnel can also display specific metrics or implementspecific 
          triggers in order to adequately monitor the activity of their employees
        </li>
      </ul>
    </DropdownElement>
    <DropdownElement 
      description="Custom Compliance"
      text="We acknowledge that our clients have unique sets of needs. Consequently, 
        we go beyond the standard client compliance program described above to also 
        implement custom compliance processes for our clients, upon request."
    >
      <ul>
        <li>
          Notification of requests sent to oversight personel for specific groups of experts
        </li>
        <li>
          Pre-defined screening questions or attestations before entering 
          into expertise sharing with specific groups of experts
        </li>
        <li>
          Customized rules concerning former employees
        </li>
      </ul>
    </DropdownElement>
    <DropdownElement 
      description="Employer Database Registry"
      text="In order to prevent any conflict of interest with any organization, we have implemented 
        a system by which organizations can register restrictions or guidelines concerning 
        consulting activity by any of their professionals or employees. The output of 
        this system is the NewtonX employer preference registry which we use to comprehensively 
        enforce organizations’ policies prohibiting or limiting outside consulting to any of their employees. 
        If you are an employer and want to inform us of any policies you have regarding outside consulting, 
        please feel to do so by emailing us at compliance@newtonx.com."
    />
  </div>
);