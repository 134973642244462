import * as googlePhoneLib from 'google-libphonenumber';

class GooglePhoneService {    
    isValidPhoneNumber = (phoneNumber) => {
        const phoneUtil = googlePhoneLib.PhoneNumberUtil.getInstance();
            try {
                const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
                return phoneUtil.isValidNumber(number);
            } catch (e) { }

            return false;
    }
}

export default new GooglePhoneService(); 