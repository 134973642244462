import React, { Component } from 'react';
import queryString from 'query-string';
import SignupService from '../../utils/signupService';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { openSigninModal } from '../../actions/modalActions';
import { setRedirectingFromEmailVerification, verifyUser } from '../../actions/signupActions';
import { VERIFY_USER_START, VERIFY_USER_SUCCESS } from '../../action-types/signup';

export class ExpertEmailVerifier extends Component {

    constructor() {
        super();
        this.signupService = new SignupService();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.vefifyUserStatus !== this.props.vefifyUserStatus &&
            this.props.vefifyUserStatus !== VERIFY_USER_START) {

            if (this.props.vefifyUserStatus === VERIFY_USER_SUCCESS) {
                this.props.setRedirectingFromEmailVerification(true);
                this.props.history.push('/');
                this.props.openSigninModal();
            } else {
                this.handleVerificationError(this.props.verifyUserError);
            }
        }
    }

    // user arrives here from clicking on a link in their verification email
    componentDidMount() {
        const verificationToken = this.getVerificationTokenFromURL();
        if (verificationToken) {
            this.props.verifyUser(verificationToken);
        } else {
            this.props.history.push('/');
        }
    }

    handleVerificationError = (response) => {
        if (response.status === 404) {
            this.props.toastManager.add(response.data.description,
                {
                    appearance: 'success',
                }
            );
        } else {
            this.props.toastManager.add('Unable to verify email address.',
                {
                    appearance: 'error',
                }
            );
        }

        this.props.history.push('/');
    }

    getVerificationTokenFromURL() {
        // link example: https://newtonx.com/verify?ref=qhklw2exiqdp7oqjgzpv
        const params = queryString.parse(this.props.location.search);
        return params['ref'];
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({ signup }) => ({
    vefifyUserStatus: signup.vefifyUserStatus,
    verifyUserError: signup.verifyUserError
});

const mapDispatchToProps = (dispatch) => ({
    openSigninModal: () => {
        dispatch(openSigninModal())
    },

    setRedirectingFromEmailVerification: (redirectingFromEmailVerification: boolean) => {
        dispatch(setRedirectingFromEmailVerification(redirectingFromEmailVerification));
    },

    verifyUser: (verificationToken) => {
        dispatch(verifyUser(verificationToken));
    }
})
export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ExpertEmailVerifier));