import React from 'react';

import '../../styles/components/aboutUs/CrewInfo.scss';

export default (props) => (
  <div className="crew-container">
    <div className="crew-content">

      <div className="profile">
        <div className="profile-photo">
          <img src={require('../../images/aboutUs/germain_photo@2x.png')} alt=""/>
        </div>
        <div className="profile-info">
          <span className="name">Germain Chastel - </span>
          <span className="title">CEO</span>
          <p className="info">
            Germain Chastel is a graduate of Harvard Business School,
            a former Associate Partner with McKinsey & Company, a former Acquisition Finance
            professional at Societe Generale Investment Banking.
          </p>
        </div>
      </div>

      <div className="profile" style={{ alignItems: 'center'}}>
        <div className="profile-info relative-setup" style={{ paddingTop: '0px'}}>
        <div className="profile-photo relative" style={{ display: props.width > 880 && 'none'}} >
          <img src={require('../../images/aboutUs/yufei_pan_photo.jpg')} alt=""/>
        </div>
          <span className="name">Yufei Pan - </span>
          <span className="title">CTO</span>
          <p className="info">
            Yufei joined NewtonX in 2019 as its CTO, helping to shape its technology strategy and innovation.
            Prior to joining NewtonX, Yufei was the Head of Search & Data at Tumblr, and Senior Director of Search Technology at Ask.com.
            Yufei holds a Master’s in Computer Science from Rutgers. Originally from China, Yufei enjoys playing ping pong, badminton and tennis.
          </p>
        </div>
        <div className="profile-photo relative"  style={{ display: props.width < 880 && 'none'}} >
          <img src={require('../../images/aboutUs/yufei_pan_photo.jpg')} alt=""/>
        </div>
      </div>

      <div className="profile">
        <div className="profile-photo" >
          <img
            src={require('../../images/aboutUs/sascha_picture@2x.png')}
            style={{
              marginTop: props.width < 880 ? '0px' : '160px',
              marginLeft: props.width < 880 && '-20px',
              height: props.width < 880 &&  '260px',
            }}
            alt=""
          />
        </div>
        <div className="profile-info">
          <span className="name">Sasha Eder - </span>
          <span className="title">COO</span>
          <p className="info">
            Sascha Eder is a graduate of MIT, former Consultant with both Boston Consulting
            Group and McKinsey & Company, and a former financial analyst at P&G. Originally from Germany,
            Sascha was once a member of the German national track and field team.
          </p>
        </div>
      </div>

    </div>
  </div>
);
