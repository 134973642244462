import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { updateExpertPayment } from '../../actions/signinActions';
import DomesticPaymentEditForm from './DomesticPaymentEditForm';
import PaypalPaymentEditForm from './PaypalPaymentEditForm';
import InternationalPaymentEditForm from './InternationalPaymentEditForm';
import { withToastManager } from 'react-toast-notifications';
import { UPDATE_EXPERT_PAYMENT_START, UPDATE_EXPERT_PAYMENT_SUCCESS, UPDATE_EXPERT_PAYMENT_FAILURE } from '../../action-types/login';

export class ExpertPaymentEditForm extends Component {
    constructor() {
        super();
        this.state = {
            paypal: false,
            domestic: false,
            international: false,
            updateExpertPaymentStatus: ''
        }
    }

    componentDidMount() {
        /**
         * we do this so that the user will see their chosen
         * payment type and associated info by default
         */
        this.setSelectedPaymentTypeFromStore();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateExpertPaymentStatus !== this.props.updateExpertPaymentStatus &&
            (
                this.state.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_START &&
                (
                    this.props.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_SUCCESS ||
                    this.props.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_FAILURE
                )
            )) {

            if (this.props.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_SUCCESS) {
                this.props.toastManager.add('Information updated successfully!', {
                    appearance: 'success'
                });
            } else {
                this.props.toastManager.add('Something went wrong. Information could not be saved. Please try again.', {
                    appearance: 'error'
                });
            }
        }
    }

    setSelectedPaymentTypeFromStore = () => {
        Object.keys(this.state).forEach(key => {
            if (key.toString().toUpperCase() === this.props.expertPayment.selectedType) {
                this.setState({
                    [key]: true
                })
            }
        })
    }

    handlePaymentTypeSelect = (event) => {
        let stateClone = { ...this.state };
        let updatedStateClone = this.updateChosenPaymentType(event.target.value, stateClone);
        this.setState({
            ...updatedStateClone
        });
    }

    extractInternationalValues = () => {
        // format currentValues to match formatting used in InternationalPaymentEditForm
        let currentValues = {
            ibanSubFormValues: {
                intlIban: this.props.expertPayment.intlIban || '',
                intlSwiftBic: this.props.expertPayment.intlSwiftBic || '',
                intlBankAddress: this.props.expertPayment.intlBankAddress || '',
                intlBankCity: this.props.expertPayment.intlBankCity || '',
                intlBankState: this.props.expertPayment.intlBankState || '',
                intlBankPostalCode: this.props.expertPayment.intlBankPostalCode || '',
                intlHasIban: this.props.expertPayment.intlHasIban || false
            },
            values: {
                intlUserAddress: this.props.expertPayment.intlUserAddress || '',
                intlUserCity: this.props.expertPayment.intlUserCity || '',
                intlUserState: this.props.expertPayment.intlUserState || '',
                intlUserPostalCode: this.props.expertPayment.intlUserPostalCode || '',
                intlNotes: this.props.expertPayment.intlNotes || '',
            },
            ibanChecked: this.props.expertPayment.intlHasIban || false
        };
        return currentValues;
    }

    // selected payment type key must be made UPPERCASE to match format used in backend 
    getSelectedPaymentType = () => {
        return Object.keys(this.state).filter(key => this.state[key] === true)[0].toUpperCase();
    }

    handleSubmit = (paymentFormValues) => {
        let expertPayment = {
            ...this.props.expertPayment,
            ...paymentFormValues,
            selectedType: this.getSelectedPaymentType()
        }
        let filteredExpertPayment = this.filterUnchangedValues(expertPayment);

        this.setState({
            updateExpertPaymentStatus: UPDATE_EXPERT_PAYMENT_START
        });

        this.props.updateExpertPayment(this.props.expert.id, filteredExpertPayment);
    }

    /**
     * the backend returns acct information in ********XXXX format with literal asterisks, 
     * so we need to make sure we do not overwrite real backend information with asterisks
     */
    filterUnchangedValues = (newExpertPayment) => {
        let filteredExpertPayment = {
            id: this.props.expertPayment.id,
            expertId: this.props.expert.id,
        };

        Object.keys(newExpertPayment).forEach(key => {
            if (newExpertPayment[key] !== this.props.expertPayment[key]) {
                filteredExpertPayment[key] = newExpertPayment[key]
            }
        });
        return filteredExpertPayment;
    }

    /** 
     * since only one payment type can be selected at once, we must iterate through all 
     * and set others to false 
     */
    updateChosenPaymentType = (checkboxValue: string, stateClone: Object) => {
        Object.keys(stateClone).forEach(key => {
            if (key === checkboxValue) {
                stateClone[key] = true;
            } else {
                stateClone[key] = false;
            }
        });
        return stateClone;
    }

    render() {
        return (
            <Fragment>
                <div layout="row mt-3" className="bootstrap gothic-font">
                    <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <h4 className="py=3">Your preferred payment method</h4>
                        <form>
                            <div className="form-check">
                                <input
                                    id="paypal"
                                    className="form-check-input"
                                    name="payment"
                                    value="paypal"
                                    type="radio"
                                    onClick={this.handlePaymentTypeSelect}
                                    checked={this.state.paypal}
                                />
                                <label name="paypal" className="form-check-label">
                                    Gift card/Paypal (preferred)
                                    <p>
                                        Via our partner <a href='https://www.rybbon.net/rewards-gifts/' target="_blank">Rybbon</a> within 7 days. Large selection including eVisa virtual debit card (worldwide), eMastercard virtual debit card (worldwide), Paypal*, Amazon gift card*, Donations* and many more e-gift cards*. *If available in your country. <strong>Payments will be sent within 1 week after your consultation directly to your email address.</strong>
                                    </p>
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="domestic"
                                    className="form-check-input"
                                    name="payment"
                                    value="domestic"
                                    type="radio"
                                    checked={this.state.domestic}
                                    onClick={this.handlePaymentTypeSelect}
                                />
                                <label name="domestic" className="form-check-label">
                                    Domestic electronic payment (USA)
                                    <p>
                                        Payment will be sent <strong>directly to your bank account</strong> within 15 days of your consultation date
                                    </p>
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="international"
                                    className="form-check-input"
                                    name="payment"
                                    value="international"
                                    type="radio"
                                    checked={this.state.international}
                                    onClick={this.handlePaymentTypeSelect}
                                />
                                <label name="international" className="form-check-label">
                                    International electronic payment (outside of USA)
                                    <p>
                                        Payment will be sent <strong>directly to your bank account</strong> within 15 days of your consultation date
                                    </p>
                                </label>
                            </div>
                        </form>
                        <div className="row mt-4" style={{ paddingBottom: "2em" }}>
                            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                                {this.state.domestic ? <DomesticPaymentEditForm currentValues={this.props.expertPayment} handleSubmit={this.handleSubmit} /> : null}
                                {this.state.paypal ? <PaypalPaymentEditForm currentValues={this.props.expertPayment} handleSubmit={this.handleSubmit} /> : null}
                                {this.state.international ? <InternationalPaymentEditForm currentValues={this.extractInternationalValues()} handleSubmit={this.handleSubmit} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = ({ userInfo: { expert, expertPayment, updateExpertPaymentStatus } }) => ({
    expertPayment,
    expert,
    updateExpertPaymentStatus
});

const mapDispatchToProps = (dispatch) => ({
    updateExpertPayment: (expertId, expertPayment) => {
        dispatch(updateExpertPayment(expertId, expertPayment))
    }
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ExpertPaymentEditForm)); 