import React, { Component } from 'react';
import ExpertCard from './ExpertCard';

export default ({
                    expertList,
                    setResetPasswordUserEmail,
                    openResetPasswordConfirmationModal,
                    setResendVerificationEmailUserId,
                    openResendVerificationEmailConfirmationModal
}) => {
  return(
    expertList.map(userInfo => {
      return <ExpertCard key={userInfo.expert.id}
          userInfo={userInfo}
          setResetPasswordUserEmail={setResetPasswordUserEmail}
          openResetPasswordConfirmationModal={openResetPasswordConfirmationModal}
          setResendVerificationEmailUserId={setResendVerificationEmailUserId}
          openResendVerificationEmailConfirmationModal={openResendVerificationEmailConfirmationModal}
      />
    })
  );
}
