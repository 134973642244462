import React from 'react';
import InfoDropdown from '../common/InfoDropdown';
import OverviewDropdown from './dropdowns/OverviewDropdown';
import ExpertCompliance from './dropdowns/ExpertCompliance';
import ClientCompilance from './dropdowns/ClientCompilance';

export default (props) => (
  <div style={{ marginBottom: props.width < 450 ? '50px' : '150px'}}>
    <InfoDropdown 
      title="Overview - "
      shortText="The NewtonX compliance framework is one of the most robust and conservative in the industry."
    >
      <OverviewDropdown />
    </InfoDropdown>
    <InfoDropdown 
      title="Expert Compliance - "
      shortText="Experts receive compliance training and are bound  by the NewtonX terms and conditions."
    >
      <ExpertCompliance />
    </InfoDropdown>
    <InfoDropdown 
      title="Client Compliance - "
      shortText="NewtonX offers an employer database registry,  high levels of visibility, and custom compliance frameworks."
    >
      <ClientCompilance />
    </InfoDropdown>
  </div>
);