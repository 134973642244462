export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const CLEAR_USER_INFORMATION = 'CLEAR_USER_INFORMATION';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FETCH_PROFILE_START = 'FETCH_PROFILE_START';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const RESEND_VERIFICATION_EMAIL_START = 'RESEND_VERIFICATION_EMAIL_START';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_FAILURE = 'RESEND_VERIFICATION_EMAIL_FAILURE';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const UPDATE_EXPERT_START = 'UPDATE_EXPERT_START';
export const UPDATE_EXPERT_SUCCESS = 'UPDATE_EXPERT_SUCCESS';
export const UPDATE_EXPERT_FAILURE = 'UPDATE_EXPERT_FAILURE';

export const UPDATE_EXPERT_PAYMENT_START = 'UPDATE_EXPERT_PAYMENT_START';
export const UPDATE_EXPERT_PAYMENT_SUCCESS = 'UPDATE_EXPERT_PAYMENT_SUCCESS';
export const UPDATE_EXPERT_PAYMENT_FAILURE = 'UPDATE_EXPERT_PAYMENT_FAILURE';

export const UPDATE_USER_PASSWORD_START = 'UPDATE_USER_PASSWORD_START';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export const DOWNLOAD_USER_INFORMATION_START = 'DOWNLOAD_USER_INFORMATION_START';
export const DOWNLOAD_USER_INFORMATION_SUCCESS = 'DOWNLOAD_USER_INFORMATION_SUCCESS';
export const DOWNLOAD_USER_INFORMATION_FAILURE = 'DOWNLOAD_USER_INFORMATION_FAILURE';

export const DELETE_USER_INFORMATION_START = 'DELETE_USER_INFORMATION_START';
export const DELETE_USER_INFORMATION_SUCCESS = 'DELETE_USER_INFORMATION_SUCCESS';
export const DELETE_USER_INFORMATION_FAILURE = 'DELETE_USER_INFORMATION_FAILURE';

export const CREATE_USER_LOG_START = 'CREATE_USER_LOG_START';
export const CREATE_USER_LOG_SUCCESS = 'CREATE_USER_LOG_SUCCESS';
export const CREATE_USER_LOG_FAILURE = 'CREATE_USER_LOG_FAILURE';