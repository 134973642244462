import React from 'react';
import { Formik } from 'formik'; 
const UserAccountForm = (props) => {
    return(
        <Formik
            initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: ''}}
            validate={ values => {
                let errors = {};
                if (!values.currentPassword) {
                    errors.currentPassword = 'Required';
                } 
                if (!values.newPassword) {
                    errors.newPassword = 'Required';
                }
                if (!values.confirmNewPassword) {
                    errors.confirmNewPassword = 'Required'; 
                }
                if (values.newPassword !== values.confirmNewPassword) {
                    errors.confirmNewPassword = 'New password and confirm password must match!';
                }
                return errors; 
            }}
            onSubmit={(values, { setSubmitting }) => {
                props.onSubmit(values);
                setSubmitting(false);  
            }}
        >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
        }) => (
            
            <form onSubmit={handleSubmit} className="bootstrap gothic-font">
                <div className="form-group">
                    <label className="required">Current Password</label>
                    <input
                    type="password"
                    name="currentPassword"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                    />
                </div>

                <div className="form-group">
                    <label className="required">New Password</label>
                    <input
                    type="password"
                    name="newPassword"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    />
                </div>

                <div className="form-group">
                    <label className="required">Confirm New Password</label>
                    <input
                    type="password"
                    name="confirmNewPassword"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPassword}
                    />
                </div>

                <button type="submit" disabled={isSubmitting} className="btn black-button">
                    Update Password
                </button>
            </form>
        )}
        </Formik>
    )
}

export default UserAccountForm; 