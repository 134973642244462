import { SET_SIGNUP_ACCESS_TOKEN, CLEAR_SIGNUP_ACCESS_TOKEN, SET_SIGNUP_USER, CLEAR_SIGNUP_USER, SET_REDIRECTING_FROM_EMAIL_VERIFICATION, SEND_CLIENT_NOTIFICATION_EMAIL_START, SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS, SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE, FETCH_PLATFORM_EXPERT_BY_GUID_START, FETCH_PLATFORM_EXPERT_BY_GUID_SUCCESS, FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE, POST_USER_START, POST_USER_SUCCESS, POST_USER_FAILURE, VERIFY_USER_START, VERIFY_USER_SUCCESS, VERIFY_USER_FAILURE } from '../action-types/signup';

import { environment } from '../environments/env';

export default (state = {
  signupToken: null,
  signupUser: {
    firstName: '',
    lastName: '',
    email: '',
    linkedInId: ''
  },
  setRedirectingFromEmailVerification: false,
  sendClientNotificationEmailStatus: '',
  fetchPlatformExpertByGuidStatus: '',
  postUserStatus: '',
  vefifyUserStatus: '',
  verifyUserError: undefined
}, action) => {
  switch (action.type) {
    case SET_SIGNUP_ACCESS_TOKEN:
      sessionStorage.setItem(environment.SIGNUP_TOKEN_NAME, action.payload.nxTempAccessToken);
      return {
        ...state,
        signupToken: action.payload.nxTempAccessToken
      }
    case CLEAR_SIGNUP_ACCESS_TOKEN:
      return {
        ...state,
        signupToken: null
      }
    case SET_SIGNUP_USER:
      return {
        ...state,
        signupUser: action.payload
      }

    case CLEAR_SIGNUP_USER:
      return {
        ...state,
        signupUser: {
          firstName: '',
          lastName: '',
          email: '',
          linkedInId: ''
        }
      }
    case SET_REDIRECTING_FROM_EMAIL_VERIFICATION:
      return {
        ...state,
        redirectingFromEmailVerification: action.payload.redirectingFromEmailVerification
      }
    case SEND_CLIENT_NOTIFICATION_EMAIL_START:
    case SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS:
    case SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        sendClientNotificationEmailStatus: action.type
      }
    case FETCH_PLATFORM_EXPERT_BY_GUID_START:
    case FETCH_PLATFORM_EXPERT_BY_GUID_SUCCESS:
    case FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE:
      return {
        ...state,
        fetchPlatformExpertByGuidStatus: action.type
      }
    case POST_USER_START:
    case POST_USER_SUCCESS:
    case POST_USER_FAILURE:
      return {
        ...state,
        postUserStatus: action.type
      }
    case VERIFY_USER_START:
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        vefifyUserStatus: action.type,
      }
    case VERIFY_USER_FAILURE:
      return {
        ...state,
        vefifyUserStatus: action.type,
        verifyUserError: action.payload
      }
    default:
      return state
  }
}