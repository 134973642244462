import React from 'react';
import { Formik } from 'formik'; 

const DomesticPaymentForm = (props) => {
    return(
        <Formik
        initialValues={{ domesticAccountNumber: '', domesticRoutingNumber: '', domesticNotes: ''}}
        validate={ values => {
            let errors = {};
            if (!values.domesticAccountNumber) {
                errors.domesticAccountNumber = 'Required';
            } 
            if (values.domesticAccountNumber.length < 3 || values.domesticAccountNumber.length > 18) {
                errors.domesticAccountNumber = 'Account number must be between 3 and 18 digits';
            }
            if (!values.domesticRoutingNumber) {
                errors.domesticRoutingNumber = 'Required';
            }
            if (values.domesticRoutingNumber.length !== 9) {
                errors.domesticRoutingNumber = 'Routing number must be 9 digits'; 
            }
            return errors; 
        }}
        onSubmit={(values, { setSubmitting }) => {
            props.handleSubmit(values);
            setSubmitting(false);  
        }}
    >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    }) => (
        
        <form onSubmit={handleSubmit} className="bootstrap gothic-font">
            <div className="form-group">
              <label className="required">Account Number</label>
              <p style={{color: "red"}}>{errors.domesticAccountNumber}</p>
              <input
                type="text"
                name="domesticAccountNumber"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.domesticAccountNumber}
              />
            </div>

            <div className="form-group">
              <label className="required">Routing Number</label>
              <p style={{color: "red"}}>{errors.domesticRoutingNumber}</p>
              <input
                type="text"
                name="domesticRoutingNumber"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.domesticRoutingNumber}
              />
            </div>

            <div className="form-group">
              <label>Additional Information</label>
              <p style={{color: "red"}}>{errors.domesticNotes}</p>
              <input
                type="text"
                name="domesticNotes"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.domesticNotes}
              />
            </div>

            <button type="submit" disabled={isSubmitting} className="btn black-button">
                Submit
            </button>
        </form>
    )}
    </Formik>
    )
}

export default DomesticPaymentForm; 