import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { closeForgotPasswordModal, openForgotPasswordStatusModal } from '../../actions/modalActions';
import { resetPassword } from '../../actions/signinActions';
import { RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from '../../action-types/login';

export class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            touched: false,
            errors: {
                emailNotPresent: true,
                emailInvalid: false
            },
            resetPasswordStatus: ''
        }
    }

    handleBlur = () => {
        this.setState({
            touched: true
        })
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value,
            errors: {
                ...this.state.errors,
                emailNotPresent: value.length === 0
            }
        })
    }

    handleSubmit = () => {
        let userInfo = {
            email: this.state.email.toLowerCase()
        };
        if (this.state.errors.emailNotPresent === false) {
            this.setState({
                resetPasswordStatus: RESET_PASSWORD_START
            });
            this.props.resetPassword(userInfo);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resetPasswordStatus !== this.props.resetPasswordStatus &&
            (
                this.state.resetPasswordStatus === RESET_PASSWORD_START &&
                (
                    this.props.resetPasswordStatus === RESET_PASSWORD_SUCCESS ||
                    this.props.resetPasswordStatus === RESET_PASSWORD_FAILURE
                )
            )) {

            if (this.props.resetPasswordStatus === RESET_PASSWORD_SUCCESS) {
                this.displayForgotPasswordStatusModal();
            } else {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        emailInvalid: true
                    }
                });
            }
        }
    }

    displayForgotPasswordStatusModal = () => {
        this.props.closeForgotPasswordModal();
        this.props.openForgotPasswordStatusModal();
    }

    renderInvalidEmailMessage = () => {
        return (
            <div className="alert alert-danger" role="alert">
                Email does not correspond to an existing NewtonX account. Please try again. Should you not have a NewtonX account, please click "Sign Up".
            </div>
        )
    }

    handleClose = () => {
        this.props.closeForgotPasswordModal();
    }

    render() {
        return (
            <Modal
                className="modal-content bootstrap gothic-font"
                isOpen={this.props.isOpen}
            >
                <div className="modal-header" style={{ justifyContent: "center", borderBottom: "none" }}>
                    <h2
                        className="modal-title"
                        style={{ color: "#004D71", marginBottom: "0", "lineHeight": "1.5", fontFamily: "inherit", fontWeight: "300", marginLeft: "auto", width: "100%" }}
                    >
                        Forgot password?
                    </h2>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="col-12">
                        {this.state.errors.emailInvalid ? this.renderInvalidEmailMessage() : null}
                        <form name="forgotPasswordForm">
                            <div className="form-group">
                                <label className="required" style={{ fontSize: "1rem", fontWeight: "400", marginBottom: ".3125rem}" }}>Email</label>
                                <input
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    maxLength="50"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                />
                                <span style={{ color: 'red' }}>{this.state.touched && this.state.errors.emailNotPresent ? 'Required' : null}</span>
                            </div>
                        </form>
                        <div className="form-row">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-secondary" style={{ width: "100%", height: "38px" }} onClick={this.handleClose}>Cancel</button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <button type="submit" className="btn black-button" style={{ width: "100%", height: "38px" }} onClick={this.handleSubmit}>
                                        Reset Password
                            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = ({ userInfo }) => {
    return {
        resetPasswordStatus: userInfo.resetPasswordStatus
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeForgotPasswordModal: () => dispatch(closeForgotPasswordModal()),
    openForgotPasswordStatusModal: () => dispatch(openForgotPasswordStatusModal()),
    resetPassword: (userInfo) => {
        dispatch(resetPassword(userInfo));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)