export const countries = [
  {
    countryName: 'United States',
    translationName: 'unitedStates',
  },
  {
    countryName: 'France',
    translationName: 'france',
  },
  {
    countryName: 'Canada',
    translationName: 'canada',
  },
  {
    countryName: 'United Kingdom',
    translationName: 'unitedKingdom',
  },
  {
    countryName: 'Australia',
    translationName: 'australia',
  },
  {
    countryName: 'Brazil',
    translationName: 'brazil',
  },
  {
    countryName: 'Germany',
    translationName: 'germany',
  },
  {
    countryName: 'Denmark',
    translationName: 'denmark',
  },
  {
    countryName: 'Sweden',
    translationName: 'sweden',
  },
  {
    countryName: 'Mexico',
    translationName: 'mexico',
  },
  {
    countryName: 'New Zealand',
    translationName: 'newZealand',
  },
  {
    countryName: 'India',
    translationName: 'india',
  },
  {
    countryName: 'Italy',
    translationName: 'italy',
  },
  {
    countryName: 'Japan',
    translationName: 'japan',
  },
  {
    countryName: 'Indonesia',
    translationName: 'indonesia',
  },
  {
    countryName: 'Ukraine',
    translationName: 'ukraine',
  },
  {
    countryName: 'Dubai',
    translationName: 'dubai',
  },
  {
    countryName: 'South Korea',
    translationName: 'southKorea',
  },
  {
    countryName: 'China',
    translationName: 'china',
  },
  {
    countryName: 'Haiti',
    translationName: 'haiti',
  },
  {
    countryName: 'Netherlands',
    translationName: 'netherlands',
  },
  {
    countryName: 'Chile',
    translationName: 'chile',
  },
];

