const SurveyCompletedData = [
  {
    language: 'English',
    languageCode: 'en',
    content: `Thank you for completing our survey.

    You will receive a confirmation of your payment within 2-3 WEEKS.

    If you have any questions, please email survey@newtonx.com with the survey topic in the subject line.

    Looking forward to working with you again.

    Your NewtonX team`,
  },
  {
    language: '中文',
    languageCode: 'ch',
    content: `感谢您完成我们的调查。

    您将在 2-3 周内收到向您付款的确认信息。

    如有任何疑问，请发送电子邮件至 survey@newtonx.com，并在邮件标题中注明调查主题以供参考。

    期待与您再次合作。

    您的NewtonX团队`,
  },
  {
    language: 'Français',
    languageCode: 'fr',
    content: `Merci d’avoir répondu à notre enquête.

    Vous recevrez la confirmation de votre paiement dans un délai de 2 à 3 SEMAINES.

    En cas de questions ou problèmes, veuillez envoyer un e-mail à survey@newtonx.com en indiquant le sujet de l’enquête dans le titre de l’e-mail pour référence.

    Nous nous réjouissons à l’idée de travailler à nouveau avec vous.

    Votre équipe NewtonX`,
  },
  {
    language: 'Deutsche',
    languageCode: 'de',
    content: `Danke, dass Sie unsere Umfrage abgeschlossen haben.

    Sie erhalten innerhalb von 2–3 WOCHEN eine Bestätigung Ihrer Zahlung.

    Falls Sie Fragen haben, schicken Sie bitte eine E-Mail an survey@newtonx.com und geben Sie das Thema der Umfrage in der Betreffzeile der E-Mail als Referenz an.

    Wir freuen uns darauf, wieder mit Ihnen zusammenzuarbeiten.

    Ihr Team von NewtonX`,
  },
  {
    language: 'Indonesia',
    languageCode: 'id',
    content: `Terima kasih telah menyelesaikan survei kami.

    Anda akan menerima konfirmasi pembayaran dalam 2-3 MINGGU.

    Apabila Anda memiliki pertanyaan, kirimkanlah email ke survey@newtonx.com dan sebutkan topik survei dalam judul email sebagai referensi.

    Kami tidak sabar untuk bekerja sama lagi dengan Anda.

    Tim NewtonX Anda`,
  },
  {
    language: '한국어',
    languageCode: 'ko',
    content: `설문을 완료해 주셔서 감사합니다.

    2~3주 이내에 지급 확인서를 발송하여 드릴 것입니다.

    질문이 있으실 경우 survey@newtonx.com으로 이메일을 보내주시기 바라며 참고를 위해 이메일 제목에 설문 주제를 기입해 주십시오.

    다시 만나 뵙기를 기원합니다.

    NewtonX 팀 드림`,
  },
  {
    language: 'Portuguesa',
    languageCode: 'pt',
    content: `Obrigado por participar desta pesquisa.

    Você receberá a confirmação de seu pagamento em 2 a 3 SEMANAS.

    Em caso de dúvidas, envie um e-mail para survey@newtonx.com e informe o tema da pesquisa no assunto da mensagem para fins de referência.

    Esperamos trabalhar com você novamente.

    Sua equipe NewtonX`,
  },
  {
    language: 'Española',
    languageCode: 'es',
    content: `Gracias por completar nuestra encuesta.

    Recibirá una confirmación de su pago dentro de 2 a 3 SEMANAS.

    Si tiene alguna pregunta, envíe un correo electrónico a survey@newtonx.com y mencione el tema de la encuesta en el título del correo electrónico para referencia.

    ¡Esperamos trabajar con usted otra vez!

    Su equipo de NewtonX`,
  },
  {
    language: '日本語',
    languageCode: 'ja',
    content: `アンケートにご協力いただき、ありがとうございます。

    2～3週間以内にお支払いの確認をお送りいたします。

    ご質問がある場合には、survey@newtonx.comまで電子メールにてご連絡ください。その際、参照用にアンケートのトピックを電子メールの件名にご記入ください。

    またの機会にご協力いただければ幸いです。

    NewtonXチーム`,
  },
  {
    language: 'Pусский',
    languageCode: 'ru',
    content: `Благодарим за прохождение опроса.

        Вы получите подтверждение платежа в течение 2–3 НЕДЕЛЬ.

        Если у вас возникли вопросы, обращайтесь по адресу survey@newtonx.com и укажите в теме электронного письма название опроса.

        С нетерпением ждем дальнейшего сотрудничества.

        Команда NewtonX`,
  },
  {
    language: 'TÜRK',
    languageCode: 'tr',
    content: `Anketi tamamladığınız için teşekkür ederiz.

    2-3 HAFTA içinde ödemenizle ilgili bir onay e-postası alacaksınız.

    Herhangi bir sorunuz olması halinde lütfen survey@newtonx.com adresine bir e-posta gönderin ve e-postanın konu kısmında referans olarak anketin konusunu belirtin.

    Tekrar birlikte çalışmak dileğiyle.

    NewtonX ekibi`,
  },
];

export default SurveyCompletedData;
