import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import { closeThankYouSignupModal } from '../../actions/modalActions';
import Modal from 'react-modal'; 

class ThankYouSignupComponent extends Component {

    render() {
        return(
            <Modal className="modal-content thankYou-modal bootstrap gothic-font" isOpen={this.props.isOpen}>
                <div className="modal-header" style={{justifyContent: "center"}}>
                    <h5 className="modal-title">Sign Up</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.closeThankYouSignupModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{textAlign: "center"}}>
                    <div style={{display: "block", paddingTop: "1.875rem"}}>
                        <i className="check-circle fa fa-check-circle fa-5x" style={{paddingBottom: "1.25rem", color: "#7ED321"}}></i>
                        <h4>Thank you for your signup!</h4>
                        <p>Please check your email to confirm your account</p>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeThankYouSignupModal: () => {
        dispatch(closeThankYouSignupModal())
    }
})

export default connect(null, mapDispatchToProps)(ThankYouSignupComponent); 