import BlogImage1 from '../images/blogs/blog-image-1.jpg';
import BlogImage2 from '../images/blogs/blog-image-2.jpg';
import BlogImage3 from '../images/blogs/blog-image-3.jpg';
import BlogImage4 from '../images/blogs/blog-image-4.jpg';
import BlogImage5 from '../images/blogs/blog-image-5.jpg';
import BlogImage6 from '../images/blogs/blog-image-6.jpg';

export default [
  {
    text: 'The Senior Executive’s Guide To AI, Machine Learning, and Deep Learning',
    author: 'Sascha Eder',
    date: 'June 5, 2018',
    backgroundImage: BlogImage1,
    link: 'https://blog.newtonx.com/2018/06/05/senior-executives-guide-ai-machine-learning-deep-learning/',
  },
  {
    text: 'Who Are the AI Police? A look at the Emerging Efforts to Regulate AI, and the Involvement of Tech Giants',
    author: 'Germain Chastel',
    date: 'March 12, 2018',
    backgroundImage: BlogImage2,
    link: 'https://blog.newtonx.com/2018/03/12/ai-policy-regulation/',
  },
  {
    text: 'Doctors Vs. Data: The New Frontier of Machine Learning Driven Medicine',
    author: 'Germain Chastel',
    date: 'March 5, 2018',
    backgroundImage: BlogImage3,
    link: 'https://blog.newtonx.com/2018/03/05/medical-automation/',
  },
  {
    text: 'More Human Than Humans: How Companies Like Netflix Replaced Humans With Predictive Analytics',
    author: 'Germain Chastel',
    date: 'January 26, 2018',
    backgroundImage: BlogImage4,
    link: 'https://blog.newtonx.com/2018/01/26/how-netflix-uses-predictive-analytics/',
  },
  {
    text: 'Smart Shoes and Self Driving Cars: Back to the Future With Edge Computing',
    author: 'Germain Chastel',
    date: 'February 1, 2018',
    backgroundImage: BlogImage5,
    link: 'https://blog.newtonx.com/2018/02/01/future-edge-cloud-computing/',
  },
  {
    text: 'Is Quantum Computing Threatening Global Cybersecurity?',
    author: 'Germain Chastel',
    date: 'January 24, 2018',
    backgroundImage: BlogImage6,
    link: 'https://blog.newtonx.com/2019/01/24/quantum-computing-threatening-global-cybersecurity/',
  },
];
