import React from 'react';

import '../../styles/components/homepage/HowItWorks.scss';

export default (props) => (
  <div className="how-it-works-container" style={{paddingBottom: props.width < 450 ? '40px' : '100px'}}>
    <div className="how-it-works-header">
      <span className="bold-text">How it Works: Expert Surveys</span>
      <span className="normal-text">Deploy Large-Scale Surveys to Experts Across Any Topic, Geography, or Language</span>
    </div>
    <div className="how-it-works-icons">
      <div className="icon-container">
        <img style={{ height: '74px', width: '116px'}} src={require('../../images/homepage/design@3x.png')} alt=""/>
        <span>Design Your Survey </span>
      </div>
      <div className="icon-container" >
        <img style={{ height: '76px', width: '133px'}} src={require('../../images/homepage/respodent_volume@3x.png')} alt=""/>
        <span>Select Respondent Volume</span>
      </div>
      <div className="icon-container">
          <img style={{ width: '88px', height: '101px', marginBottom: '5px'}} src={require('../../images/homepage/real_time_data@3x.png')} alt=""/>
          <span >Access Real-Time Data</span>
        </div>
    </div>
  </div>
);