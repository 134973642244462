import React from 'react'
import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Solution"
      text="NewtonX put two experts on the phone in under three hours. The NewtonX identification 
        engine leverages AI and machine learning to identify niche experts with specific skills 
        and backgrounds — whether or not they’re already in our database. This automated engine 
        rapidly located two experts for the job: A former CEO and industry veteran who had set up his own boutique consulting firm, and an expert who had just  left the company the P.E. firm was investing in."
    />
    <DropdownElement 
      description="Impact"
      text="The P.E. fund successfully submitted a price by the end of the day after 
        speaking with the NewtonX experts. They were so impressed by both the speed and 
        quality at which NewtonX was able to deliver insights that NewtonX has since 
        become their go-to resource for all niche expertise needs."
    />
  </div>
);