export const OPEN_SIGNUP_MODAL = 'OPEN_SIGNUP_MODAL';
export const CLOSE_SIGNUP_MODAL = 'CLOSE_SIGNUP_MODAL';

export const OPEN_SIGNUP_AFTER_REDIRECT_MODAL = 'OPEN_SIGNUP_AFTER_REDIRECT_MODAL';
export const CLOSE_SIGNUP_AFTER_REDIRECT_MODAL = 'CLOSE_SIGNUP_AFTER_REDIRECT_MODAL';

export const OPEN_SIGNIN_MODAL = 'OPEN_SIGNIN_MODAL';
export const CLOSE_SIGNIN_MODAL = 'CLOSE_SIGNIN_MODAL';

export const OPEN_RESEND_LINK_MODAL = 'OPEN_RESEND_LINK_MODAL';
export const CLOSE_RESEND_LINK_MODAL = 'CLOSE_RESEND_LINK_MODAL';

export const OPEN_RESEND_LINK_STATUS_MODAL = 'OPEN_RESEND_LINK_STATUS_MODAL';
export const CLOSE_RESEND_LINK_STATUS_MODAL = 'CLOSE_RESEND_LINK_STATUS_MODAL';

export const OPEN_FORGOT_PASSWORD_MODAL = 'OPEN_FORGOT_PASSWORD_MODAL';
export const CLOSE_FORGOT_PASSWORD_MODAL = 'CLOSE_FORGOT_PASSWORD_MODAL';

export const OPEN_FORGOT_PASSWORD_STATUS_MODAL = 'OPEN_FORGOT_PASSWORD_STATUS_MODAL';
export const CLOSE_FORGOT_PASSWORD_STATUS_MODAL = 'CLOSE_FORGOT_PASSWORD_STATUS_MODAL';

export const OPEN_THANK_YOU_SIGNUP_MODAL = 'OPEN_THANK_YOU_SIGNUP_MODAL';
export const CLOSE_THANK_YOU_SIGNUP_MODAL = 'CLOSE_THANK_YOU_SIGNUP_MODAL';

export const RESET_PASSWORD_USER_EMAIL = 'RESET_PASSWORD_USER_EMAIL';
export const OPEN_RESET_PASSWORD_CONFIRMATION_MODAL = 'OPEN_RESET_PASSWORD_CONFIRMATION_MODAL';
export const CLOSE_RESET_PASSWORD_CONFIRMATION_MODAL = 'CLOSE_RESET_PASSWORD_CONFIRMATION_MODAL';

export const RESEND_VERIFICATION_USER_ID = 'RESEND_VERIFICATION_USER_ID';
export const OPEN_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL = 'OPEN_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL';
export const CLOSE_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL = 'CLOSE_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL';
