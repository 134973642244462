import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import SignupService from '../../utils/signupService';
import SignupAfterRedirectModalForm from '../signup/SignupAfterRedirectModalForm';
import { withToastManager } from 'react-toast-notifications';
import {setSignupUser, fetchPlatformExpertByGuid, postUser} from '../../actions/signupActions';
import { compose } from 'redux';
import {
    openThankYouSignupModal
} from '../../actions/modalActions';
import {FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE, POST_USER_FAILURE, POST_USER_SUCCESS} from '../../action-types/signup';

/**
 * Experts who click on a referral signup link provided by Ops arrive here.
 * This component automatically retrieves the expert's information via the core platform,
 * avoiding the need for the expert to go through LinkedIn OAuth themselves. 
 * The expert will be directed to the SignupAfterRedirectModal.
 */
class ReferralLinkSignup extends Component {

    constructor() {
        super();
        this.signupService = new SignupService();
        this.state = {
            submitDisabled: false,
            alerts: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchPlatformExpertByGuidStatus !== this.props.fetchPlatformExpertByGuidStatus &&
            this.props.fetchPlatformExpertByGuidStatus === FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE) {

            this.props.toastManager.add('Please click Sign Up and sign in via your LinkedIn account.', {
                appearance: 'error'
            });
            this.props.history.push('/');
        }
        if (this.props.postUserStatus === POST_USER_FAILURE &&
            prevProps.postUserStatus !== this.props.postUserStatus) {
            this.setState({
                submitDisabled: false,
                alerts: 'Unable to create account. If you already have a NewtonX account, please click "Login In" instead of "Sign Up". '
            });
        }
        if (this.props.postUserStatus === POST_USER_SUCCESS) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {
        const expertGuid = this.getReferralGuidFromURL();
        this.props.fetchPlatformExpertByGuid(expertGuid);
    }

    getReferralGuidFromURL = () => {
        let params = queryString.parse(this.props.location.search);
        return params['ref'];
    }

    handleFormSubmit = (email: string, password: string) => {
        this.setState({
            submitDisabled: true,
            alerts: null
        });
        let userInfo = {
            email: email,
            password: password,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            linkedInId: this.props.linkedInId,
        };

        this.props.postUser(userInfo);
    }

    render() {
        return <SignupAfterRedirectModalForm
            alerts={this.state.alerts}
            email={this.props.email}
            onFormSubmit={this.handleFormSubmit}
            submitDisabled={this.state.submitDisabled}
        />;
    }
}

const mapStateToProps = ({ signup }) => ({
    fetchPlatformExpertByGuidStatus: signup.fetchPlatformExpertByGuidStatus,
    email: signup.signupUser.email,
    firstName: signup.signupUser.firstName,
    lastName: signup.signupUser.lastName,
    linkedInId: signup.signupUser.linkedInId,
    postUserStatus: signup.postUserStatus
});

const mapDispatchToProps = (dispatch) => ({
    setSignupUser: (userInfo) => {
        dispatch(setSignupUser(userInfo));
    },
    fetchPlatformExpertByGuid: (guid) => {
        dispatch(fetchPlatformExpertByGuid(guid));
    },
    openThankYouSignupModal: () => {
        dispatch(openThankYouSignupModal())
    },
    postUser: (userInfo) => {
        dispatch(postUser(userInfo));
    }
})

export default compose(
    withToastManager,
    connect(mapStateToProps, mapDispatchToProps)
)(ReferralLinkSignup);
