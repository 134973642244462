import React, { PureComponent } from 'react';
import Signup from '../../containers/login/Signup';

import '../../styles/components/homepage/HowItWorks.scss';

export default class HowItWorks extends PureComponent {
  state = {
    signUpModalOpen: false,
    signInModalOpen: false,
  }

  handleSignupClick = (showClientSignup) => {
    this.setState({
      signUpModalOpen: true,
    });
  }

  handlClose = () => {
    this.setState({ signUpModalOpen: false });
  }

  render() {
    const { signUpModalOpen } = this.state;
    return (
      <div className="how-it-works-container">
        <div className="how-it-works-header">
          <span className="bold-text">How it Works - Expert Calls</span>
          <span className="normal-text">Find Subject Matter Experts Across Any Topic, Geography, or Language</span>
        </div>
        <div className="how-it-works-icons">
          <div className="icon-container">
            <img style={{height: '90px'}} src={require('../../images/homepage/define_need_icon@3x.png')} alt=""/>
            <span>Define Your Need</span>
          </div>
          <div className="icon-container">
            <img style={{height: '90px'}} src={require('../../images/homepage/choose_expert@3x.png')} alt=""/>
            <span>Choose Your Experts</span>
          </div>
          <div className="icon-container">
            <img  src={require('../../images/homepage/schedule@3x.png')} alt=""/>
            <span style={{ marginTop: '10px'}}>Schedule Seamlessly</span>
          </div>
        </div>
        <div className="button-container">
          <button
            className="get-started-button"
            onClick={this.handleSignupClick}
          >
            Get Started Now
          </button>
        </div>

        <img className="full-width-picture" src={require('../../images/homepage/luca-bravo.png')} alt=""/>

        <div className="how-it-works-header">
          <span className="bold-text">How it Works - Expert Surveys</span>
          <span className="normal-text">Deploy Large-Scale Surveys to Experts Across Any Topic, Geography, or Language</span>
        </div>
        <div className="how-it-works-icons">
          <div className="icon-container">
            <img style={{ width: '116px', height: '74px'}} src={require('../../images/homepage/design@3x.png')} alt=""/>
            <span>Design Your Survey </span>
          </div>
          <div className="icon-container">
            <img style={{ width: '133px', height: '74px' }} src={require('../../images/homepage/respodent_volume@3x.png')} alt=""/>
            <span>Select Respondent Volume</span>
          </div>
          <div className="icon-container">
            <img style={{ width: '88px', height: '101px', marginBottom: '5px'}} src={require('../../images/homepage/real_time_data@3x.png')} alt=""/>
            <span >Access Real-Time Data</span>
          </div>
        </div>
        <div className="button-container">
          <button
            className="get-started-button"
            onClick={this.handleSignupClick}
          >
            Get Started Now
          </button>
        </div>
        <Signup
          showClientSignup={true}
          isOpen={signUpModalOpen}
          onClose={this.handlClose}
        />
      </div>
    )
  }
}