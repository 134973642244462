import React from 'react'
import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Solution"
      text="Using our proprietary AI-powered algorithm, NewtonX automated the 
        selection of experts, their onboarding process, and the scheduling 
        of their surveys. Because of this, we were able to deliver the final 
        survey results at a much lower cost to the SaaS company. The final survey 
        included hundreds of data points across four industries, covering current 
        and future needs, budget, decision-making process, buying preferences, and more."
    />
    <DropdownElement 
      description="Impact"
      text="NewtonX not only connected the SaaS company with a cohort of experts, 
        but also advised the client on how to design the survey for the best possible 
        results. Through a Qual-Quant-Qual approach NewtonX gave a broad overview of 
        customer sentiment, as well as a granular look 
        into the motivations of select sample experts."
    />
  </div>
);