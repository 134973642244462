import React from 'react';

import '../../styles/components/homepage/TrustedBy.scss';

export default (props) => (
  <div className="trusted-by-box">
    <div className="trusted-by-content">
      <div className="trusted-by-text">
        <span>Trusted by -</span>
      </div>
      <div className="trusted-by-brands">
        {
          props.width < 450 ? 
              <img src={require('../../images/homepage/mobile_brands.png')} alt=""/>
            :
              <img src={require('../../images/homepage/Logos.png')} alt=""/>
        }
       
      </div>
    </div>
  </div>
);