import React, { Component, Fragment } from 'react'; 
import Field from '../common/Field'; 
import { isValidIBAN, isValidBIC } from 'ibantools'; 

// form built manually instead of using Formik due to nested form component 
class InternationalPaymentEditForm extends Component  {

    constructor() {
        super(); 
        this.state = {
            ibanSubFormValues: {
                intlIban: '',
                intlSwiftBic: '',
                intlBankAddress: '',
                intlBankCity: '',
                intlBankState: '',
                intlBankPostalCode: '',
                intlHasIban: ''
            },
            values: {
                intlUserAddress: '',
                intlUserCity: '',
                intlUserState: '',
                intlUserPostalCode: '',
                intlNotes: '',
            },
            ibanSubFormTouched: {
                intlIban: false,
                intlSwiftBic: false,
                intlBankCity: false,
                intlBankState: false,
                intlBankPostalCode: false,
                intlBankAddress: false,
            },
            touched: {
                intlUserAddress: false,
                intlUserCity: false,
                intlUserState: false,
                intlUserPostalCode: false,
                intlNotes: false,
            },
            ibanSubFormErrors: {
                intlIban: '',
                intlSwiftBic: '',
                intlBankAddress: '',
                intlBankCity: '',
                intlBankState: '',
                intlBankPostalCode: ''
            },
            errors: {
                intlUserAddress: '',
                intlUserCity: '',
                intlUserState: '',
                intlUserPostalCode: '',
                intlNotes: ''
            },
            ibanChecked: false,
            formInvalid: true,
            alert: '',
            editingIban: false
        }
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            ibanSubFormValues: {
                ...this.props.currentValues.ibanSubFormValues,
            },
            values: {
                ...this.props.currentValues.values,
            },
            ibanChecked: this.props.currentValues.ibanChecked
        })
    }
    
    onChange = (event) => {
        event.persist(); 
        //  we revalidate the form after every value change  
        this.setState({
            values: {
                ...this.state.values,
                [event.target.name]: event.target.value
            }
        }, () => this.handleFormValidation(event));
    }

     /**
     * since the IBAN form is not always open, we have a 
     * separate validation for when it's open
     */
    onIbanSubFormChange = (event) => {
        event.persist(); 
        this.setState({
            ibanSubFormValues: {
                ...this.state.ibanSubFormValues,
                [event.target.name]: event.target.value
            }
        }, () => this.handleFormValidation(event));
    }

    onBlur = (event) => {
        this.setState({
            touched: {
                ...this.state.touched,
                [event.target.name] : true
            }
        });
    }

    onIbanSubFormBlur = (event) => {
        this.setState({
            ibanSubFormTouched: {
                ...this.state.ibanSubFormTouched,
                [event.target.name]: true
            }
        });
    }

    // display or hide IBAN form based on user's selection
    handleIbanCheckboxChange = (event) => {
        if (this.state.ibanSubFormValues.intlHasIban === true) {
            let blankedIbanSubFormValues = {
                intlIban: '',
                intlSwiftBic: '',
                intlBankAddress: '',
                intlBankCity: '',
                intlBankState: '',
                intlBankPostalCode: ''
            };

            this.setState({
                ibanChecked: event.target.checked,
                ibanSubFormValues: {
                    ...blankedIbanSubFormValues,
                    intlHasIban: !this.state.ibanSubFormValues.intlHasIban
                },
                ibanSubFormErrors: {
                    ...this.state.ibanSubFormErrors,
                }
            }, () => this.handleFormValidation())
        } else {
            this.setState({
                ibanChecked: event.target.checked,
                ibanSubFormValues: {
                    ...this.state.ibanSubFormValues,
                    intlHasIban: !this.state.ibanSubFormValues.intlHasIban
                },
                ibanSubFormErrors: {
                    ...this.state.ibanSubFormErrors,
                }
            }, () => this.handleFormValidation())
        }
    }

    handleIbanEditOrResetClick = (event) => {
        if (this.state.editingIban === false) {
            this.setState({
                editingIban: true,
                ibanSubFormValues: {
                    ...this.state.ibanSubFormValues,
                    intlIban: ''
                },
                ibanSubFormErrors: {
                    ...this.state.ibanSubFormErrors,
                    intlIban: 'IBAN not valid'
                }
            });
        } else {
            this.setState({
                editingIban: false, 
                ibanSubFormValues: {
                    ...this.state.ibanSubFormValues,
                    intlIban: this.props.currentValues.ibanSubFormValues.intlIban
                }
            });
        }
    }

    handleFormValidation = (event) => {
        let errors = this.verifyAllFieldsHaveData(event); 
        this.checkIbanAndBIC(errors)
        this.setState({
            errors: {
                ...errors.errorsStateClone,
            },
            ibanSubFormErrors: {
                ...errors.ibanErrorsStateClone
            }
        }, this.disableFormIfErrorsExist);
    }
    
    // IBAN and BIC are international standards and have a specific format 
    checkIbanAndBIC = (errors) => {
        if (this.validateBIC(this.state.ibanSubFormValues.intlSwiftBic) === false) {
            errors.ibanErrorsStateClone.intlSwiftBic = 'Business Identifier Code not valid.';
        } else {
            errors.ibanErrorsStateClone.intlSwiftBic = '';
        }
        if (this.validateIBAN(this.state.ibanSubFormValues.intlIban) === false) {
            errors.ibanErrorsStateClone.intlIban = 'IBAN not valid.'; 
        } else {
            errors.ibanErrorsStateClone.itnlIban = '';
        }
    }

    disableFormIfErrorsExist = () => {
        if (this.checkIfFormErrorsPresent()) {
            this.setState({
                formInvalid: true
            });
        } else {
            this.setState({
                formInvalid: false
            })
        }
    }

    verifyAllFieldsHaveData = (event) => {
        // first we check the IBAN sub-form values
        let subFormKeys = Object.keys(this.state.ibanSubFormValues); 
        let ibanErrorsStateClone = { ...this.state.ibanSubFormErrors };
        subFormKeys.forEach( key => {
            if (!this.state.ibanSubFormValues[key] || this.state.ibanSubFormValues[key].length === 0) {
                ibanErrorsStateClone[key] = `Required`;
            } else {
                ibanErrorsStateClone[key] = '';
            }
        });

        // then we check the values which are always required
        let formKeys = Object.keys(this.state.values); 
        let errorsStateClone = {...this.state.errors}; 
        formKeys.forEach( key => {
            if (this.state.values[key].length === 0) {
                errorsStateClone[key] = `Required`;
            } else {
                errorsStateClone[key] = '';
            }
        });
        return {
            ibanErrorsStateClone,
            errorsStateClone
        }
    }

    checkIfFormErrorsPresent = () => {
        let errorKeys = Object.keys(this.state.errors);
        let errorsExist = false; 
        errorKeys.forEach( key => {
            if (this.state.errors[key].length > 0) {
                errorsExist = true; 
            }
        }); 
        if (this.state.ibanChecked) {
            let ibanErrorKeys = Object.keys(this.state.ibanSubFormErrors); 
            ibanErrorKeys.forEach( key => {
                if (this.state.ibanSubFormErrors[key].length > 0) {
                    errorsExist = true; 
                }
            });
        }
        return errorsExist;
    }

    handleSubmit = () => {
        let formValues = {
            ...this.state.values,
            ...this.state.ibanSubFormValues
        };
        this.props.handleSubmit(formValues); 
    }

    validateBIC(bic: string): boolean {
        return isValidBIC(bic.replace(/\s+/g, ''));
      }
    
    validateIBAN(iban: string): boolean {
        return isValidIBAN(iban.replace(/\s+/g, ''));
    }

    renderIbanEditButton = () => {
        return(
            <span className="input-group-btn">
                <button className="btn black-button" type="button" onClick={this.handleIbanEditOrResetClick}>
                    {this.state.editingIban ? "Reset" : "Edit" }
                </button>
            </span>
        )
    }
    render() {
        let renderIbanSubForm = () => {
            return(
                <Fragment>
                    <Field 
                        value={this.state.ibanSubFormValues.intlIban} 
                        onChange={this.onIbanSubFormChange} 
                        label="IBAN" 
                        name="intlIban" 
                        type="text"
                        onBlur={this.onIbanSubFormBlur} 
                        touched={this.state.ibanSubFormTouched.intlIban} 
                        errors={this.state.ibanSubFormErrors.intlIban}
                        disabled={!this.state.editingIban && this.props.currentValues.ibanSubFormValues.intlIban}
                        renderEditButton={this.state.ibanChecked && this.props.currentValues.ibanSubFormValues.intlIban ? this.renderIbanEditButton : null }
                    />
                    <Field 
                        value={this.state.ibanSubFormValues.intlSwiftBic} 
                        onChange={this.onIbanSubFormChange} 
                        label="SWIFT/BIC" 
                        name="intlSwiftBic" 
                        type="text" 
                        onBlur={this.onIbanSubFormBlur} 
                        touched={this.state.ibanSubFormTouched.intlSwiftBic} 
                        errors={this.state.ibanSubFormErrors.intlSwiftBic}
                    />
                    <h4>Bank Information</h4>
                    <Field 
                        value={this.state.ibanSubFormValues.intlBankAddress} 
                        onChange={this.onIbanSubFormChange} 
                        label="Street Address" 
                        name="intlBankAddress" 
                        type="text" 
                        onBlur={this.onIbanSubFormBlur} 
                        touched={this.state.ibanSubFormTouched.intlBankAddress} 
                        errors={this.state.ibanSubFormErrors.intlBankAddress}
                    />
                    <Field 
                        value={this.state.ibanSubFormValues.intlBankCity} 
                        onChange={this.onIbanSubFormChange} 
                        label="City" 
                        name="intlBankCity" 
                        type="text" 
                        onBlur={this.onIbanSubFormBlur} 
                        touched={this.state.ibanSubFormTouched.intlBankCity} 
                        errors={this.state.ibanSubFormErrors.intlBankCity}
                    />
                    <Field 
                        value={this.state.ibanSubFormValues.intlBankState} 
                        onChange={this.onIbanSubFormChange} 
                        label="State" 
                        name="intlBankState" 
                        type="text" 
                        onBlur={this.onIbanSubFormBlur} 
                        touched={this.state.ibanSubFormTouched.intlBankState} 
                        errors={this.state.ibanSubFormErrors.intlBankState}
                    />
                    <Field 
                        value={this.state.ibanSubFormValues.intlBankPostalCode} 
                        onChange={this.onIbanSubFormChange} 
                        label="Postal Code" 
                        name="intlBankPostalCode" 
                        type="text" 
                        onBlur={this.onIbanSubFormBlur} 
                        touched={this.state.ibanSubFormTouched.intlBankPostalCode} 
                        errors={this.state.ibanSubFormErrors.intlBankPostalCode}
                    />
                </Fragment>
            )
        }
        return(
            <form className="bootstrap gothic-font">
                {this.state.alert}
                <div className="form-check">
                    <input type="checkbox" name="expertHasIban" className="form-check-input" onChange={this.handleIbanCheckboxChange} checked={this.state.ibanChecked}/>
                    <label className="form-check-label" name="expertHasIban">I have an IBAN</label> 
                </div>
                { this.state.ibanChecked ? renderIbanSubForm() : null }
                <h4 style={{paddingTop: '.5em'}}>Your Information</h4>
                <Field 
                    value={this.state.values.intlUserCity} 
                    onChange={this.onChange} 
                    onBlur={this.onBlur} 
                    touched={this.state.touched.intlUserCity} 
                    label="City" 
                    name="intlUserCity"
                    type="text"
                    errors={this.state.errors.intlUserCity}
                />
                <Field 
                    value={this.state.values.intlUserAddress} 
                    onChange={this.onChange} 
                    onBlur={this.onBlur} 
                    touched={this.state.touched.intlUserAddress}
                    label="Street Address" 
                    name="intlUserAddress" 
                    type="text" 
                    errors={this.state.errors.intlUserAddress}
                />
                <Field 
                    value={this.state.values.intlUserState} 
                    onChange={this.onChange} 
                    onBlur={this.onBlur} 
                    touched={this.state.touched.intlUserState} 
                    label="State" 
                    name="intlUserState" 
                    type="text" 
                    errors={this.state.errors.intlUserState}
                />
                <Field 
                    value={this.state.values.intlUserPostalCode} 
                    onChange={this.onChange} 
                    onBlur={this.onBlur} 
                    touched={this.state.touched.intlUserPostalCode} 
                    label="Postal Code" 
                    name="intlUserPostalCode" 
                    type="text" 
                    errors={this.state.errors.intlUserPostalCode}
                />
                <Field 
                    value={this.state.values.intlNotes} 
                    onChange={this.onChange} 
                    maxLength="255" 
                    onBlur={this.onBlur} 
                    touched={this.state.touched.intlNotes} 
                    label="Payment Instructions" 
                    name="intlNotes" 
                    type="text" 
                    errors={this.state.errors.intlNotes}
                />
                <button type="button" className="btn black-button" onClick={this.handleSubmit} disabled={this.state.formInvalid}>Submit</button>
            </form>
        )
    }
}
export default InternationalPaymentEditForm; 