import React from 'react';

import '../../styles/components/services/HowWeCanHelpContainer.scss';

export default () => (
  <div className="how-we-can-help-container">
    <div className="help-content">
      <div className="help-header">
        <span>
          Discover how we can help you and your company
        </span>
      </div>
      <div className="links-box">
        <span className="link">Consulting</span>
        <span className="link">Private Equity</span>
        <span className="link">Enterprise</span>
      </div>
    <div className="pictures-box">
      <img src={require('../../images/services/help_figure.png')} alt=""/>
      <img className="second-image" src={require('../../images/services/red_line.png')} alt=""/>
    </div>
    </div>
  </div>
);