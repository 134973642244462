import React from 'react';
import Slider from '../common/Slider';

import '../../styles/components/homepage/sliderContentStyles.scss';

const SLIDES = [
  {
    quote: '“ I have been consulting with NewtonX for the past year, and have formed great relationships with two top companies as a result. ”',
    title: 'Chief Technology Officer at Fortune 100 Company',
    firm: '',
  },
  {
    quote: '“ The NewtonX sign-up process was truly seamless, and I completed it in a matter of minutes. ”',
    title: 'Customer Service Executive',
    firm: '',
  },
  {
    quote: '“ The NewtonX operations team was very helpful and payment was remitted quickly and easily. ”',
    title: 'Blockchain Technology Engineer',
    firm: '',
  },
  {
    quote: '“ Great team and communication. ”',
    title: 'CIO at Global CPG Company',
    firm: '',
  },
];

export default () => {
  const settings = {
    adaptiveHeight: true
  }
  return (
    <div className="client-opinions" style={{ paddingBottom: '50px', paddingTop: '50px' }}>
      <span className="client-header">What Our Experts Say -</span>
      <Slider settings={settings} paddingBottom="25%">
        {
          SLIDES.map(({
            quote, title, firm,
          }) => (
            <div className="slider-text" key={quote}>
              <p>{quote}</p>
              <span>{title} <br /> {firm}</span>
            </div>
          ))
        }
      </Slider>
    </div>
  )
  }