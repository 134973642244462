import React from 'react';

import '../../styles/components/aboutUs/GeneralInfo.scss';

export default () => (
  <div className="general-info-container">
    <div className="general-info-content">
      <div className="header-box">
        <span>NewtonX is the world’s most sophisticated <br /> expert search engine -</span>
      </div>
      <div className="main-content">
        <div className="title-box">
          <span>About Us</span>
        </div>
        <div className="text-box">
          <p>
          NewtonX connects clients with experts using a unique set of proprietary automation tools. 
          Where traditional expert networks rely on a finite number of pre-onboarded experts, NewtonX 
          leverages a proprietary search algorithm to identify and onboard the best possible experts in 
          the world for each specific request - whether or not those experts are already onboarded onto the NewtonX platform.
          </p>
        </div>
      </div>
    </div>
  </div>
);