import React, { Component } from 'react';
import { ErrorMessage, withFormik } from 'formik';
import i18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { countries } from '../../data/countries';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { faPaypal, faAmazon } from '@fortawesome/free-brands-svg-icons';
import '../../styles/components/payments/payments.css';

const paymentTypes = {
  BANKTRANSFER: 'Bank Transfer',
  PAYPAL: 'Paypal',
  AMAZON: 'Amazon',
};

const AMAZON_COUNTRIES= ['france', 'canada', 'united kingdom', 'australia',
  'germany', 'denmark', 'sweden', 'netherlands', 'italy', 'united states'];

function validateEmailAddress(emailAddress) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailAddress);
}

class SurveyPaymentForm extends Component {
  state = {
    hidePaymentEmail: false,
    paymentEmail: '',
    confirmPaymentEmail: '',
    contactEmail: '',
    confirmContactEmail: '',
  };

  handleDropdownChange = event => {
    this.props.setFieldValue('country', event.target.value, true);
    this.props.onCountryChange(event.target.value);
  };

  sortCountriesByName = (countryA, countryB) => {
    if (countryA.countryName < countryB.countryName) {
      return -1;
    }
    if (countryA.countryName > countryB.countryName) {
      return 1;
    }
    return 0;
  };

  render() {
    const { handleChange, handleBlur, handleSubmit, values } = this.props;

    const sortedCountries = countries
      .sort(this.sortCountriesByName)
      .map(country => (
        <option value={country.countryName} key={country.countryName}>
          {i18n.t(country.translationName)}
        </option>
      ));

    // will need a new way to do this in the future. NNC-266
    const country = values.country ? values.country.toLowerCase() : null;
    const showAmazonPaymentType = AMAZON_COUNTRIES.includes(country) ?
        (<div className='form-check'>
          <input
            className='form-check-input'
            id='amazonPayment'
            disabled={this.props.amazonPaymentDisabled}
            type='radio'
            name='paymentType'
            value='Amazon'
            onChange={handleChange}
            checked={
              values.paymentType &&
              values.paymentType.toUpperCase() === 'AMAZON'
            }
          />
          <label htmlFor='amazonPayment' className='form-check-label'>
            {i18n.t('amazon')} <FontAwesomeIcon icon={faAmazon} />
          </label>
        </div>) : '';

    const isCountrySelected = values.country ? (
      <div>
        <label className='required'>{i18n.t('paymentType')}</label>
        { showAmazonPaymentType }
        <div className='form-check'>
          <input
            className='form-check-input'
            id='bankTransferPayment'
            type='radio'
            name='paymentType'
            value='Bank Transfer'
            onChange={handleChange}
            checked={
              values.paymentType &&
              values.paymentType.toUpperCase() === 'BANK TRANSFER'
            }
          />
          <label htmlFor='bankTransferPayment' className='form-check-label'>
            {i18n.t('bankTransfer')} <FontAwesomeIcon icon={faExchangeAlt} />
          </label>
        </div>
        <div className='form-check'>
          <input
            className='form-check-input'
            id='paypalPayment'
            type='radio'
            name='paymentType'
            value='Paypal'
            onChange={handleChange}
            checked={
              values.paymentType &&
              values.paymentType.toUpperCase() === 'PAYPAL'
            }
          />
          <label htmlFor='paypalPayment' className='form-check-label'>
            {i18n.t('paypal')} <FontAwesomeIcon icon={faPaypal} />
          </label>
        </div>
      </div>
    ) : null;

    const isDifferentEmail =
      values.paymentType &&
      (!values.paymentEmailIsHidden &&
        values.paymentType.toUpperCase() !== 'BANK TRANSFER') ? (
        <div>
          <div
            className='form-group'
            disabled={
              this.state.hidePaymentEmail ||
              values.paymentType.toUpperCase() === 'BANK TRANSFER'
            }
          >
            <label className='required'>
              {values.paymentType &&
              values.paymentType.toUpperCase() === 'AMAZON'
                ? i18n.t('amazonEmailAddress')
                : i18n.t('paypalEmailAddress')}
            </label>
            <input
              type='email'
              name='paymentEmail'
              onChange={handleChange}
              onBlur={handleBlur}
              values={values.paymentEmail}
              className='form-control'
            />
          </div>
          <ErrorMessage
            name='paymentEmail'
            render={msg => <p style={{ color: 'red' }}>{msg}</p>}
          />
          <div
            className='form-group'
            disabled={
              this.state.hidePaymentEmail ||
              values.paymentType.toUpperCase() === 'BANK TRANSFER'
            }
          >
            <label className='required'>
              {values.paymentType &&
              values.paymentType.toUpperCase() === 'AMAZON'
                ? i18n.t('confirmAmazonEmailAddress')
                : i18n.t('confirmPaypalEmailAddress')}
            </label>
            <input
              type='email'
              name='confirmPaymentEmail'
              onChange={handleChange}
              onBlur={handleBlur}
              values={values.confirmPaymentEmail}
              className='form-control'
            />
          </div>
          <ErrorMessage
            name='confirmPaymentEmail'
            render={msg => <p style={{ color: 'red' }}>{msg}</p>}
          />
        </div>
      ) : null;

    const isPaymentTypeSelected = values.paymentType ? (
      <div>
        {values.paymentType &&
        values.paymentType.toUpperCase() === 'BANK TRANSFER' ? (
          <p style={{ color: 'blue' }}>{i18n.t('bankTransferMessage')}</p>
        ) : null}
        <div className='row' style={{ marginTop: '10px' }}>
          <div className='form-group col-md-6'>
            <label className='required'>{i18n.t('firstName')}</label>
            <input
              type='firstName'
              name='firstName'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              className='form-control col-md-6'
              style={{ maxWidth: '100%' }}
            />
            <ErrorMessage
              name='firstName'
              render={msg => <p style={{ color: 'red' }}>{msg}</p>}
            />
          </div>
          <div className='form-group col-md-6'>
            <label className='required'>{i18n.t('lastName')}</label>
            <input
              type='lastName'
              name='lastName'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              className='form-control col-md-6'
              style={{ maxWidth: '100%' }}
            />
            <ErrorMessage
              name='lastName'
              render={msg => <p style={{ color: 'red' }}>{msg}</p>}
            />
          </div>
        </div>
        <div className='form-group'>
          <label className='required'>{i18n.t('contactEmail')}</label>
          <input
            type='email'
            name='contactEmail'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.contactEmail}
            className='form-control'
          />
        </div>
        <ErrorMessage
          name='contactEmail'
          render={msg => <p style={{ color: 'red' }}>{msg}</p>}
        />
        <div className='form-group'>
          <label className='required'>{i18n.t('confirmContactEmail')}</label>
          <input
            type='email'
            name='confirmContactEmail'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmContactEmail}
            className='form-control'
          />
        </div>
        <ErrorMessage
          name='confirmContactEmail'
          render={msg => <p style={{ color: 'red' }}>{msg}</p>}
        />
        {values.paymentType &&
        values.paymentType.toUpperCase() !== 'BANK TRANSFER' ? (
          <div className='form-check'>
            <input
              id='paymentEmailIsHidden'
              type='checkbox'
              name='paymentEmailIsHidden'
              className='form-check-input'
              onChange={handleChange}
            />
            <label className='form-check-label' htmlFor='paymentEmailIsHidden'>
              {i18n.t('paymentEmailCheckbox')}
            </label>
          </div>
        ) : null}
        {isDifferentEmail}
      </div>
    ) : null;

    return (
      <div style={{ marginTop: '10px' }}>
        <form className='form' onSubmit={handleSubmit}>
          <div className='form-group'>
            {i18n.t(
              'Please select the country corresponding to your payment information',
            )}
            :<br />
            <select
              id='country-select'
              value={values.country}
              name='countrySelect'
              onChange={this.handleDropdownChange}
            >
              <option value=''>Please select</option>
              {sortedCountries}
            </select>
          </div>
          <ErrorMessage
            name='countrySelect'
            render={msg => <p style={{ color: 'red' }}>{msg}</p>}
          />
          {isCountrySelected}
          {isPaymentTypeSelected}
          <button
            type='submit'
            className='black-button'
            style={{ float: 'right', marginBottom: '10px' }}
            hidden={!values.paymentType}
          >
            {i18n.t('Submit')}
          </button>
        </form>
      </div>
    );
  }
}

export default withFormik({
  mapPropsToValues(prop) {
    return {
      firstName: prop.values.firstName,
      lastName: prop.values.lastName,
      country: prop.values.paymentCountry,
      paymentEmail: prop.values.paymentEmail,
      confirmPaymentEmail: prop.values.paymentEmail,
      contactEmail: prop.values.contactEmail,
      confirmContactEmail: prop.values.contactEmail,
      paymentType: prop.values.paymentMethod,
      paymentEmailIsHidden: !!prop.values.paymentEmailIsHidden,
      language: 'English',
      languageCode: 'en',
    };
  },

  enableReinitialize: true,

  validateEmailAddress: emailAddress => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailAddress);
  },

  validate: values => {
    let errors = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    if (!values.country) {
      errors.country = 'Required';
    }

    if (!validateEmailAddress(values.contactEmail)) {
      errors.contactEmail = 'Invalid email address!';
    }

    if (!values.contactEmail) {
      errors.contactEmail = 'Required';
    }

    if (values.contactEmail !== values.confirmContactEmail) {
      errors.confirmContactEmail =
        'Contact email and confirm contact email must match!';
    }

    if (!validateEmailAddress(values.confirmContactEmail)) {
      errors.confirmContactEmail = 'Invalid email address!';
    }

    if (!values.confirmContactEmail) {
      errors.confirmContactEmail = 'Required';
    }

    if (
      !validateEmailAddress(values.paymentEmail) &&
      values.paymentType !== paymentTypes.BANKTRANSFER
    ) {
      errors.paymentEmail = 'Invalid email address!';
    }

    if (
      !values.paymentEmail &&
      values.paymentType !== paymentTypes.BANKTRANSFER
    ) {
      errors.paymentEmail = 'Required';
    }

    if (
      !values.paymentEmailIsHidden &&
      !validateEmailAddress(values.confirmPaymentEmail) &&
      values.paymentType !== paymentTypes.BANKTRANSFER
    ) {
      errors.confirmPaymentEmail = 'Invalid email address!';
    }

    if (
      !values.paymentEmailIsHidden &&
      values.paymentType !== paymentTypes.BANKTRANSFER
    ) {
      errors.confirmPaymentEmail = 'Required';
    }

    if (
      !values.paymentEmailIsHidden &&
      values.paymentEmail !== values.confirmPaymentEmail
    ) {
      errors.confirmPaymentEmail =
        'Payment email and confirm contact email must match!';
    }

    if (values.paymentEmailIsHidden) {
      // we delete these errors so that form validates correctly
      delete errors.paymentEmail;
      delete errors.confirmPaymentEmail;
    }

    return errors;
  },

  handleSubmit: (values, { resetForm, props: { onSubmit }, setFieldValue }) => {
    onSubmit(values);
  },
})(SurveyPaymentForm);
