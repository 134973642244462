import React from 'react'

import '../../styles/components/aboutUs/GeneralInfo.scss';

export default (props) => (
  <div className="general-info-container">
    <div style={{ height: '470px'}} className="general-info-content">
      <div className="header-box">
        <span>Share your Insights - </span>
      </div>
      <div className="main-content">
        <div className="title-box">
          <span>Expert Overview</span>
          <img style={{ height: props.width > 1242 ? '420px' : 'unset'}} src={require('../../images/howWeWork/crossing_photo.png')} alt=""/>
        </div>
        <div className="text-box">
          <p style={{ marginTop: '0px' }}>
            NewtonX connects subject matter experts with the world’s leading 
            professionals: investors, consultants and business and technology executives. 
            The NewtonX proprietary technology matches you only with the clients that have 
            a need for your particular expertise.
          </p>
        </div>
      </div>
    </div>
  </div>
);