import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import Signup from '../../containers/login/Signup';
import Signin from '../../containers/login/Signin';

import '../../styles/common/MobileMenu.scss';
import ContactUs from '../../containers/contactUs/contactUs';
import ResendLink from '../resendLink/ResendLink';
import ResendLinkStatus from '../resendLink/ResendLinkStatus';
import ForgotPassword from '../forgotPassword/ForgotPassword';
import { clearUserInformation } from '../../actions/signinActions';
import { openSignupModal, closeSignupAfterRedirectModal, openSigninModal, closeSigninModal } from '../../actions/modalActions';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ForgotPasswordStatus from '../forgotPassword/ForgotPasswordStatus';
import SignupAfterRedirectModal from '../../containers/signup/SignupAfterRedirectModal';
import ThankYouSignupModal from '../../containers/signup/ThankYouSignupModal';
import authService from '../../utils/authService';
import contactService from '../../utils/contactService';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';

export class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signUpModalOpen: false,
      signInModalOpen: false,
      width: null,
    }
  }

  componentDidMount() {
    document.body.style.overflow = "hidden"  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  handleScroll = (e) => {
    e.preventDefault();
  }
  // onMobileClick = () => {
  //   this.props.onMobileClose();
  // }

  // componentDidMount = () => {
  //   window.addEventListener("click", this.onMobileClick);
  // }

  // componentWillUnmount = () => {
  //   window.removeEventListener("click", this.onMobileClick)
  // }

  render () {
    return (
      <div className="mobile-menu-container">
        <div className="mobile-routing-box">
          <NavLink onClick={this.props.onMobileClose} exact={true} to="/about-us" className="mobile-routing-link">About Us</NavLink>
          <NavLink onClick={this.props.onMobileClose} exact={true} to="/how-we-work"className="mobile-routing-link">How We Work</NavLink>
          <NavLink onClick={this.props.onMobileClose} exact={true} to="/services" className="mobile-routing-link">Services</NavLink>
          <NavLink onClick={this.props.onMobileClose} exact={true} to="/press" className="mobile-routing-link">Press</NavLink>
        </div>
          <div className="secondary-routing-box">
            <div className="navigation">
              { this.props.userId ? null : <span onClick={this.handleSignupClick} to="#" className="mobile-routing-link">Sign up</span> }
              { this.props.userId ? null : <span onClick={this.handleLoginClick} to="#" className="mobile-routing-link">Login</span> }
              { this.props.userId ? <span onClick={this.handleMyAccountClick} to="#" className="top-routing-link modal-trigger">My Account</span> : null }
              { this.props.userId ? <span onClick={this.handleLogoutClick} to="#" className="top-routing-link">Logout</span> : null }
              <NavLink onClick={this.handleContactUsClick} to="#" className="mobile-routing-link">Contact Us</NavLink>
              <NavLink onClick={this.close} exact={true} to="/careers" className="mobile-routing-link">Careers</NavLink>
            </div>
            <div className="picture">
              <img src={require('../../images/common/figure_mobile_menu.png')} alt=""/>
            </div>
          </div>
          <Signup history={this.props.history} isOpen={this.state.signUpModalOpen} onClose={this.handleClose} />
          <Signin history={this.props.history} isOpen={this.props.signinModalOpen} onClose={this.handleLoginClose} onMobileClose={this.props.onMobileClose} openSignup={this.handleSignupClick}/>
          <ForgotPassword isOpen={this.props.forgotPasswordModalOpen} />
          <ForgotPasswordStatus isOpen={this.props.forgotPasswordStatusModalOpen} />
          <ContactUs isOpen={this.state.contactUsOpen} onClose={this.handleContactUsClose} onSubmit={this.handleContactUsSubmit}/>
          {/* <contactUs isOpen={this.state.contactUsOpen} onClose={this.handleContactUsClose} />
          <ResendLink isOpen={this.props.resendLinkModalOpen} />
          <ResendLinkStatus isOpen={this.props.resendLinkStatusModalOpen} />
          <SignupAfterRedirectModal isOpen={this.props.signupAfterRedirectModalOpen} />
          <ThankYouSignupModal isOpen={this.props.thankYouSignupModalOpen} /> */}
      </div>
    );
  }

  handleLoginClick = () => {
    this.props.openSigninModal();
  }
  handleSignupClick = () => {
    this.props.closeSigninModal(); 
    this.setState({ signUpModalOpen: true, signInModalOpen: false });
  }
  handleClose = () => {
    this.setState({ signUpModalOpen: false });
  }
  handleLoginClose = () => {
    this.props.closeSigninModal();

    // close the mobile menu via Header component so that it does not remain on screen and cover other components
    this.props.onMobileClose()
  }
  handleContactUsClick = () => {
    this.setState({ contactUsOpen: true })
  }
  handleContactUsClose = () => {
    this.setState({ contactUsOpen: false });
  }

  handleContactUsSubmit = (values) => {
    let contactInfo = {
      email: values.email,
      name: values.name,
      request: values.request,
      message: values.message
    };
    this.props.onMobileClose()
    contactService.sendContactNotificationEmail(contactInfo)
      .then( (resp) => this.props.toastManager.add('Request sent. Our team will contact you to assist.', {
        appearance: 'success'
      }))
      .catch( (err) => this.props.toastManager.add('Something went wrong. Request could not be sent. Please try again.', {
        appearance: 'success'
      }))
    this.handleContactUsClose(); 
  }

  handleLogoutClick = () => {
      localStorage.removeItem('nxPublicToken');
      this.props.clearUserInformation()
      authService.logoutFromCAS(); 
  }
  handleMyAccountClick = () => {
    this.props.history.push('/expert/profile');
  }
}
const mapStateToProps = ({ modals, userInfo }) => ({
  signupAfterRedirectModalIsOpen: modals.signupAfterRedirectModalIsOpen,
  resendLinkModalOpen: modals.resendLinkModalOpen,
  resendLinkStatusModalOpen: modals.resendLinkStatusModalOpen,
  userId: userInfo.user.id,
  forgotPasswordModalOpen: modals.forgotPasswordModalOpen,
  forgotPasswordStatusModalOpen: modals.forgotPasswordStatusModalOpen,
  signupAfterRedirectModalOpen: modals.signupAfterRedirectModalOpen,
  thankYouSignupModalOpen: modals.thankYouSignupModalOpen,
  signinModalOpen: modals.signinModalOpen
})


const mapDispatchToProps = (dispatch) => ({
  clearUserInformation: () => {
      dispatch(clearUserInformation())
  },

  handleSignupClick: () => {
      dispatch(openSignupModal())
  },

  closeSignupAfterRedirectModal: () => {
      dispatch(closeSignupAfterRedirectModal())
  },

  openSigninModal: () => {
    dispatch(openSigninModal())
  },

  closeSigninModal: () => {
    dispatch(closeSigninModal())
  }
})

export default compose(
  withRouter,
  withToastManager,
  connect(mapStateToProps, mapDispatchToProps)
)(MobileMenu);
