import React from 'react'; 

const Field = (props) => (

<div className="form-group">
    <label className="required">{props.label}</label>

    { props.errors && props.touched ?  <p style={{color: "red"}}>{props.errors}</p> : null}
    <div className="input-group">
        <input
            type={props.type}
            name={props.name}
            className="form-control"
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            maxLength={props.maxLength || null}
            disabled={props.disabled}
        />
        {/* for fields that need to be selectively editable */}
        {props.renderEditButton ? props.renderEditButton() : null}
    </div>
</div>
)

export default Field; 

// needs a Label, Errors obj, Name, onChange, value. MaxLength optional. 