import React, { Component } from 'react';
import { connect } from 'react-redux';
import SignupService from '../../utils/signupService';
import { setSignupAccessToken, clearSignupAccessToken, setSignupUser} from '../../actions/signupActions';
import { openSignupAfterRedirectModal } from '../../actions/modalActions'; 
import { withToastManager } from 'react-toast-notifications'; 
import queryString from 'query-string';

/**
 * After the expert provides their LinkedIn username and password
 * and redirects from LinkedIn, they arrive here. 
 */
class LinkedInOAuthSignup extends Component {

    constructor(props) {
        super(props); 
        this.signupService = new SignupService(); 
    }

    componentDidMount() {

        // make sure the code and nonce exist in the URL
        let parsedParams = queryString.parse(this.props.location.search); 
        if (parsedParams.code && parsedParams.state) {

            // example URL with code: https://newtonx.com/oauth/linkedin/signup?code=AQRoUtQitQ4kcnGb7DqCAADZbgM69PTBakt0eeyflY0XOIkFShqKiP8WMckXSJLzErkHiME8wQT4mzwVHn8vwlDrCJioCjC6bjmo8os0thH9ZuCP-jlGHHUcFTuKnQNnlMSNgt1V1NFlMV-L4xy9p_wvtTyU2MhdV4CrRjScrg6F8z5I-oXSg9puxtVbhg&state=fxy1jbjh82pdciwsayfwj8
            let accessCodeFromLinkedIn = parsedParams.code;
            let nonce = parsedParams.state 
            
            this.signupService.fetchLinkedInProfile(accessCodeFromLinkedIn, nonce)
              .then(resp => {this.signupService.setSignupAccessToken(resp.data.nxTempAccessToken); this.props.setSignupUser({
                  firstName: resp.data.firstName,
                  lastName: resp.data.lastName,
                  email: resp.data.emailAddress,
                  linkedInId: resp.data.publicProfileUrl
              })})
              .then( () => this.props.openSignupAfterRedirectModal())
              .catch( err => { 
                  // if backend returns that the user's profile is private we display the full message returned
                  if (err.response && err.response.data && err.response.description && err.response.data.description.includes('You cannot use LinkedIn to sign up')) {
                    this.props.toastManager.add(err.response.data.description, {
                        appearance: 'error'
                    });
                  } else {
                    this.props.toastManager.add('Unable to sign in through LinkedIn.', 
                  {
                    appearance: 'error'
                  }); 
                  }
                  this.props.history.push('/'); }); 
        } else {
            
            // URL didn't have the query strings we needed, so we can't progress
            this.props.history.push('/')
        }
    }

    render() {
        return(
            null
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSignupAccessToken: (token) => {
        dispatch(setSignupAccessToken(token))
    },
    
    clearSignupToken: () => {
        dispatch(clearSignupAccessToken())
    },

    setSignupUser: (signupUser) => {
        dispatch(setSignupUser(signupUser))
    },

    openSignupAfterRedirectModal: () => {
        dispatch(openSignupAfterRedirectModal())
    },

})

export default withToastManager(connect(null, mapDispatchToProps)(LinkedInOAuthSignup)); 