import React, { Component } from 'react';
import { environment } from '../../environments/env';
import ExpertScreen from '../../components/homepage/ExpertScreen';
import TrustedBy from '../../components/homepage/TrustedBy';
import AboutUs from '../../components/homepage/AboutUs';
import HowItWorks from '../../components/homepage/HowItWorks';
import Expertise from '../../components/homepage/Expertise';
import ClientOpinions from '../../components/homepage/ClientOpinions';
import ExpertOpinions from '../../components/homepage/ExpertsOpinions';
import WorkFlow from '../../components/homepage/WorkFlow';
import queryString from 'query-string';
import authService from '../../utils/authService';
import { setUserInformation, getProfile } from '../../actions/signinActions';
import { connect } from 'react-redux';
import { openResendLinkModal } from '../../actions/modalActions';
import userService from '../../utils/userService';

import {
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE
} from '../../action-types/login';

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);


    // if a valid CAS access token already exists, we try to log in with it
    this.checkIfAccessTokenExists();

    /**
     * part 2 of 3 in the LinkedIn OAuth sign-in process - we've just redirected from LI with their oauth ticket as a query string
     * example URL at this stage: http://localhost:4200/?ticket=ST-1-yH1gi1Dtm3NJzMcN0gYPKjGAhAId43e2b086d86
     */
    if (this.props.location.search && queryString.parse(this.props.location.search).ticket) {

      // this sends us back to CAS with the ticket to get our CAS access token 
      authService.completeOAuthForLinkedInLogin();
    }

    /**
     * part 3 of 3 in LinkedIn OAuth sign-in, where we return from CAS with our access token
     * example URL at this point: 
     * http://localhost:4200/#access_token=AT-70-w8yY4BsAHhilNWHxcW1c3N4xI0zUOS9n&token_type=bearer&expires_in=10800
     */
    if (this.props.location.hash) {

      // get the example key out of the URL fragment - example formatting #access_token=AT-70-w8yY4BsAHhilNWHxcW1c3N4xI0zUOS9n&token_type=bearer&expires_in=10800
      const nxPublicToken = this.props.location.hash.split('&')[0].split('=')[1];

      localStorage.setItem(environment.LOGIN_TOKEN_NAME, nxPublicToken);

      // with the key now accessible, we can get the profile
      this.getProfile();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userInfo.fetchProfileStatus !== this.props.userInfo.fetchProfileStatus &&
      (this.props.userInfo.fetchProfileStatus === FETCH_PROFILE_SUCCESS ||
        this.props.userInfo.fetchProfileStatus === FETCH_PROFILE_FAILURE)) {

      if (this.props.userInfo.fetchProfileStatus === FETCH_PROFILE_SUCCESS) {
        this.checkIfUserIsVerified(this.props.isVerified);
      } else {
        this.props.history.push('/')
      }
    }
  }

  checkIfAccessTokenExists = () => {
    if (localStorage.getItem(environment.LOGIN_TOKEN_NAME)) {
      this.getProfile();
    }
  }

  getProfile = () => {
    this.props.getProfile();
  }

  checkIfUserIsVerified = (isVerified: boolean) => {
    if (isVerified) {
      userService.redirectAfterSignin(this.props.history, this.props.userInfo);
    } else {
      this.props.history.push('/');
      this.props.openResendLinkModal();
    }
  }

  render() {
    return (
      <div>
        <ExpertScreen />
        <TrustedBy width={this.state.width} />
        <AboutUs width={this.state.width} />
        <HowItWorks />
        <Expertise width={this.state.width} />
        <ClientOpinions />
        <WorkFlow width={this.state.width} />
        <ExpertOpinions />
      </div>
    );
  }
}

const mapStateToProps = ({ userInfo }) => ({
  isVerified: userInfo.user.isVerified,
  userInfo
})

const mapDispatchToProps = (dispatch: Function) => ({
  setUserInformation: (userInfo) => {
    dispatch(setUserInformation(userInfo))
  },

  openResendLinkModal: () => {
    dispatch(openResendLinkModal())
  },

  getProfile: () => {
    dispatch(getProfile());
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
