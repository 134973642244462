import React, { Component } from 'react'
import MainBlackBox from '../../components/common/MainBlackBox';
import HowWeCanHelp from '../../components/services/HowWeCanHelp';
import HelpDropdowns from '../../components/services/HelpDropdowns';
import Products from '../../components/services/Products';
import FeatureDropdowns from '../../components/services/FeatureDropdowns';

import '../../styles/containers/Services.scss';

export default class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: null
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    return (
      <div> 
        <MainBlackBox 
          contentStyle={{ height: this.state.width < '250px'}}
          mainTitle="Expert Insights Across all Industries - "
          subTitle="Services"
          text="NewtonX rapidly and effectively connects clients across sectors, 
            geographies, and languages, with subject-matter experts for calls and large-scale surveys."
        />
        <img 
          style={{ width: '100%' }} 
          className="service-image"
          src={require('../../images/services/stairs.png')} 
          alt=""
        />
        <HowWeCanHelp />
        <HelpDropdowns />
        <MainBlackBox 
          contentStyle={{ marginTop: '100px', maxHeight: this.state.width > 464 ? '350px' : 'unset', height: ''}}
          mainTitle="Innovative Products  for Every Client Need -"
          subTitle="Services"
          text="NewtonX offers one-on-one expert calls, large-scale expert and influencer surveys, tailored workshops, and ad-hoc engagements."
        /> 
        <Products />
        <FeatureDropdowns />
      </div>
    );
  }
}