import React from 'react';
import { Formik } from 'formik';
import GooglePhoneService from '../../utils/GooglePhoneService';

const ExpertProfileForm = (props) => {
  return (
    <div style={{ paddingBottom: "2em" }}>
      <section className="container">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <h4 className="py-3">Update Your Information</h4>
            <Formik
              initialValues={{ firstName: props.firstName, lastName: props.lastName, phone: props.phone }}
              validate={values => {
                let errors = {};
                if (!values.firstName) {
                  errors.firstName = 'Required';
                }
                if (!values.lastName) {
                  errors.lastName = 'Required';
                }
                if (!values.phone) {
                  errors.phone = 'Required';
                } else if (!GooglePhoneService.isValidPhoneNumber(values.phone)) {
                  errors.phone = 'Not a valid number. Hint +[Country code][Area code][Phone number]';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                props.onSubmit(values);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (

                  <form onSubmit={handleSubmit} className="bootstrap gothic-font">
                    <div className="form-group">
                      <label className="required">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                      {errors.firstName && touched.firstName ? (<div className="text-danger">{errors.firstName}</div>) : null}
                    </div>

                    <div className="form-group">
                      <label className="required">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                      {errors.lastName && touched.lastName ? (<div className="text-danger">{errors.lastName}</div>) : null}
                    </div>

                    <div className="form-group">
                      <label className="required">Phone Number</label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      ></input>
                      {errors.phone && touched.phone ? (<div className="text-danger">{errors.phone}</div>) : null}
                    </div>

                    <button type="submit" disabled={isSubmitting} className="btn black-button">
                      Save
                    </button>
                  </form>
                )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ExpertProfileForm; 