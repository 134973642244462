import React from 'react';
import { Link } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';

import '../../styles/components/signup/ExternalSignInForm.scss';
import '../../styles/components/signup/SignupForm.scss';
import '../../styles/common/Button.scss';

const ExternalSignInForm = (props) => (
  <div>
    <div className="bootstrap">
      {props.redirectingFromEmailVerification ? props.renderEmailVerifiedAlert() : null}
      {props.loginFailed ? props.renderLoginFailedAlert() : null}
    </div>
    <Form className="form-container">
      <span className="label">Email<i>*</i></span>
      <span style={{ color: 'red' }}>{props.errors.email}</span>
      <Field className="input-style form-input" name="email" placeholder="Email" />
      <span className="label">Password<i>*</i></span>
      <span style={{ color: 'red' }}>{props.errors.email}</span>
      <Field style={{ marginBottom: '0px' }} type="password" className="input-style form-input" name="password" placeholder="Password" />
      <Link to="#" className="label-right" onClick={props.handleForgotPassword}>Forgot Password?</Link>
      <button className="white-button" type="submit">Submit</button>
    </Form>
    <Link to="#" onClick={props.handleForgotPassword} className="label-right-mobile">Forgot Password?</Link>
  </div>
);

export default withFormik({
  mapPropsToValues() {
    return {
      email: '',
      password: '',
    }
  },

  validate: values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    setSubmitting = false;
    resetForm();
    props.handleSignin(values.email, values.password);

  },
})(ExternalSignInForm)