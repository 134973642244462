// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import ExpertProfileContainer from './ExpertProfileContainer';
import UserAccountContainer from './UserAccountContainer';
import PaymentEditContainer from './PaymentEditContainer';
import FaqContainer from './FaqContainer';
import userService from '../../utils/userService'; 

/**
 * MyProfileContainer handles the rendering of menus accessible
 * to the expert after logging in. 
 */
class MyProfileContainer extends Component {
    constructor() {
        super(); 
        this.state = {
            itemIsActive: {
                account: true,
                profile: false,
                payment: false,
                faq: false
            }
        }
    }

    componentDidMount() {

        // if user clicks on "My Account" in header and comes here before completing signup, we need to redirect them
        if (userService.isUserSignupIncomplete(this.props.userInfo)) {
            this.props.history.push('/'); 
        }
    }

    onClick = (event) => {
        let clickedNavbarItem = event.target.innerText.toLowerCase(); 
        this.updateActiveNavbarItem(clickedNavbarItem); 
    }

    // the 'active' class provides underline styling to indicate the current screen to the user
    renderNavItemClasses = (itemName) => {
        if (this.state.itemIsActive[itemName]) {
            return "nav-link active"
        } else {
            return "nav-link"
        }
    }

    renderSelectedProfilePage = () => {
        if (this.state.itemIsActive.account) {
            return <UserAccountContainer />
        } else if (this.state.itemIsActive.profile) {
            return <ExpertProfileContainer />
        } else if (this.state.itemIsActive.payment) {
            return <PaymentEditContainer />
        } else if (this.state.itemIsActive.faq) {
            return <FaqContainer />
        } else {
            return null
        }
    }

    // the active item will be styled to underline it 
    updateActiveNavbarItem = (clickedNavbarItem) => {
        let navbarItemsState = {
                profile: false,
                account: false,
                payment: false,
                faq: false
        };
        navbarItemsState[clickedNavbarItem] = true; 
        this.setState({
            itemIsActive: {
                ...navbarItemsState
            }
        });
    }
    render() {
        return(
            <div className="bootstrap gothic-font">
            <div className="container">
              <ul className="nav justify-content-center flex-xs-column" style={{paddingBottom: '.5em'}}>
                <li className="nav-item">
                    <a className={this.renderNavItemClasses('account')} href="#" onClick={this.onClick}>Account</a>
                </li>
                <li className="nav-item">
                    <a className={this.renderNavItemClasses('profile')} href="#" onClick={this.onClick}>Profile</a>
                </li>
                <li className="nav-item">
                    <a className={this.renderNavItemClasses('payment')} href="#" onClick={this.onClick}>Payment</a>
                </li>
                <li className="nav-item">
                    <a className={this.renderNavItemClasses('faq')} href="#" onClick={this.onClick}>FAQ</a>
                </li>
            </ul>
            {this.renderSelectedProfilePage()}
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
}); 

export default connect(mapStateToProps, null)(MyProfileContainer); 
