import React, { Component } from 'react';
import { UnmountClosed } from 'react-collapse';
import $ from 'jquery';

import '../../styles/common/InfoDropdown.scss';

export default class InfoDropdown extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      showMore: false, 
      width: null,
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  handleShowClick = () => {
    const id = this.props.title.replace(/\s/g,'');

    this.setState(prevState => {
      return {
        showMore: !prevState.showMore
      }
    }, () => {
      if(this.state.width < 865 ) {
        if(this.state.showMore === false) {
          $("html, body").animate({ scrollTop: $(`#${id}`).offset().top - 70}, 600);
          // const offsetMinus = this.state.width < 573 ? 80 : 130;
          // window.scrollTo({
          //   top:this.myRef.current.offsetTop - offsetMinus, 
          //   behavior: "smooth"   // Optional, adds animation
          // });
        }
      }
    })
  }

  renderMoreInfo = () => {
    return this.props.children;
  }

  render() {
    let buttonText;
    if (this.props.buttonText) {
      buttonText = this.props.buttonText;
    } else {
      buttonText = `Show ${this.state.showMore ? 'Less' : 'More'}`
    }
    const id = this.props.title.replace(/\s/g,'');
    return (
      <div id={id} className="info-dropdown-container" ref={this.myRef}>
        <div className="info-dropdown-content">
          <div className="dropdown-header">
            <span>{this.props.title}</span>
            <button onClick={this.handleShowClick}>{buttonText}</button>
          </div>
          <div className="short-text">
            <p>{this.props.shortText}</p>
          </div>
          {this.renderCorrectContentBasedOnDevice()}
          <div className="mobile-button-container">
            <button className="mobile-button" onClick={this.handleShowClick}>{buttonText}</button>
          </div>
        </div>
      </div>
    );
  }

  renderCorrectContentBasedOnDevice = () => {
    if(this.state.width < 865) {
      return (
        <UnmountClosed  
        forceInitialAnimation={true} 
        isOpened={this.state.showMore}
        springConfig={{stiffness: 25, damping: 10, precision: 0}}
        >
          {this.renderMoreInfo()}
        </UnmountClosed>
      );
    }
    return <div>{this.state.showMore && this.renderMoreInfo()}</div>
  
  }
}