import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExpertProfileForm from '../../components/myProfile/ExpertProfileForm';
import { updateExpert } from '../../actions/signinActions';
import { withToastManager } from 'react-toast-notifications';
import { UPDATE_EXPERT_START, UPDATE_EXPERT_SUCCESS, UPDATE_EXPERT_FAILURE } from '../../action-types/login';

/**
 * This form displays the expert's email address,
 * and permits the updating of their password. 
 */
class ExpertProfileContainer extends Component {
    constructor() {
        super();
        this.state = {
            alerts: '',
            updateExpertStatus: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateExpertStatus !== this.props.updateExpertStatus &&
            (
                this.state.updateExpertStatus === UPDATE_EXPERT_START &&
                (
                    this.props.updateExpertStatus === UPDATE_EXPERT_SUCCESS ||
                    this.props.updateExpertStatus === UPDATE_EXPERT_FAILURE
                )
            )) {

            if (this.props.updateExpertStatus === UPDATE_EXPERT_SUCCESS) {
                this.props.toastManager.add('Information updated successfully!', {
                    appearance: 'success'
                });
            } else {
                this.props.toastManager.add('Password could not be updated. Please confirm that you have the correct current password and try again.', {
                    appearance: 'error'
                });
            }
        }
    }

    handleFormSubmit = (values) => {
        const expert = this.updateExpertValues(this.props.expertInfo, values);

        this.setState({
            updateExpertStatus: UPDATE_EXPERT_START
        });

        this.props.updateExpert(expert.id, expert);
    }

    updateExpertValues = (expert: Object, values: Object) => {
        expert.firstName = values.firstName;
        expert.lastName = values.lastName;
        expert.phone = values.phone;
        return expert;
    }

    render() {
        return (
            <ExpertProfileForm alerts={this.state.alerts} onSubmit={this.handleFormSubmit} firstName={this.props.expertInfo.firstName} lastName={this.props.expertInfo.lastName} phone={this.props.expertInfo.phone} />
        )
    }
}

const mapStateToProps = ({ userInfo }) => ({
    expertInfo: userInfo.expert,
    updateExpertStatus: userInfo.updateExpertStatus
});

const mapDispatchToProps = (dispatch) => ({
    updateExpert: (id, expert) => {
        dispatch(updateExpert(id, expert))
    }
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ExpertProfileContainer)); 