const dev = {
  PRODUCTION: false,
  ENABLE_CAPTCHA: false,
  CAPTCHA_SITE_KEY: '6LdhUOwUAAAAAANL7TgibqSDkPYm6BSVXq7eC3Cm',
  CAS_BASE_PATH: 'https://cas-dev.newtonx.com:8443/cas',
  API_BASE_PATH: 'http://localhost:8081/api/v1',
  LINKEDIN_CLIENT_KEY: '86q5gbtr6pf00v',
  CAS_CLIENT_ID: 'http://localhost:4200/',
  CAS_LINKEDIN_CLIENT_NAME: 'LinkedIn',
  CLIENT_BASE_PATH: 'http://127.0.0.1:4200',
  LINKEDIN_CLIENT_ID: '86q5gbtr6pf00v',
  LOGIN_TOKEN_NAME: 'nxPublicToken',
  SIGNUP_TOKEN_NAME: 'nxSignupToken',
  GA_TRACKING_ID: 'DV-DUMMYID',
  SURVEY_PAYMENT_COMPLETION_URL:
    'http://hub.staging.newtonx.com/nxpaymentSubmit/',
};

const devInt = {
  PRODUCTION: true,
  ENABLE_CAPTCHA: true,
  CAPTCHA_SITE_KEY: '6LdhUOwUAAAAAANL7TgibqSDkPYm6BSVXq7eC3Cm',
  LINKEDIN_CLIENT_ID: '86q5gbtr6pf00v',
  CLIENT_BASE_PATH: 'https://newtonx-expert-ui.dev.newtonx.com',
  API_BASE_PATH: 'https://public-api.dev.newtonx.com/api/v1',
  CAS_BASE_PATH: 'https://public-cas.dev.newtonx.com/cas',
  CAS_CLIENT_ID: 'https://public-ui.dev.newtonx.com/',
  CAS_LINKEDIN_CLIENT_NAME: 'LinkedIn',
  LOGIN_TOKEN_NAME: 'nxPublicToken',
  SIGNUP_TOKEN_NAME: 'nxSignupToken',
  GA_TRACKING_ID: 'ST-DUMMYID',
  SURVEY_PAYMENT_COMPLETION_URL:
    'http://hub.dev.newtonx.com/nxpaymentSubmit/',
};

const prod = {
  PRODUCTION: true,
  ENABLE_CAPTCHA: true,
  CAPTCHA_SITE_KEY: '6LfUbOoUAAAAAIar_MxYlqV0vmUuOSxayA3a7smf',
  LINKEDIN_CLIENT_ID: '86q5gbtr6pf00v',
  CLIENT_BASE_PATH: 'https://www.newtonx.com',
  API_BASE_PATH: 'https://public-api.prod.newtonx.com/api/v1',
  CAS_BASE_PATH: 'https://public-cas.prod.newtonx.com/cas',
  CAS_CLIENT_ID: 'https://www.newtonx.com/',
  CAS_LINKEDIN_CLIENT_NAME: 'LinkedIn',
  LOGIN_TOKEN_NAME: 'nxPublicToken',
  SIGNUP_TOKEN_NAME: 'nxSignupToken',
  GA_TRACKING_ID: 'UA-142539538-1',
  SURVEY_PAYMENT_COMPLETION_URL: 'http://hub.newtonx.com/nxpaymentSubmit/',
};

const staging = {
  PRODUCTION: true,
  ENABLE_CAPTCHA: true,
  CAPTCHA_SITE_KEY: '6LdhUOwUAAAAAANL7TgibqSDkPYm6BSVXq7eC3Cm',
  LINKEDIN_CLIENT_ID: '86q5gbtr6pf00v',
  CLIENT_BASE_PATH: 'https://newtonx-expert-ui.staging.newtonx.com',
  API_BASE_PATH: 'https://public-api.staging.newtonx.com/api/v1',
  CAS_BASE_PATH: 'https://public-cas.staging.newtonx.com/cas',
  CAS_CLIENT_ID: 'https://public-ui.staging.newtonx.com/',
  CAS_LINKEDIN_CLIENT_NAME: 'LinkedIn',
  LOGIN_TOKEN_NAME: 'nxPublicToken',
  SIGNUP_TOKEN_NAME: 'nxSignupToken',
  GA_TRACKING_ID: 'ST-DUMMYID',
  SURVEY_PAYMENT_COMPLETION_URL:
    'http://hub.staging.newtonx.com/nxpaymentSubmit/',
};

let environment;

if (process.env.REACT_APP_STAGE === 'production') {
  environment = prod;
} else if (process.env.REACT_APP_STAGE === 'staging') {
  environment = staging;
} else if (process.env.REACT_APP_STAGE === 'development') {
  environment = devInt;
} else {
  environment = dev;
}

export { environment };
