import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { closeResendVerificationEmailConfirmationModal } from '../../actions/modalActions';
import { withToastManager } from "react-toast-notifications";
import { resendVerificationEmail } from '../../actions/signinActions';
import { RESEND_VERIFICATION_EMAIL_START, RESEND_VERIFICATION_EMAIL_SUCCESS, RESEND_VERIFICATION_EMAIL_FAILURE } from '../../action-types/login'

export class ResendVerificationEmailConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resendVarificationEmailStatus: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resendVarificationEmailStatus !== this.props.resendVarificationEmailStatus &&
            (
                this.state.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_START &&
                (
                    this.props.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_SUCCESS ||
                    this.props.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_FAILURE
                )
            )) {
            if (this.props.resendVarificationEmailStatus === RESEND_VERIFICATION_EMAIL_SUCCESS) {
                this.handleClose();
                this.props.toastManager.add('Verification email has been sent.', {
                    appearance: 'success',
                });
            } else {
                this.handleClose();
                this.props.toastManager.add('Error in sending verification email', {
                    appearance: 'error',
                })
            }
        }
    }

    handleSubmit = () => {
        this.setState({
            resendVarificationEmailStatus: RESEND_VERIFICATION_EMAIL_START
        });
        this.props.resendVerificationEmail(this.props.modals.userId);
    };

    handleClose = () => {
        this.props.closeResendVerificationEmailConfirmationModal();
    };

    render() {
        return (
            <Modal
                className="modal-content bootstrap gothic-font"
                isOpen={this.props.isOpen}
            >
                <div className="modal-header" style={{ justifyContent: "center", borderBottom: "none" }}>
                    <h1
                        className="modal-title"
                        style={{ color: "#004D71", marginBottom: "0", "lineHeight": "1.5", fontFamily: "inherit", fontWeight: "300", marginLeft: "auto", width: "100%", textAlign: "center" }}
                    >
                        Resend Verification Email
                    </h1>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: "80%" }}>
                    <div className="col-12" style={{ height: "100%" }}>
                        <label style={{ margin: "0px 6em 0px 5em" }}>
                            Please confirm that you would like
                            to resend the verification email:
                        </label>
                        <div className="form-row" style={{ position: "absolute", bottom: "0", width: "100%" }}>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-secondary" style={{ width: "100%", height: "38px" }} onClick={this.handleClose}>Cancel</button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <button type="submit" className="btn black-button" style={{ width: "100%", height: "38px" }} onClick={this.handleSubmit}>
                                        Confirm
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        )
    }
}

const mapStateToProps = ({ modals, userInfo }) => {
    return {
        modals,
        resendVarificationEmailStatus: userInfo.resendVarificationEmailStatus
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeResendVerificationEmailConfirmationModal: () => dispatch(closeResendVerificationEmailConfirmationModal()),

    resendVerificationEmail: (userId) => {
        dispatch(resendVerificationEmail(userId));
    }
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ResendVerificationEmailConfirm));
