// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExpertSetupForm from '../../components/signup/ExpertSetupForm';
import { updateExpert } from '../../actions/signinActions';
import { UPDATE_EXPERT_START, UPDATE_EXPERT_SUCCESS, UPDATE_EXPERT_FAILURE } from '../../action-types/login';

/**
 * First stage of the expert information signup process.
 * Expert provides first name, last name, and phone number here. 
 */
export class ExpertSetupContainer extends Component {

    constructor() {
        super();
        this.state = {
            alerts: '',
            updateExpertStatus: ''
        }
    }

    componentDidMount() {
        if (!this.props.expert.id) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateExpertStatus !== this.props.updateExpertStatus &&
            (
                this.state.updateExpertStatus === UPDATE_EXPERT_START &&
                (
                    this.props.updateExpertStatus === UPDATE_EXPERT_SUCCESS ||
                    this.props.updateExpertStatus === UPDATE_EXPERT_FAILURE
                )
            )) {

            if (this.props.updateExpertStatus === UPDATE_EXPERT_SUCCESS) {
                this.props.history.push('/expert/terms');
            } else {
                this.setState({
                    alerts: 'Something went wrong. Please try again.'
                });
            }
        }
    }

    handleSubmit = (values: Object) => {
        const expert = this.updateExpertValues(this.props.expert, values);

        this.setState({
            updateExpertStatus: UPDATE_EXPERT_START
        });

        this.props.updateExpert(expert.id, expert);
    }

    updateExpertValues = (expert: Object, values: Object) => {
        expert.firstName = values.firstName;
        expert.lastName = values.lastName;
        expert.phone = values.phone;
        return expert;
    }

    render() {
        return (
            <ExpertSetupForm onSubmit={this.handleSubmit} alerts={this.state.alerts} />
        )
    }
}

const mapStateToProps = ({ userInfo }) => ({
    expert: userInfo.expert,
    updateExpertStatus: userInfo.updateExpertStatus
})

const mapDispatchToProps = (dispatch) => ({
    updateExpert: (id, expert) => {
        dispatch(updateExpert(id, expert))
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(ExpertSetupContainer); 