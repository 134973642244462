import React from 'react';
import i18n from '../../i18n';
import { languages } from '../../data/languages';

function renderLanguages() {
  return languages.map(language => {
    return (
      <option value={language.formAbbreviation} key={language.formAbbreviation}>
        {i18n.t(language.languageName)}
      </option>
    );
  });
}
const SurveyPayment = props => {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>{i18n.t('pageHeadline')}</h1>
      <div>{i18n.t('paymentHeaderMessage')}</div>
      <br />
      <div>{i18n.t('Select a Language')}:</div>
      <select
        id='language-select'
        onChange={props.onLanguageChange}
        defaultValue={props.language}
      >
        {renderLanguages()}
      </select>
    </div>
  );
};

export default SurveyPayment;
