import React from 'react'

import '../../styles/components/aboutUs/GeneralInfo.scss';

export default (props) => (
  <div className="general-info-container">
    <div style={{ height: '470px', ...props.contentStyle }} className="general-info-content">
      <div className="header-box">
        <span className="mobile-subtitle">{props.subTitle}</span>
        <span>{props.mainTitle}</span>
      </div>
      <div className="main-content">
        {
          (props.subTitle || props.children) &&
            <div className="title-box">
              { props.subTitle && <span>{props.subTitle}</span> }
              { props.children }
            </div>
        }
  
        { props.text &&
            <div className="text-box" style={props.textStyle}>
              <p style={{ marginTop: '0px', ...props.textStyle && props.textStyle  }}>
                {props.text}
              </p>
            </div>
        }

      </div>
    </div>
  </div>
);