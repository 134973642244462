import React from 'react';

import '../../styles/common/WhiteFeatureBox.scss';
import '../../styles/common/Button.scss';

export default (props) => {
  if (props.placeholder && props.style) {
    return (
      <div className="white-box-container" style={props.style}>
        <div className="text-small-placeholder" style={{ marginTop: '35px', marginLeft: '30px'}}>
          <p>{props.placeholder}</p>
        </div>
      </div>
    );
  }

  if(props.placeholder) {
    return (
      <div className="white-box-container" style={props.style}>
        <div className="white-box-content">
          <div className="text" style={{ display: 'flex', alignItems: 'flex-end', marginTop: '35px'}}>
            <p>{props.placeholder}</p>
          </div>
        </div>
      </div>
    );
  }

  if (props.buttonLink) {
    return (
      <div className="white-box-container">
      <div className="white-box-content">
        { props.children && <div className="image-container">{props.children}</div> }
        { props.author && <span className="author">{props.author}</span>}
        <div className="text" style={{ height: (props.children || props.author) ? '120px' : '170px'}}>
          <p>{props.text}</p>
        </div>
        <div className="button-container">
          <button className="white-button center" onClick={() => window.open(props.buttonLink, '_self')}>
            <p className='download'>
            {props.buttonText}</p>
          </button>
        </div>
      </div>
    </div>
    );
  }

  return (
    <div className="white-box-container">
      <div className="white-box-content">
        { props.children && <div className="image-container">{props.children}</div> }
        { props.author && <span className="author">{props.author}</span>}
        <div className="text" style={{ height: (props.children || props.author) ? '120px' : '170px'}}>
          <p>{props.text}</p>
        </div>
        <div className="button-container">
          <button className="white-button center">
            {
              props.downloadLink ? (
                <a className='download' href={props.downloadLink} download>{props.buttonText}</a>
              ) : props.buttonText
            }
          </button>
          {props.audio && <span>DOWNLOAD AUDIO</span>}
        </div>
      </div>
    </div>
  );
};