import React from 'react';
import {
  privacyTitle,
  securityTitle,
  privacyUpdatedDate,
  securityUpdatedDate,
} from '../../data/policies';

export default ({ isPrivacy }) => (
  <div className='policies-header__container'>
    <h1 className='policies-header__title'>
      { isPrivacy ? privacyTitle : securityTitle }
    </h1>
    <p className='policies-header__date'>
      {`Updated on ${isPrivacy ? privacyUpdatedDate : securityUpdatedDate}`}
    </p>
  </div>
);