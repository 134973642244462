import React from 'react'

import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Solution"
      text="NewtonX created a multi-faceted team including R&D engineers, 
        hardware engineers, software engineers and sales people experienced 
        in pilots and partnerships for one-on-one consultations and an in-person workshop."
    />
    <DropdownElement 
      description="Impact"
      text="The software company learned best practices that they applied to subsequent 
        similar situations. The client learned from leading experts the keys to 
        successful implementation  of VR, including the costs of buying or developing the 
        technology, the implementation roadmaps, and the key 
        risks associated with different implementation options."
    />
  </div>
);