import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware} from 'connected-react-router'; 
import { batch, batching } from 'redux-batch-middleware'; 
import thunk from 'redux-thunk'; 
import rootReducer from '../reducers/root-reducer.js';
import { createBrowserHistory } from 'history';


const initialState = {
  userInfo: {
    user: {},
    expert: {},
    expertPayment: {}
  },
  /** This is not used
  signupUserInfo: {
    userLinkedInProfileInfo: { }
  }, **/
};



export const history = createBrowserHistory();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = applyMiddleware(
  routerMiddleware(history),
  thunk,
  batch,
);

const store = createStore(
        batching(rootReducer(history)),
        initialState,
        composeEnhancer(middlewares),
      );

export default store; 