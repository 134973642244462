import {
    OPEN_RESEND_LINK_MODAL,
    CLOSE_RESEND_LINK_MODAL,
    CLOSE_SIGNUP_MODAL,
    CLOSE_SIGNIN_MODAL,
    OPEN_SIGNIN_MODAL,
    OPEN_SIGNUP_MODAL,
    OPEN_SIGNUP_AFTER_REDIRECT_MODAL,
    CLOSE_SIGNUP_AFTER_REDIRECT_MODAL,
    OPEN_RESEND_LINK_STATUS_MODAL,
    CLOSE_RESEND_LINK_STATUS_MODAL,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    OPEN_FORGOT_PASSWORD_STATUS_MODAL,
    CLOSE_FORGOT_PASSWORD_STATUS_MODAL,
    OPEN_THANK_YOU_SIGNUP_MODAL,
    CLOSE_THANK_YOU_SIGNUP_MODAL,
    RESET_PASSWORD_USER_EMAIL,
    OPEN_RESET_PASSWORD_CONFIRMATION_MODAL,
    CLOSE_RESET_PASSWORD_CONFIRMATION_MODAL,
    RESEND_VERIFICATION_USER_ID,
    OPEN_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL,
    CLOSE_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL
} from '../action-types/modals';

export const closeSignupModal = () => ({
    type: CLOSE_SIGNUP_MODAL,
    payload: { signupModalOpen: false }
});

export const openSignupModal = () => ({
    type: OPEN_SIGNUP_MODAL,
    payload: { signupModalOpen: true }
});

export const closeSignupAfterRedirectModal = () => ({
    type: CLOSE_SIGNUP_AFTER_REDIRECT_MODAL,
    payload: { signupAfterRedirectModalOpen: false }
});

export const openSignupAfterRedirectModal = () => ({
    type: OPEN_SIGNUP_AFTER_REDIRECT_MODAL,
    payload: { signupAfterRedirectModalOpen: true }
});

export const closeSigninModal = () => ({
    type: CLOSE_SIGNIN_MODAL,
    payload: { signinModalOpen: false}
});

export const openSigninModal = () => ({
    type: OPEN_SIGNIN_MODAL,
    payload: { signinModalOpen: true }
})

export const closeResendLinkModal = () => ({
    type: CLOSE_RESEND_LINK_MODAL,
    payload: { resendLinkModalOpen: false}
});

export const openResendLinkModal = () => ({
    type: OPEN_RESEND_LINK_MODAL,
    payload: { resendLinkModalOpen: true }
})

export const openResendLinkStatusModal = () => ({
    type: OPEN_RESEND_LINK_STATUS_MODAL,
    payload: { resendLinkStatusModalOpen: true}
})

export const closeResendLinkStatusModal = () => ({
    type: CLOSE_RESEND_LINK_STATUS_MODAL,
    payload: { resendLinkStatusModalOpen: false }
})

export const openForgotPasswordModal = () => ({
    type: OPEN_FORGOT_PASSWORD_MODAL,
    payload: { forgotPasswordModalOpen: true }
})

export const closeForgotPasswordModal = () => ({
    type: CLOSE_FORGOT_PASSWORD_MODAL,
    payload: { forgotPasswordModalOpen: false }
})

export const openForgotPasswordStatusModal = () => ({
    type: OPEN_FORGOT_PASSWORD_STATUS_MODAL,
    payload: { forgotPasswordStatusModalOpen: true }
})

export const closeForgotPasswordStatusModal = () => ({
    type: CLOSE_FORGOT_PASSWORD_STATUS_MODAL,
    payload: { forgotPasswordStatusModalOpen: false }
})

export const openThankYouSignupModal = () => ({
    type: OPEN_THANK_YOU_SIGNUP_MODAL,
    payload: { thankYouSignupModalOpen: true }
})

export const closeThankYouSignupModal = () => ({
    type: CLOSE_THANK_YOU_SIGNUP_MODAL,
    payload: { thankYouSignupModalOpen: false}
})


export const setResetPasswordUserEmail = (userEmail) => ({
    type: RESET_PASSWORD_USER_EMAIL,
    payload: { userEmail: userEmail}
})

export const openResetPasswordConfirmationModal = () => ({
    type: OPEN_RESET_PASSWORD_CONFIRMATION_MODAL,
    payload: { resetPasswordConfirmationModalOpen: true }
})

export const closeResetPasswordConfirmationModal = () => ({
    type: CLOSE_RESET_PASSWORD_CONFIRMATION_MODAL,
    payload: { resetPasswordConfirmationModalOpen: false }
})

export const setResendVerificationEmailUserId = (userId) => ({
    type: RESEND_VERIFICATION_USER_ID,
    payload: { userId: userId}
})

export const openResendVerificationEmailConfirmationModal = () => ({
    type: OPEN_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL,
    payload: { resendVerificationEmailConfirmationModalOpen: true }
})

export const closeResendVerificationEmailConfirmationModal = () => ({
    type: CLOSE_RESEND_VERIFICATION_EMAIL_CONFIRMATION_MODAL,
    payload: { resendVerificationEmailConfirmationModalOpen: false }
})
