import React, { Component } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Signup from '../../containers/login/Signup';
import MobileMenu from './MobileMenu';
import { connect } from 'react-redux';
import { compose } from 'redux';
import '../../styles/common/Header.scss';
import ResendLink from '../resendLink/ResendLink';
import ResendLinkStatus from '../resendLink/ResendLinkStatus';
import SignupAfterRedirectModal from '../../containers/signup/SignupAfterRedirectModal';
import ThankYouSignupModal from '../../containers/signup/ThankYouSignupModal';
import { clearUserInformation } from '../../actions/signinActions';
import { openSignupModal, closeSignupAfterRedirectModal, openSigninModal, closeSigninModal } from '../../actions/modalActions';
import queryString from 'query-string';

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      sticky: false,
      width: null,
      showMobileMenu: false,
      contactUsOpen: false
    }
    this.headerRef = React.createRef();
  }
  listenScrollEvent = e => {
    // let offsetTop = this.headerRef.current.offsetTop;
    // console.log(offsetTop);
    // if (offsetTop > 40) {
    //   this.setState({sticky: true});
    // } else if (offsetTop <= 40) {
    //   this.setState({sticky: false});
    // }
  }

  listenResizeEvent = () => {
    this.setState({ width: window.innerWidth })
  }

  componentDidMount() {
    this.listenResizeEvent();
    window.addEventListener('scroll', this.listenScrollEvent);
    window.addEventListener('resize', this.listenResizeEvent);
    let parsedParams = queryString.parse(this.props.location.search);
    if (this.props.location.pathname === '/verify' && parsedParams['ref']) {
      /**
       * when the user arrives from the verify email link we need to open the mobile menu so the signin modal,
       * which is a child of the mobile menu, appears automatically with the confirm msg
       */
      this.setState({
        showMobileMenu: true
      })
    }

  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
    window.removeEventListener('resize', this.listenResizeEvent);

  }

  render() {
    const activeStyle = {
      color: '#0000FF',
      paddingBottom: '5px',
      borderBottom: '2px solid #0000FF',
    }

    if(this.state.width < 573 ) {
      return this.renderMobileMenu();
    }
    return (
      <header className="header" ref={this.headerRef}>
        {/* <div className="top-routing">
          <div className="top-routing-box">
            <span onClick={this.handleSignupClick} to="#" className={modalTriggerStyle}>Sign up</span>
            <NavLink to="#" className="top-routing-link">Login</NavLink>
            <NavLink to="#" className="top-routing-link">Contact Us</NavLink>
            <NavLink exact={true} activeStyle={activeTopNavStyle} to="/careers" className="top-routing-link">Careers</NavLink>
          </div>
        </div> */}
        <div className={`main-routing ${this.state.sticky && 'sticky-main-routing'}`}>
          <div onClick={this.onMobileClose} className="header-logo-box">
            <Link to="/">
              <img src={require('../../images/homepage/newtonx_logo@3x.png')} alt=""/>
            </Link>
          </div>
          <div className="main-routing-box">
            <NavLink exact={true} activeStyle={activeStyle} to="/about-us" className="main-routing-link">About Us</NavLink>
            <NavLink exact={true} activeStyle={activeStyle} to="/how-we-work"className="main-routing-link">How We Work</NavLink>
            <NavLink exact={true} activeStyle={activeStyle} to="/services" className="main-routing-link">Services</NavLink>
            <NavLink exact={true} activeStyle={activeStyle} to="/press" className="main-routing-link">Press</NavLink>
          </div>
        </div>
        <Signup isOpen={this.state.modalOpen} onClose={this.handleClose}/>
      </header>
    );
  }

  renderMobileMenu = () => {
    return (
      <header className="header" ref={this.headerRef} style={{overflow: 'hidden'}} >
        <div className={`main-routing mobile-menu ${this.state.sticky && 'sticky-main-routing'}`}>
          <div className="header-logo-box" onClick={this.onMobileClose}>
            <Link to="/">
              <img src={require('../../images/homepage/newtonx_logo@3x.png')} alt=""/>
            </Link>
          </div>
          <div>
            {
              this.state.showMobileMenu ?
                <img onClick={this.onMobileClose} className="close-icon" src={require('../../images/common/close_icon@3x.png')} alt=""/>
              :
                <img onClick={this.onMobileMenuClick} src={require('../../images/common/mobile_menu@3x.png')} alt=""/>
            }

          </div>
        </div>
        { this.state.showMobileMenu && <MobileMenu onMobileClose={this.onMobileClose}/> }
        <ResendLink isOpen={this.props.resendLinkModalOpen} />
        <ResendLinkStatus isOpen={this.props.resendLinkStatusModalOpen} />
        <SignupAfterRedirectModal isOpen={this.props.signupAfterRedirectModalOpen} />
        <ThankYouSignupModal isOpen={this.props.thankYouSignupModalOpen} />

      </header>
    );
  }

  onMobileMenuClick = () => {
    this.setState({ showMobileMenu: true });
  }

  onMobileClose = () => {
    this.setState({ showMobileMenu: false });
  }
  handleContactUsClick = () => {
    this.setState({ contactUsOpen: true })
  }
  handleContactUsClose = () => {
    this.setState({ contactUsOpen: false });
  }

  handleLogoutClick = () => {
      localStorage.removeItem('nxPublicToken');
      this.props.clearUserInformation();
      this.props.history.push('/');
  }
  handleMyAccountClick = () => {
    this.props.history.push('/expert/profile');
  }

}

const mapStateToProps = ({ modals, userInfo }) => ({
signupAfterRedirectModalIsOpen: modals.signupAfterRedirectModalIsOpen,
resendLinkModalOpen: modals.resendLinkModalOpen,
resendLinkStatusModalOpen: modals.resendLinkStatusModalOpen,
userId: userInfo.user.id,
forgotPasswordModalOpen: modals.forgotPasswordModalOpen,
forgotPasswordStatusModalOpen: modals.forgotPasswordStatusModalOpen,
signupAfterRedirectModalOpen: modals.signupAfterRedirectModalOpen,
thankYouSignupModalOpen: modals.thankYouSignupModalOpen,
signinModalOpen: modals.signinModalOpen
})


const mapDispatchToProps = (dispatch) => ({
clearUserInformation: () => {
    dispatch(clearUserInformation())
},

handleSignupClick: () => {
    dispatch(openSignupModal())
},

closeSignupAfterRedirectModal: () => {
    dispatch(closeSignupAfterRedirectModal())
},

openSigninModal: () => {
  dispatch(openSigninModal())
},

closeSigninModal: () => {
  dispatch(closeSigninModal())
}
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
