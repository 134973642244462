// @flow
import axios from 'axios';
import { environment } from '../environments/env';
import PaymentTypeEnums from '../enums/PaymentTypeEnums';

class UserService {

  redirectAfterSignin = (history, userInfo) => {
    if (userInfo.user.isAdmin) {
      history.push('/expert/admin')
    } else if (this.isExpertIncomplete(userInfo.expert)) {
      history.push('/expert/setup');
    } else if (userInfo.expert.termsAccepted === false) {
      history.push('/expert/terms')
    } else if (this.isPaymentIncomplete(userInfo.expertPayment)) {
      history.push('/expert/payment');
    } else {
      history.push('/expert/profile')
    }
  }

  isUserSignupIncomplete = (userInfo) => {
    if (this.isExpertIncomplete(userInfo.expert)) {
      return true;
    } else if (userInfo.expert.termsAccepted === false) {
      return true;
    } else if (this.isPaymentIncomplete(userInfo.expertPayment)) {
      return true;
    } else {
      return false;
    }
  }

  isExpertIncomplete = (expert): boolean => {
    let firstName = expert.firstName ? expert.firstName.trim() : false;
    let lastName = expert.lastName ? expert.lastName.trim() : false;
    let phone = expert.phone ? expert.phone.trim() : false;

    return !(firstName && lastName && phone);
  }

  isPaymentIncomplete = (expertPayment): boolean => {
    if (!expertPayment.selectedType) {
      return true;
    }

    if (expertPayment.selectedType === PaymentTypeEnums.DOMESTIC) {
      return ['domesticAccountNumber', 'domesticRoutingNumber'].some(prop => expertPayment[prop] == null);
    }

    if (expertPayment.selectedType === PaymentTypeEnums.INTERNATIONAL) {
      if (expertPayment.intlHasIban) {
        return [
          'intlIban',
          'intlSwiftBic',
          'intlUserCity',
          'intlUserState',
          'intlUserPostalCode',
          'intlUserAddress',
          'intlBankCity',
          'intlBankState',
          'intlBankPostalCode',
          'intlBankAddress',
        ].some(prop => expertPayment[prop] == null);
      } else {
        return expertPayment.intlNotes == null;
      }
    }

    if (expertPayment.selectedType === PaymentTypeEnums.PAYPAL) {
      return expertPayment.paypalEmail == null;
    }
  }

  getUserLogInfo = () => {
    const systemInfo = this.getSystemInfo();

    return {
      ...systemInfo,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };
  }

  getSystemInfo = () => {
    const { userAgent } = navigator;
    const operatingSystem = this.getOperatingSystem();
    const browserInfo = this.getBrowserInfo();

    return {
      ...browserInfo,
      userAgent,
      operatingSystem,
    };
  }

  getBrowserInfo = () => {
    const { userAgent, cookieEnabled, language } = navigator;
    let browserName = navigator.appName;
    let browserVersion = '' + parseFloat(navigator.appVersion);

    let versionOffset;
    let nameOffset;

    // Chrome
    if ((versionOffset = userAgent.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      browserVersion = userAgent.substring(versionOffset + 7);
    }

    // Firefox
    else if ((versionOffset = userAgent.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
      browserVersion = userAgent.substring(versionOffset + 8);
    }

    // Safari
    else if ((versionOffset = userAgent.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      browserVersion = userAgent.substring(versionOffset + 7);
      if ((versionOffset = userAgent.indexOf('Version')) !== -1) {
        browserVersion = userAgent.substring(versionOffset + 8);
      }
    }

    // Internet Explorer
    else if ((versionOffset = userAgent.indexOf('MSIE')) !== -1) {
      browserName = 'Internet Explorer';
      browserVersion = userAgent.substring(versionOffset + 5);
    }

    // Opera
    else if ((versionOffset = userAgent.indexOf('Opera')) !== -1) {
      browserName = 'Opera';
      browserVersion = userAgent.substring(versionOffset + 6);
      if ((versionOffset = userAgent.indexOf('Version')) !== -1) {
        browserVersion = userAgent.substring(versionOffset + 8);
      }
    }

    // Other
    else if (
      (nameOffset = userAgent.lastIndexOf(' ') + 1) <
      (versionOffset = userAgent.lastIndexOf('/'))
    ) {
      browserName = userAgent.substring(nameOffset, versionOffset);
      browserVersion = userAgent.substring(versionOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // Trim version number semicolon/space separator
    let separatorIndex;
    if ((separatorIndex = browserVersion.indexOf(';')) !== -1) {
      browserVersion = browserVersion.substring(0, separatorIndex);
    }
    if ((separatorIndex = browserVersion.indexOf(' ')) !== -1) {
      browserVersion = browserVersion.substring(0, separatorIndex);
    }

    return {
      browserName,
      browserVersion,
      cookieEnabled,
      language,
    };
  }

  getOperatingSystem = () => {
    const { appVersion } = navigator;
    let os = 'Unknown OS';

    if (appVersion.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (appVersion.indexOf('Mac') !== -1) {
      os = 'MacOS';
    } else if (appVersion.indexOf('X11') !== -1) {
      os = 'UNIX';
    } else if (appVersion.indexOf('Linux') !== -1) {
      os = 'Linux';
    }

    return os;
  }


}

export default new UserService();  