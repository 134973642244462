import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import ContactUs from '../../containers/contactUs/contactUs';

import '../../styles/common/Footer.scss';

export default class Footer extends Component {
  state = {
    contactUsOpen: false,
  }

  handleContactUsClick = () => {
    this.setState({ contactUsOpen: true })
  }

  handleContactUsClose = () => {
    this.setState({ contactUsOpen: false });
  }

  render() {
    return (
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <Link to="/"><img src={require('../../images/homepage/white_logo@3x.png')} alt=""/></Link>
          </div>
          <div className="footer-info">
            <div className="footer-info-box">
              <span className="footer-info-box-header">Address</span>
              <span className="footer-info-line">335 Madison Avenue</span>
              <span className="footer-info-line">New York, NY 10017</span>
            </div>
            <div className="footer-info-box">
              <span className="footer-info-box-header">Contact</span>
              <span className="footer-info-line">+1 628 224 3707</span>
              <span className="footer-info-line"><a href="mailto:info@newtonx.com" style={{textDecoration: "none", color: "#FFFFFF"}}>info@newtonx.com</a></span>
            </div>
            <div className="footer-info-box">
              <span className="footer-info-box-header">More Information</span>
              <NavLink exact={true} to="/about-us" className="nav-link">
                <span className="footer-info-line">About Us</span>
              </NavLink>
              <NavLink exact={true} to="/compliance" className="nav-link">
                <span className="footer-info-line">Compliance</span>
              </NavLink>
              <NavLink exact={true} to="/careers" className="nav-link">
                <span className="footer-info-line">Careers</span>
              </NavLink>
              <NavLink exact={true} to="/faq" className="nav-link">
                <span className="footer-info-line">FAQ</span>
              </NavLink>
              <span className="footer-info-line" onClick={this.handleContactUsClick}>Contact</span>
              <NavLink exact={true} to="/privacy" className="nav-link">
                <span className="footer-info-line">Privacy Policy</span>
              </NavLink>
              <NavLink exact={true} to="/security" className="nav-link">
                <span className="footer-info-line">Data Security Policy</span>
              </NavLink>
            </div>
            <div className="footer-info-box">
              <span className="footer-info-box-header">Connect</span>
              <div className="social-media-links">
                <a href="https://www.linkedin.com/company/10893993/" target="_blank" rel="noopener noreferrer"><img src={require('../../images/homepage/linked_in_icon@3x.png')} alt=""/></a>
                <a href="https://twitter.com/NewtonXInsights" title="Twitter" target="_blank" rel="noopener noreferrer"><img src={require('../../images/homepage/tweeter_icon@3x.png')} alt=""/></a>
              </div>
              <div className="privacy-links">
                <div>
                  <div className="privacy-links-header">Certified by -</div>
                </div>
                <div className="privacy-links-image">
                  <a href="https://www.privacyshield.gov/" target="_blank" rel="noopener noreferrer"><img src={require("../../images/homepage/privacy_shield_white.png")} alt="Privacy Shield logo"/></a>
                </div>
                <div className="privacy-links-image">
                  <a href="https://www.esomar.org/what-we-do/standards" target="_blank" rel="noopener noreferrer"><img src={require("../../images/homepage/esomar-white.png")} alt="Esomar Logo"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactUs isOpen={this.state.contactUsOpen} onClose={this.handleContactUsClose} />
      </div>
    );
  }
}