import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExpertSearchContainer from './ExpertSearchContainer';

class AdminContainer extends Component {

  componentDidMount() {
    if (this.props.userInfo.user.isAdmin !== true) {
      this.props.history.push('/');
    }
  }

  render() {
    return(
      <ExpertSearchContainer />
    )
  }
}

const mapStateToProps = ({ userInfo }) => ({
  userInfo
})

export default connect(mapStateToProps)(AdminContainer);