// @flow
import axios from 'axios';
import { environment } from '../environments/env';

import {
    SET_SIGNUP_ACCESS_TOKEN,
    CLEAR_SIGNUP_ACCESS_TOKEN,
    SET_SIGNUP_USER,
    CLEAR_SIGNUP_USER,
    SET_REDIRECTING_FROM_EMAIL_VERIFICATION,
    SEND_CLIENT_NOTIFICATION_EMAIL_START,
    SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS,
    SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE,
    FETCH_PLATFORM_EXPERT_BY_GUID_START,
    FETCH_PLATFORM_EXPERT_BY_GUID_SUCCESS,
    FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE,
    POST_USER_START,
    POST_USER_SUCCESS,
    POST_USER_FAILURE,
    VERIFY_USER_START,
    VERIFY_USER_SUCCESS,
    VERIFY_USER_FAILURE
} from '../action-types/signup';

import { openSignupAfterRedirectModal, closeSignupAfterRedirectModal, openThankYouSignupModal } from '../actions/modalActions';
import api from '../utils/api';

export const setSignupAccessToken = (token) => ({
    type: SET_SIGNUP_ACCESS_TOKEN,
    payload: { nxTempAccessToken: token }
});

export const clearSignupAccessToken = (token) => ({
    type: CLEAR_SIGNUP_ACCESS_TOKEN,
    payload: { nxTempAccessToken: token }
});

export const setSignupUser = (signupUser) => ({
    type: SET_SIGNUP_USER,
    payload: signupUser
});

export const clearSignupUser = () => ({
    type: CLEAR_SIGNUP_USER,
    payload: {}
});

export const setRedirectingFromEmailVerification = (redirectingFromEmailVerification: boolean) => ({
    type: SET_REDIRECTING_FROM_EMAIL_VERIFICATION,
    payload: {
        redirectingFromEmailVerification
    }
});

export const setSendClientNotificationEmailStart = () => ({
    type: SEND_CLIENT_NOTIFICATION_EMAIL_START
});

export const setSendClientNotificationEmailSuccess = () => ({
    type: SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS
});

export const setSendClientNotificationEmailFailure = () => ({
    type: SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE
});

export const setFetchPlatformExpertByGuidStart = () => ({
    type: FETCH_PLATFORM_EXPERT_BY_GUID_START
});

export const setFetchPlatformExpertByGuidSuccess = () => ({
    type: FETCH_PLATFORM_EXPERT_BY_GUID_SUCCESS
});

export const setFetchPlatformExpertByGuidFailure = () => ({
    type: FETCH_PLATFORM_EXPERT_BY_GUID_FAILURE
});

export const setPostUserStart = () => ({
    type: POST_USER_START
});

export const setPostUserSuccess = () => ({
    type: POST_USER_SUCCESS
});

export const setPostUserFailure = () => ({
    type: POST_USER_FAILURE
});

export const setVerifyUserStart = () => ({
    type: VERIFY_USER_START
});

export const setVerifyUserSuccess = () => ({
    type: VERIFY_USER_SUCCESS
});

export const setVerifyUserFailure = (error) => ({
    type: VERIFY_USER_FAILURE,
    payload: error
});

export const sendClientNotificationEmail = (clientInfo) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setSendClientNotificationEmailStart())
    try {
        const notificationEmailStatus = await api.post('/clients', clientInfo);

        if (notificationEmailStatus.status === 200) {
            await dispatch(setSendClientNotificationEmailSuccess());
        }
    } catch (error) {
        await dispatch(setSendClientNotificationEmailFailure());
    }
};

export const fetchPlatformExpertByGuid = (guid: string) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setFetchPlatformExpertByGuidStart())
    try {
        const queryParams = new URLSearchParams();
        queryParams.append('guid', guid);
        const expertData = await api.get(`/platform/experts?${queryParams.toString()}`);

        if (expertData.status === 200) {
            const { verificationToken, firstName, lastName, email, linkedInId } = expertData.data;
            await dispatch(setSignupAccessToken(verificationToken));
            await dispatch(setSignupUser({ firstName, lastName, email, linkedInId }));
            await dispatch(setFetchPlatformExpertByGuidSuccess());
        }
    } catch (error) {
        await dispatch(setFetchPlatformExpertByGuidFailure());
    }
};

export const postUser = (userInfo: Object) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setPostUserStart())
    try {
        const signupToken = sessionStorage.getItem(environment.SIGNUP_TOKEN_NAME);

        const userToSend = {
            email: userInfo.email.toLowerCase(),
            ...userInfo
        };
        const userPostResult = await axios.post(`${environment.API_BASE_PATH}/users`, userToSend, {
            headers: {
                'Authorization': signupToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        });

        if (userPostResult.status === 200) {
            await dispatch(closeSignupAfterRedirectModal());
            sessionStorage.removeItem(environment.SIGNUP_TOKEN_NAME);
            await dispatch(openThankYouSignupModal());
            await dispatch(setPostUserSuccess());
        }
    } catch (error) {
        await dispatch(setPostUserFailure());
    }
};

export const verifyUser = (verificationToken: string) => async (
    dispatch: Dispatch,
    getState: GetState
) => {
    await dispatch(setVerifyUserStart())
    try {
        const queryParams = new URLSearchParams();
        queryParams.append('ref', verificationToken);

        const verifyUserResult = await api.get(`/verify?${queryParams.toString()}`);

        if (verifyUserResult.status === 200) {
            await dispatch(setVerifyUserSuccess());
        }
    } catch (error) {
        const { response } = error;
        await dispatch(setVerifyUserFailure(response));
    }
};



