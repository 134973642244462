import React from 'react';
import FAQEntryItem from './FAQEntryItem';
import '../../styles/components/FAQ/FAQcontent.scss';

export default () => (
  <div className="faq-container">
    <div className="faq-content">
      <span>Clients - </span>
      <div className="faq-entries">
        <FAQEntryItem
          question="What is NewtonX?"
          answers={[
            `NewtonX is an Expert Discovery Engine connecting the world’s business professionals with subject-matter experts.`,
            `You should use NewtonX to get the expertise you need to achieve your business objectives. Whether it’s assessing a new market, competitors, products, customers or best practices, we have you covered.`,
          ]}
        />
        <FAQEntryItem
          question="How does NewtonX work?"
          answers={[
            `Our dynamic request engine helps you refine 
            your ask to make sure we get a clear understanding of your needs.`,
            `Our proprietary matching algorithm computes a relevance score for each 
            expert profile to help you select the experts that will best address your needs.`,
            `Our online scheduling tool allows you to see the 
            experts availability and schedule the conversation whenever is most convenient for you.`,
          ]}
        />
        <FAQEntryItem
          question="Why should I use NewtonX?"
          answers={[
            `NewtonX offers help on two critical dimensions:
            Not only does our proprietary search algorithm help you effectively refine 
            your search, but it also suggests possible areas or categories you may want to consider.`,
            `Our search engine finds experts faster than any other competitors and ensures that these 
            experts are equipped to answer all of your questions. You gain speed and optimize your costs.`,
          ]}
        />
        <FAQEntryItem
          question="Why should I use you instead of other expert networks"
          answers={[
            'Other expert networks use research associates who search manually for experts. This results in time loss and increased costs for you. Also, most research associates do not possess the specific knowledge to judge whether experts will be suitable for your request or not.',
            'Our proprietary search algorithm ensures that the experts you get are the best fit for your needs. We do it faster, more accurately and cheaper.',
          ]}
        />
        <FAQEntryItem
          question="Do I need to tell my employer that I am using NewtonX?"
          answers={['Depending on how your company is organized, you should check with your team if there are processes in place for external service providers. Our compliance and confidentiality agreements ensure the highest standards of professional business conduct. We also ensure the conversation is confidential and that the information you request is proprietary.']}
        />
        <FAQEntryItem
          question="How do I pay?"
          answers={[
            'If your company is not a customer of ours yet, you can pay with a credit card. Our payment process is set up to make it a seamless experience for you, so you can focus on getting the expertise you need.',
            'We will reach out to your company to see if a central billing process is the best solution, in which case we will centrally bill you through your company.',
          ]}
        />
        <FAQEntryItem
          question="What happens if I am dissatisfied with my expert?"
          answers={[
            `NewtonX has a 100% satisfaction guaranteed policy. Reach out to your dedicated operations associate, and they will provide you with a solution for you to get the insights you need.`
          ]}
        />
      </div>
    </div>
    <div className="faq-content second-row">
      <span>Experts - </span>
      <div className="faq-entries">
        <FAQEntryItem
          question="What distinguishes NewtonX from expert networks?"
          answers={['We are a technology-driven knowledge platform trying to make the exchange of knowledge between experts and companies as fruitful and easy as possible. We do not rely on lengthy imprecise text-based resumés: we use our proprietary profile builder and algorithm to quickly extract your expertise and thereby enable companies to better search and target you.']}
        />
        <FAQEntryItem
          question="What is the process to become an expert?"
          answers={['After signing up, you fill out your work and education profile, or import it directly from LinkedIn. We will then build your knowledge tree to identify your expertise, which will help you to get perfectly matched with potential clients.']}
        />
        <FAQEntryItem
          question="What industries does NewtonX focus on?"
          answers={['NewtonX covers a broad range of industries from Aerospace & Defense to Consumer Goods, Healthcare, Media, The Public Sector and Telco. Our clients are diverse, and we are always looking for experts in different industries.']}
        />
      </div>
    </div>
  </div>
);