import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { environment } from '../../environments/env';

class HubspotAnalytics extends Component {
  componentWillUpdate({ location, history }) {

    if (location.pathname === this.props.location.pathname) {
      // don't relog if they're on the same page

      return;
    }

    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', location.pathname]);
    _hsq.push(['trackPageView']);
  }

  render () {
    return null;
  }
}

export default withRouter(HubspotAnalytics);