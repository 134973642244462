import React, { Component } from 'react'
import Modal from 'react-modal';
import { withFormik, Field, Form } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import { environment } from '../../environments/env';
import HubspotForm from 'react-hubspot-form';

import '../../styles/components/signup/SignupForm.scss';
import '../../styles/containers/Modal.scss';
import '../../styles/common/Button.scss';

class ContactUs extends Component {

  constructor() {
    super();

    this.state = {
      alert: ''
    }
  }

  componentDidMount() {
    
  }

  handleRecaptchaChange = (value) => {
    this.props.setFieldValue('recaptcha', value, true); 
  }

  render() {
    return (
      <Modal 
        htmlOpenClassName='ReactModal__Html--open' 
        isOpen={this.props.isOpen} 
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.handleClose}
        ariaHideApp={false}
        style={{
          content: {
            minWidth: '400px',
            minHeight: '800px',
          },
        }}
      >
        <div style={{padding: '5px'}}>
          <HubspotForm
            portalId='6114340'
            formId='d92d694b-c014-4fa1-af98-bb2aec97600f'
          />
        </div>
     </Modal>
    );
  };

  handleClose = () => {
    this.props.onClose();
  }

}

export default withFormik({
  mapPropsToValues() {
    return {
      name: '',
      email: '',
      request: '',
      message: '',
      recaptcha: ''
    }
  },

  validate: values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    
    if (!values.email) {
      errors.email = 'Required'; 
    }

    if (!values.request) {
      errors.request = 'Required';
    }

    if (!values.message) {
      errors.message = 'Required'; 
    }

    if (!values.recaptcha) {
      errors.recaptcha = 'Required';
    }

    return errors; 
  },

  handleSubmit: (values, { resetForm, props: { onSubmit }}) => { 
    onSubmit(values); 
    resetForm(); 
  }
})(ContactUs);