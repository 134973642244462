import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Signup from '../../containers/login/Signup';
import Signin from '../../containers/login/Signin';
import ResendLink from '../../components/resendLink/ResendLink';
import ResendLinkStatus from '../../components/resendLink/ResendLinkStatus'
import ContactUs from '../../containers/contactUs/contactUs';
import { connect } from 'react-redux';
import { compose } from 'redux';
import '../../styles/common/Header.scss';
import { clearUserInformation } from '../../actions/signinActions';
import { openSignupModal, closeSignupAfterRedirectModal, openSigninModal, closeSigninModal } from '../../actions/modalActions';
import ForgotPassword from '../forgotPassword/ForgotPassword';
import ForgotPasswordStatus from '../forgotPassword/ForgotPasswordStatus';
import SignupAfterRedirectModal from '../../containers/signup/SignupAfterRedirectModal';
import ThankYouSignupModal from '../../containers/signup/ThankYouSignupModal';
import contactService from '../../utils/contactService';
import { withToastManager } from 'react-toast-notifications';
import authService from '../../utils/authService';
import ResetPasswordConfirm from '../resetPasswordConfirm/ResetPasswordConfirm';
import ResendVerificationEmailConfirm from "../resendVerificationEmailConfirm/ResendVerificationEmailConfirm";

export class TopHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signUpModalOpen: false,
      contactUsOpen: false,
      width: null,
    }
  }

  listenResizeEvent = () => {
    this.setState({ width: window.innerWidth })
  }

  componentDidMount() {
    this.listenResizeEvent();
    window.addEventListener('resize', this.listenResizeEvent);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.listenResizeEvent);
  }

  render() {
    const { location } = this.props;
    if (location.pathname.match(/press/)) {
      return null;
    }

    if (this.state.width < 573) {
      return null;
    }

    const activeTopNavStyle = {
      color: '#fff',
      paddingBottom: '12px',
      borderBottom: '2px solid #0000FF',
    }

    let modalTriggerStyleSignIn, modalTriggerStyleSignUp, modalTriggerStyleContact;
    if (this.state.signInModalOpen) {
      modalTriggerStyleSignIn = "top-routing-link modal-trigger active-modal"
    } else {
      modalTriggerStyleSignIn = "top-routing-link modal-trigger";
    }
    if (this.state.signUpModalOpen) {
      modalTriggerStyleSignUp = "top-routing-link modal-trigger active-modal"
    } else {
      modalTriggerStyleSignUp = "top-routing-link modal-trigger";
    }
    if (this.state.contactUsOpen) {
      modalTriggerStyleContact = "top-routing-link modal-trigger active-modal";
    } else {
      modalTriggerStyleContact = "top-routing-link modal-trigger";
    }
    return (
      <header className="header">
        <div className="top-routing">
          <div className="top-routing-box">
            {this.props.userId ? null : <span onClick={this.handleSignupClick} to="#" className={modalTriggerStyleSignUp}>Sign up</span>}
            {this.props.userId ? null : <span onClick={this.handleLoginClick} to="#" className={modalTriggerStyleSignIn}>Login</span>}
            {this.props.userId ? <span onClick={this.handleLogoutClick} to="#" className="top-routing-link modal-trigger">Logout</span> : null}
            {this.props.userId ? <span onClick={this.handleMyAccountClick} to="#" className="top-routing-link modal-trigger">My Account</span> : null}
            <span onClick={this.handleContactUsClick} to="#" className={modalTriggerStyleContact}>Contact Us</span>
            <NavLink exact={true} activeStyle={activeTopNavStyle} to="/careers" className="top-routing-link">Careers</NavLink>
          </div>
        </div>
        {/* all components below are modals  */}
        <Signup history={this.props.history} isOpen={this.state.signUpModalOpen} onClose={this.handleClose} />
        <Signin history={this.props.history} isOpen={this.props.signinModalOpen} onClose={this.handleLoginClose} openSignup={this.handleSignupClick} />
        <ContactUs isOpen={this.state.contactUsOpen} onClose={this.handleContactUsClose} onSubmit={this.handleContactUsSubmit} />
        <ResendLink isOpen={this.props.resendLinkModalOpen} />
        <ResendLinkStatus isOpen={this.props.resendLinkStatusModalOpen} />
        <ForgotPassword isOpen={this.props.forgotPasswordModalOpen} />
        <ForgotPasswordStatus isOpen={this.props.forgotPasswordStatusModalOpen} />
        <SignupAfterRedirectModal isOpen={this.props.signupAfterRedirectModalOpen} />
        <ThankYouSignupModal isOpen={this.props.thankYouSignupModalOpen} />
        <ResetPasswordConfirm isOpen={this.props.resetPasswordConfirmationModalOpen} />
        <ResendVerificationEmailConfirm isOpen={this.props.resendVerificationEmailConfirmationModalOpen} />
      </header>
    );
  }
  handleLoginClick = () => {
    this.props.openSigninModal();
  }
  handleSignupClick = () => {
    this.props.closeSigninModal();
    this.setState({ signUpModalOpen: true, signInModalOpen: false });
  }
  handleClose = () => {
    this.setState({ signUpModalOpen: false });
  }
  handleLoginClose = () => {
    this.props.closeSigninModal();
  }
  handleContactUsClick = () => {
    this.setState({ contactUsOpen: true })
  }
  handleContactUsClose = () => {
    this.setState({ contactUsOpen: false });
  }

  handleLogoutClick = () => {
    localStorage.removeItem('nxPublicToken');
    this.props.clearUserInformation();
    authService.logoutFromCAS();
  }

  handleContactUsSubmit = (values) => {
    let contactInfo = {
      email: values.email,
      name: values.name,
      request: values.request,
      message: values.message
    };
    contactService.sendContactNotificationEmail(contactInfo)
      .then((resp) => this.props.toastManager.add('Request sent. Our team will contact you to assist.', {
        appearance: 'success'
      }))
      .catch((err) => this.props.toastManager.add('Something went wrong. Request could not be sent. Please try again.', {
        appearance: 'success'
      }))
    this.handleContactUsClose();
  }

  handleMyAccountClick = () => {
    this.props.history.push('/expert/profile');
  }
}

const mapStateToProps = ({ modals, userInfo }) => ({
  signupAfterRedirectModalIsOpen: modals.signupAfterRedirectModalIsOpen,
  resendLinkModalOpen: modals.resendLinkModalOpen,
  resendLinkStatusModalOpen: modals.resendLinkStatusModalOpen,
  userId: userInfo.user.id,
  forgotPasswordModalOpen: modals.forgotPasswordModalOpen,
  forgotPasswordStatusModalOpen: modals.forgotPasswordStatusModalOpen,
  signupAfterRedirectModalOpen: modals.signupAfterRedirectModalOpen,
  thankYouSignupModalOpen: modals.thankYouSignupModalOpen,
  signinModalOpen: modals.signinModalOpen,
  resetPasswordConfirmationModalOpen: modals.resetPasswordConfirmationModalOpen,
  resendVerificationEmailConfirmationModalOpen: modals.resendVerificationEmailConfirmationModalOpen
});

const mapDispatchToProps = (dispatch) => ({
  clearUserInformation: () => {
    dispatch(clearUserInformation())
  },

  handleSignupClick: () => {
    dispatch(openSignupModal())
  },

  closeSignupAfterRedirectModal: () => {
    dispatch(closeSignupAfterRedirectModal())
  },

  openSigninModal: () => {
    dispatch(openSigninModal())
  },

  closeSigninModal: () => {
    dispatch(closeSigninModal())
  }
})

export default compose(
  withRouter,
  withToastManager,
  connect(mapStateToProps, mapDispatchToProps)
)(TopHeader);
