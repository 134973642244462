import React, { Component } from 'react';
import SurveyPayment from '../../components/surveyPayment/SurveyPayment';
import queryString from 'query-string';
import i18n from '../../i18n';
import { environment } from '../../environments/env';
import SurveyPaymentForm from '../../components/surveyPayment/SurveyPaymentForm';
import { withToastManager } from 'react-toast-notifications';
import axios from 'axios';

class SurveyPaymentContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expertGuid: '',
      amazonPaymentDisabled: false,
      flag: false,
      values: {
        firstName: '',
        lastName: '',
        contactEmail: '',
        paymentMethod: '',
        paymentCountry: '',
        paymentEmail: '',
      },
    };
  }

  async componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (await this.checkIfPaymentCompleted(params.guid)) {
      window.location.replace(
        environment.SURVEY_PAYMENT_COMPLETION_URL + params.guid,
      );
    }

    this.onLanguageChange({ target: { value: params.lang || 'en' } });

    this.setState({
      expertGuid: params.guid,
    });
  }

  checkIfPaymentCompleted = async guid => {
    try {
      const response = await axios.get(
        environment.API_BASE_PATH + '/survey/expert/payment',
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            guid: guid,
          },
        },
      );

      const newState = {
        ...this.state,
        values: { ...response.data },
      };
      this.setState(newState);

      return response.data.length !== 0;
    } catch (error) {
      this.props.toastManager.add(
        'An error occurred. Please reach out to your NewtonX contact to request assistance with completing this form.',
        {
          appearance: 'error',
        },
      );
      this.props.history.push('/');
      return false;
    }
  };

  onFormSubmit = async (values, paymentEmailIsHidden) => {
    const params = queryString.parse(this.props.location.search);
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      contactEmail: values.contactEmail,
      paymentMethod: values.paymentType.toUpperCase(),
      paymentCountry: values.country,
      paymentEmail: values.paymentEmail || values.contactEmail,
      guid: this.state.expertGuid,
    };

    if (values.paymentEmailIsHidden) {
      payload.paymentEmail = values.contactEmail;
    }

    try {
      const code = Math.floor(Math.random() * 90000000) + 10000000;
      await axios.post(
        environment.API_BASE_PATH + '/survey/expert/payment',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            guid: this.state.expertGuid,
            lang: params.lang || 'en',
            code: code,
          },
        },
      );

      window.location.assign(
        `${environment.SURVEY_PAYMENT_COMPLETION_URL}${this.state.expertGuid}?code=${code}${
          params.lang ? `&lang=${params.lang}` : ''
        }`,
      );
    } catch (error) {
      this.props.toastManager.add(
        'Payment information could not be processed. Please try again.',
        {
          appearance: 'error',
        },
      );
      this.setState({
        amazonPaymentDisabled: false,
      });
    }
  };

  render() {
    const languageCode = queryString.parse(this.props.location.search).lang || 'en';
    return (
      <div
        className='bootstrap container'
        style={{ margin: '0 auto', maxWidth: '750px', width: '80%' }}
      >
        <SurveyPayment
          onLanguageChange={this.onLanguageChange}
          onCountryChange={this.onCountryChange}
          language={languageCode}
        />
        <SurveyPaymentForm
          values={this.state.values}
          onCountryChange={this.onCountryChange}
          onSubmit={this.onFormSubmit}
        />
      </div>
    );
  }

  onLanguageChange = event => {
    const queries = queryString.parse(this.props.location.search);
    i18n.changeLanguage(
      event.target.value,
      this.setState({
        language: event.target.value,
      }),
    );
    this.props.history.push({
      search: `?${new URLSearchParams({
        ...queries,
        lang: event.target.value,
      }).toString()}`,
    });
  };

  onCountryChange = countryName => {
    const amazonEnabled = [
      'France',
      'Canada',
      'United States',
      'United Kingdom',
      'Australia',
      'Germany',
      'Denmark',
      'Sweden',
      'Netherlands',
    ];

    if (amazonEnabled.find(country => country === countryName)) {
      this.setState({
        amazonPaymentDisabled: false,
      });
    } else {
      this.setState({
        amazonPaymentDisabled: true,
      });
    }
  };
}

export default withToastManager(SurveyPaymentContainer);
