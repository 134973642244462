import React, { Component } from 'react';
import { connect } from 'react-redux';

import adminService from '../../utils/adminService';
import ExpertSearchBar from '../../components/admin/ExpertSearchBar.js';
import { getExpertsByNameOrEmail } from '../../actions/adminActions';
import ExpertCardList from '../../components/admin/ExpertCardList';
import {
  setResetPasswordUserEmail,
  openResetPasswordConfirmationModal,
  setResendVerificationEmailUserId,
  openResendVerificationEmailConfirmationModal
} from "../../actions/modalActions";

class ExpertSearchContainer extends Component {

  constructor() {
    super();
    this.adminService = adminService;
  }

  searchForExpert = async (searchValue) => {
    this.props.getExpertsByNameOrEmail(searchValue);
  }

  render() {
    return(
      <div style={{paddingLeft: "1em", paddingRight: "1em"}}>
        <ExpertSearchBar searchForExpert={this.searchForExpert} />
        <ExpertCardList
            expertList={this.props.adminSearchResults}
            setResetPasswordUserEmail={this.props.setResetPasswordUserEmail}
            openResetPasswordConfirmationModal={this.props.openResetPasswordConfirmationModal}
            setResendVerificationEmailUserId={this.props.setResendVerificationEmailUserId}
            openResendVerificationEmailConfirmationModal={this.props.openResendVerificationEmailConfirmationModal}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  getExpertsByNameOrEmail: (results) => {
    dispatch(getExpertsByNameOrEmail(results))
  },

  openResetPasswordConfirmationModal: () => {
    dispatch(openResetPasswordConfirmationModal())
  },

  setResetPasswordUserEmail: (userEmail) => {
    dispatch(setResetPasswordUserEmail(userEmail))
  },

  setResendVerificationEmailUserId: (userId) => {
    dispatch(setResendVerificationEmailUserId(userId))
  },

  openResendVerificationEmailConfirmationModal: () => {
    dispatch(openResendVerificationEmailConfirmationModal())
  }
});

const mapStateToProps = ({ admin: { adminSearchResults } }) => ({
  adminSearchResults
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertSearchContainer);
