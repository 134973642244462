import React, { Component, Fragment } from 'react';
import DomesticPaymentForm from './DomesticPaymentForm';
import PaypalPaymentForm from './PaypalPaymentForm';
import InternationalPaymentForm from './InternationalPaymentForm';
import { connect } from 'react-redux';
import { updateExpertPayment } from '../../actions/signinActions';
import { withToastManager } from 'react-toast-notifications';
import { UPDATE_EXPERT_PAYMENT_START, UPDATE_EXPERT_PAYMENT_SUCCESS, UPDATE_EXPERT_PAYMENT_FAILURE } from '../../action-types/login';

/**
 * The ExpertPaymentForm holds 
 * will render one of three payment
 * forms, based on which option is currently selected 
 */
export class ExpertPaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paypal: false,
            domestic: false,
            international: false,
            alert: '',
            updateExpertPaymentStatus: ''
        }
    }

    handlePaymentTypeSelect = (event) => {
        let stateClone = { ...this.state };
        let updatedStateClone = this.updateChosenPaymentType(event.target.value, stateClone);
        this.setState({
            ...updatedStateClone
        });
    }

    // selected payment type key must be made UPPERCASE to match format used in backend 
    getSelectedPaymentType = () => {
        return Object.keys(this.state).filter(key => this.state[key] === true)[0].toUpperCase();
    }

    /** 
     * since only one payment type can be selected at once, we must iterate through all 
     * and set others to false 
     */
    updateChosenPaymentType = (checkboxValue: string, stateClone: Object) => {
        Object.keys(stateClone).forEach(key => {
            if (key === checkboxValue) {
                stateClone[key] = true;
            } else {
                stateClone[key] = false;
            }
        });
        return stateClone;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateExpertPaymentStatus !== this.props.updateExpertPaymentStatus &&
            (
                this.state.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_START &&
                (
                    this.props.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_SUCCESS ||
                    this.props.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_FAILURE
                )
            )) {

            if (this.props.updateExpertPaymentStatus === UPDATE_EXPERT_PAYMENT_SUCCESS) {
                this.props.history.push('/expert/profile');
                this.props.toastManager.add(`Thank you for signing up! Please review the expert tutorial
                video on this page before leaving the site.`, {
                    appearance: 'success'
                });
            } else {
                this.props.toastManager.add('Signup failed! Please try again.', {
                    appearance: 'error'
                });
            }
        }
    }

    handleSubmit = (paymentFormValues) => {
        let expertPayment = {
            ...this.props.expertPayment,
            ...paymentFormValues,
            selectedType: this.getSelectedPaymentType()
        }
        // this is the obj we'll send as the api call payload 
        let expertPaymentWithoutNullValues = {};

        // we don't care about sending null/empty  values to the backend so we filter them out 
        Object.keys(expertPayment).filter(key => expertPayment[key] !== null && expertPayment[key] !== '').forEach(key => expertPaymentWithoutNullValues[key] = expertPayment[key]);

        this.setState({
            updateExpertPaymentStatus: UPDATE_EXPERT_PAYMENT_START
        });

        this.props.updateExpertPayment(this.props.expert.id, expertPaymentWithoutNullValues);
    }

    render() {
        let renderAlert = () => {
            return (
                <div class="alert alert-success" role="alert">
                    {this.state.alert}
                </div>
            )
        }

        let renderDomesticForm = () => {
            return (
                <DomesticPaymentForm handleSubmit={this.handleSubmit} />
            )
        }

        let renderPaypalForm = () => {
            return (
                <PaypalPaymentForm handleSubmit={this.handleSubmit} />
            )
        }

        let renderInternationalForm = () => {
            return (
                <InternationalPaymentForm handleSubmit={this.handleSubmit} />
            )
        }
        return (
            <Fragment>
                <div className="bootstrap gothic-font">
                    <h2 className="headline">Payment Details</h2>
                    {this.state.alert ? renderAlert() : null}
                    <div layout="row mt-3">
                        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                            <h4 className="py=3">Your preferred payment method</h4>
                            <button
                                className="md-raised previous-button"
                                style={{ boxShadow: "2px 2px #d2d2d2" }}
                                onClick={this.props.onBackClick}
                            >
                                Previous
                            </button>
                            <form>
                                <div className="form-check">
                                    <input
                                        id="paypal"
                                        className="form-check-input"
                                        name="payment"
                                        value="paypal"
                                        type="radio"
                                        onClick={this.handlePaymentTypeSelect}
                                    />
                                    <label for="paypal" className="form-check-label">
                                        Gift card/Paypal (preferred)
                                        <p>
                                            Via our partner <a href='https://www.rybbon.net/rewards-gifts/' target="_blank">Rybbon</a> within 7 days. Large selection including eVisa virtual debit card (worldwide), eMastercard virtual debit card (worldwide), Paypal*, Amazon gift card*, Donations* and many more e-gift cards*. *If available in your country. <strong>Payments will be sent within 1 week after your consultation directly to your email address.</strong>
                                        </p>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        id="domestic"
                                        className="form-check-input"
                                        name="payment"
                                        value="domestic"
                                        type="radio"
                                        onClick={this.handlePaymentTypeSelect}
                                    />
                                    <label for="domestic" className="form-check-label">
                                        Domestic electronic payment (USA)
                                        <p>
                                            Payment will be sent <strong>directly to your bank account</strong> within 15 days of your consultation date
                                        </p>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        id="international"
                                        className="form-check-input"
                                        name="payment"
                                        value="international"
                                        type="radio"
                                        onClick={this.handlePaymentTypeSelect}
                                    />
                                    <label for="international" className="form-check-label">
                                        International electronic payment (outside of USA)
                                        <p>
                                            Payment will be sent <strong>directly to your bank account</strong> within 15 days of your consultation date
                                        </p>
                                    </label>
                                </div>
                            </form>
                            <div className="row mt-4" style={{ paddingBottom: "2em" }}>
                                <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                                    {this.state.domestic ? renderDomesticForm() : null}
                                    {this.state.paypal ? renderPaypalForm() : null}
                                    {this.state.international ? renderInternationalForm() : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ userInfo }) => ({
    expertPayment: userInfo.expertPayment,
    expert: userInfo.expert,
    updateExpertPaymentStatus: userInfo.updateExpertPaymentStatus
});

const mapDispatchToProps = (dispatch) => ({
    updateExpertPayment: (expertId, expertPayment) => {
        dispatch(updateExpertPayment(expertId, expertPayment))
    }
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(ExpertPaymentForm)); 