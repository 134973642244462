import React from 'react'

import '../../styles/components/homepage/HowItWorks.scss';


export default (props) => (
  <div className="how-it-works-container" style={{ background: '#F8F8F8', marginTop: props.width < 665 && '180px' }}>
    <div className="how-it-works-header">
      <span className="bold-text">How it Works</span>
      <span className="normal-text">Start accepting consultation opportunities today.</span>
    </div>
    <div className="how-it-works-icons">
      <div className="icon-container">
        <img src={require('../../images/howWeWork/icon_complete@3x.png')} alt=""/>
        <span>Complete profile</span>
      </div>
      <div className="icon-container">
        <img style={{ height: '76px' }} src={require('../../images/howWeWork/icon_accept@3x.png')} alt=""/>
        <span>Accept projects</span>
      </div>
      <div className="icon-container">
        <img src={require('../../images/howWeWork/icon_sched@3x.png')} alt=""/>
        <span>Schedule Seamlessly</span>
      </div>
    </div>
    <div className="bottom-text-container">
      <p className="bottom-text">
        The NewtonX proprietary onboarding flow, matching algorithm, and 
        scheduling service make sharing your expertise easier than ever before. 
        We handle everything, from project offer to payment.
      </p>
    </div>
  </div>
);