import React from 'react'
import DropdownElement from '../../common/DropdownElement';

export default () => (
  <div>
    <DropdownElement 
      description="Solution"
      text="NewtonX facilitated 10 video-conferencing sessions with experts from 4 areas:"
    >
      <ul>
        <li>Data Monetization platform engineers</li>
        <li>Data Buyers</li>
        <li>Data Agencies</li>
        <li>Data Owners</li>
      </ul>
    </DropdownElement>
    <DropdownElement 
      description="Impact"
      text="The consulting firm learned from a variety of stakeholders in the 
        data monetization value chain how to leverage its internal capabilities and 
        use external partnerships to effectively monetize its data assets."
    />
  </div>
);