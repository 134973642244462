import React, { Component } from 'react';
import { withFormik, Field, Form } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";

import '../../styles/components/signup/SignupForm.scss';
import '../../styles/common/Button.scss';
import { environment } from '../../environments/env';
import contactService from '../../utils/contactService';

class SignupForm  extends Component {
  handleRecaptchaChange = (value) => {
    this.props.setFieldValue('recaptcha', value, true);
  }

  render() {
    return(
    <div>
      <Form className="form-container">
        <p style={{color: 'red'}}>{this.props.errors.name}</p>
        <span className="label">Name<i>*</i></span>
        <Field className="input-style" name="name" placeholder="Name"/>
        <p style={{color: 'red'}}>{this.props.errors.email}</p>
        <span className="label">Email<i>*</i></span>
        <Field className="input-style" name="email" placeholder="Email"/>
        <p style={{color: 'red'}}>{this.props.errors.phone}</p>
        <span className="label">Phone<i>*</i></span>
        <Field className="input-style" name="phone" placeholder="Phone"/>
        <p style={{color: 'red'}}>{this.props.errors.message}</p>
        <span className="label">Please tell us what you need<i>*</i></span>
        <Field className="input-style"  name="message"
          render={({ field }) => (
            <textarea  rows="4" {...field} placeholder="Message"></textarea>
          )}
        />
        <p style={{color: 'red'}}>{this.props.errors.recaptcha}</p>
        <div className="reCaptcha">
          <ReCAPTCHA
            sitekey={environment.CAPTCHA_SITE_KEY}
            onChange={this.handleRecaptchaChange}
          />
        </div>

        <button className="white-button" type="submit">Send</button>
      </Form>
    </div>
    )
  }
}

export default withFormik({
  mapPropsToValues() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      recaptcha: '',
    }
  },

  validate: values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    }

    if (!values.phone) {
      errors.phone = 'Required';
    }

    if (!values.message) {
      errors.message = 'Required';
    }

    if (!values.recaptcha) {
      errors.recaptcha = 'Required';
    }

    return errors;
  },

  handleSubmit: (values, { resetForm, props: { onSubmit }}) => {
    onSubmit(values);
  }
})(SignupForm)
