import React from 'react';
import { Formik } from 'formik'; 

const PaypalPaymentEditForm = (props) => {
    return(
        <Formik
        initialValues={{ paypalEmail: props.currentValues.paypalEmail, paypalNotes: ''}}
        validate={ values => {
            let errors = {};
            if (!values.paypalEmail) {
                errors.paypalEmail = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.paypalEmail)
              ) {
                errors.paypalEmail = 'Invalid email address';
              }
            if (values.paypalNotes.length > 255) {
                errors.paypalNotes = 'Additional info must be 255 characters or less';
            }
            return errors; 
        }}
        onSubmit={(values, { setSubmitting }) => {
            props.handleSubmit(values);
            setSubmitting(false);  
        }}
    >
    {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    }) => (
        
        <form onSubmit={handleSubmit} className="bootstrap gothic-font">
            <div className="form-group">
              <label className="required">Email Address</label>
              <input
                type="text"
                name="paypalEmail"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paypalEmail}
              />
              <p style={{color: "red"}}>{errors.paypalEmail}</p>
            </div>

            <div className="form-group">
              <label>Additional Information</label>
              <input
                type="text"
                name="paypalNotes"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.paypalNotes}
                maxLength="255"
              />
              <p style={{color: "red"}}>{errors.paypalNotes}</p>
            </div>

            <button type="submit" disabled={isSubmitting || (errors.paypalEmail || values.paypalEmail === '')} className="btn black-button">
                Submit
            </button>
        </form>
    )}
    </Formik>
    )
}

export default PaypalPaymentEditForm; 