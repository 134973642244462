import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store/configure-store';
import Router from './router/AppRouter';
import { ToastProvider } from 'react-toast-notifications';

export default () => (
  <Provider store={store}>
    <ToastProvider placement='bottom-left'>
      <ConnectedRouter history={history}>
        <Router />
      </ConnectedRouter>
    </ToastProvider>
  </Provider>
);
