import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import SignupForm from '../../components/login/SignupForm';
import SignupService from '../../utils/signupService';
import '../../styles/containers/Modal.scss';
import '../../styles/common/Button.scss';
import linkedInOAuthLogo from '../../images/linkedin/linkedin-signin-default.png';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { sendClientNotificationEmail } from '../../actions/signupActions';
import { SEND_CLIENT_NOTIFICATION_EMAIL_START, SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS, SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE, } from '../../action-types/signup';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showClientSignup: false,
      sendClientNotificationEmailStatus: ''
    };
    this.signupService = new SignupService();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showClientSignup: nextProps.showClientSignup || false,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sendClientNotificationEmailStatus !== this.props.sendClientNotificationEmailStatus &&
      (
        this.state.sendClientNotificationEmailStatus === SEND_CLIENT_NOTIFICATION_EMAIL_START &&
        (
          this.props.sendClientNotificationEmailStatus === SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS ||
          this.props.sendClientNotificationEmailStatus === SEND_CLIENT_NOTIFICATION_EMAIL_FAILURE)
      )
    ) {

      if (this.props.sendClientNotificationEmailStatus === SEND_CLIENT_NOTIFICATION_EMAIL_SUCCESS) {
        this.props.toastManager.add('Request sent. Our team will contact you shortly.', {
          appearance: 'success'
        });
      } else {
        this.props.toastManager.add('Something went wrong. Request could not be sent. Please try again.', {
          appearance: 'error'
        })
      }

      this.handleClose();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.handleClose}
        ariaHideApp={false}
      >
        <div className="signup-content">
          <div className="signup-header">
            <img onClick={this.handleClose} src={require('../../images/common/close_icon.png')} alt="" />
            <span>Sign up</span>
          </div>
          <div className="buttons-container">
            <button
              onClick={this.handleClientButtonClick}
              className={this.state.showClientSignup ? 'black-button' : 'white-button'}
            >
              Client
            </button>
            <button
              onClick={this.handleExpertButtonClick}
              className={this.state.showClientSignup ? 'white-button' : 'black-button'}
            >
              Expert
            </button>
          </div>
          <div className="main-content">
            {this.state.showClientSignup ? <SignupForm onSubmit={this.handleClientContactFormSubmit} /> : this.renderExpertSignupView()}
          </div>
        </div>
      </Modal>
    );
  }
  renderExpertSignupView = () => {
    return (
      <div className="expert-content">
        <span className="message-text">
          NewtonX is an invitation-only platform. To become an expert, you should have received an invitation from a NewtonX team member with a personalized signup link.
        </span>
      </div>
    );
  }

  handleClientContactFormSubmit = (values) => {
    let clientInfo = {
      email: values.email,
      name: values.name,
      phone: values.phone,
      message: values.message,
      recaptcha: values.recaptcha
    };

    this.setState({
      sendClientNotificationEmailStatus: SEND_CLIENT_NOTIFICATION_EMAIL_START
    });

    this.props.sendClientNotificationEmail(clientInfo);
  }

  handleSignupClick = () => {
    this.signupService.signupWithLinkedIn();
  }
  handleClose = () => {
    this.props.onClose();
  }
  handleClientButtonClick = () => {
    this.setState({ showClientSignup: true });
  }
  handleExpertButtonClick = () => {
    this.setState({ showClientSignup: false });
  }
}

const mapStateToProps = ({ signup }) => ({
  sendClientNotificationEmailStatus: signup.sendClientNotificationEmailStatus
});

const mapDispatchToProps = (dispatch: Function) => ({
  sendClientNotificationEmail: (clientInfo) => {
    dispatch(sendClientNotificationEmail(clientInfo));
  }
});

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(Login));