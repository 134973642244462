import {
    SET_USER_INFORMATION,
    CLEAR_USER_INFORMATION,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    FETCH_PROFILE_START,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAILURE,
    RESEND_VERIFICATION_EMAIL_START,
    RESEND_VERIFICATION_EMAIL_SUCCESS,
    RESEND_VERIFICATION_EMAIL_FAILURE,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    UPDATE_EXPERT_START,
    UPDATE_EXPERT_SUCCESS,
    UPDATE_EXPERT_FAILURE,
    UPDATE_EXPERT_PAYMENT_START,
    UPDATE_EXPERT_PAYMENT_SUCCESS,
    UPDATE_EXPERT_PAYMENT_FAILURE,
    UPDATE_USER_PASSWORD_START,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAILURE,
    DOWNLOAD_USER_INFORMATION_START,
    DOWNLOAD_USER_INFORMATION_SUCCESS,
    DOWNLOAD_USER_INFORMATION_FAILURE,
    DELETE_USER_INFORMATION_START,
    DELETE_USER_INFORMATION_SUCCESS,
    DELETE_USER_INFORMATION_FAILURE,
    CREATE_USER_LOG_START,
    CREATE_USER_LOG_SUCCESS,
    CREATE_USER_LOG_FAILURE
} from '../action-types/login';

export default (state = {
    user: {},
    expert: {},
    expertPayment: {},
    redirectingFromEmailVerification: false,
    isUserVerified: false,
    loginFailed: false,
    accessToken: '',
    fetchProfileFailed: false,
    loginStatus: '',
    fetchProfileStatus: '',
    error: undefined,
    loading: false,
    resendVarificationEmailStatus: '',
    resetPasswordStatus: '',
    updateExpertStatus: '',
    updateExpertPaymentStatus: '',
    updateUserPasswordStatus: '',
    downloadUserInformationStatus: '',
    deleteUserInformationStatus: '',
    createUserLogStatus: ''
}, action) => {
    switch (action.type) {
        case 'SIMPLE ACTION':
            return {
                result: action.payload
            }
        case SET_USER_INFORMATION:
            return {
                ...state,
                user: action.payload.user,
                expert: action.payload.expert,
                expertPayment: action.payload.expertPayment
            }
        case CLEAR_USER_INFORMATION:
            return {
                ...state,
                user: {},
                expert: {},
                expertPayment: {}
            }
        case LOGIN_START:
            return {
                ...state,
                accessToken: '',
                loading: true,
                error: undefined,
                loginStatus: action.type,
                loginFailed: false
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload,
                loading: false,
                error: undefined,
                loginStatus: action.type,
                loginFailed: false
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                accessToken: '',
                loading: false,
                error: action.payload,
                loginStatus: action.type,
                loginFailed: true
            }
        case FETCH_PROFILE_START:
            return {
                ...state,
                user: {},
                expert: {},
                expertPayment: {},
                loading: true,
                error: undefined,
                fetchProfileStatus: action.type
            }
        case FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                expert: action.payload.expert,
                expertPayment: action.payload.expertPayment,
                loading: false,
                error: undefined,
                fetchProfileStatus: action.type
            }
        case FETCH_PROFILE_FAILURE:
            return {
                ...state,
                user: {},
                expert: {},
                expertPayment: {},
                loading: false,
                error: action.payload,
                fetchProfileStatus: action.type
            }
        case RESEND_VERIFICATION_EMAIL_START:
        case RESEND_VERIFICATION_EMAIL_SUCCESS:
        case RESEND_VERIFICATION_EMAIL_FAILURE:
            return {
                ...state,
                resendVarificationEmailStatus: action.type
            }
        case RESET_PASSWORD_START:
        case RESET_PASSWORD_SUCCESS:
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordStatus: action.type
            }

        // TODO: evaluate whether this should be in another reducer, or whether 'signin reducer' should be renamed to 'user reducer'        
        case UPDATE_EXPERT_START:
        case UPDATE_EXPERT_FAILURE:
            return {
                ...state,
                updateExpertStatus: action.type
            }
        case UPDATE_EXPERT_SUCCESS:
            return {
                ...state,
                updateExpertStatus: action.type,
                expert: {
                    ...action.payload
                }
            }
        case UPDATE_EXPERT_PAYMENT_START:
        case UPDATE_EXPERT_PAYMENT_FAILURE:
            return {
                ...state,
                updateExpertPaymentStatus: action.type
            }
        case UPDATE_EXPERT_PAYMENT_SUCCESS:
            return {
                ...state,
                updateExpertPaymentStatus: action.type,
                expertPayment: {
                    ...state.expertPayment,
                    ...action.payload
                }
            }
        case UPDATE_USER_PASSWORD_START:
        case UPDATE_USER_PASSWORD_SUCCESS:
        case UPDATE_USER_PASSWORD_FAILURE:
            return {
                ...state,
                updateUserPasswordStatus: action.type
            }
        case DOWNLOAD_USER_INFORMATION_START:
        case DOWNLOAD_USER_INFORMATION_SUCCESS:
        case DOWNLOAD_USER_INFORMATION_FAILURE:
            return {
                ...state,
                downloadUserInformationStatus: action.type
            }
        case DELETE_USER_INFORMATION_START:
        case DELETE_USER_INFORMATION_SUCCESS:
        case DELETE_USER_INFORMATION_FAILURE:
            return {
                ...state,
                deleteUserInformationStatus: action.type
            }
        case CREATE_USER_LOG_START:
        case CREATE_USER_LOG_SUCCESS:
        case CREATE_USER_LOG_FAILURE:
            return {
                ...state,
                createUserLogStatus: action.type
            }
        default:
            return state
    }
}